import { routeNames } from './uiRouteNames';

const apiRoutePrefix = '/api';

export const INIT = {
	csrfCookie: '/sanctum/csrf-cookie',
	getUser: `${apiRoutePrefix}/user`,
	initialize: `${apiRoutePrefix}/initialize`
};

export const LANG = {
	locale: '/api/locale',
};

export const APP = {
	forgotPassword: `${apiRoutePrefix}/forgot-password`,
	resetPassword: `${apiRoutePrefix}/reset-password`,
	verifyEmail: `${apiRoutePrefix}/email/verify`,
	verificationNotification: `${apiRoutePrefix}/email/verification-notification`,
	changePassword: `${apiRoutePrefix}/user/password`,
	deleteProfile: `${apiRoutePrefix}/user/delete`,
	deliveryAddress: `${apiRoutePrefix}/user/delivery-address`,
	bankAccountDetails: `${apiRoutePrefix}/user/bank-account-details`,
	phoneDetails: `${apiRoutePrefix}/user/phone`,
	products: `${apiRoutePrefix}/products`,
	productImage: `${apiRoutePrefix}/product-image`,
	cities: `${apiRoutePrefix}/cities`,
	avatar: `${apiRoutePrefix}/user/avatar`,
	messageImage: `${apiRoutePrefix}/user/message-threads/:thread_id/image`,
	favoriteUser: `${apiRoutePrefix}/user/favorite/user`,
	favoriteProduct: `${apiRoutePrefix}/user/favorite/product`,
	highlightProduct: `${apiRoutePrefix}/user/highlight-product`,
	profile: `${apiRoutePrefix}/user/:userId/profile`,
	userProducts: `${apiRoutePrefix}/user/:userId/products`,
	userSettings: `${apiRoutePrefix}/user/settings`,
	userSettingsOptOuts: `${apiRoutePrefix}/user/settings/opt-outs`,
	userDeliveryOption: `${apiRoutePrefix}/user/delivery-option`,
	dashboard: `${apiRoutePrefix}/dashboard`,
	catalog: `${apiRoutePrefix}/catalog`,
	cart: `${apiRoutePrefix}/cart`,
	mplDeliveryOptions: `${apiRoutePrefix}/mpl-delivery-options`,
	cartDeliveryOptions: `${apiRoutePrefix}/cart-delivery-options`,
	cartUserDeliveryOptions: `${apiRoutePrefix}/cart-user-delivery-options`,
	payCart: `${apiRoutePrefix}/pay`,
	userOrders: `${apiRoutePrefix}/user/orders`,
	userNotificationGroups: `${apiRoutePrefix}/user/notification-groups`,
	userNotifications: `${apiRoutePrefix}/user/notifications`,
	userThreads: `${apiRoutePrefix}/user/message-threads`,
	userMessages: `${apiRoutePrefix}/user/messages`,
	userReports: `${apiRoutePrefix}/user/reports`,
	paymentState: `${apiRoutePrefix}/payment-state`,
	tos: `${apiRoutePrefix}/tos`,
	gdpr: `${apiRoutePrefix}/gdpr`,
	faq: `${apiRoutePrefix}/faq`,
	purchaseHighlight: `${apiRoutePrefix}/user/purchase-highlight`,
	setHoliday: `${apiRoutePrefix}/user/set-holiday`,
	optOut: `${apiRoutePrefix}/opt-out`,
	searchTags: `${apiRoutePrefix}/search-tags`,
	searchUsers: `${apiRoutePrefix}/search-users`,
	inquireAvailability: `${apiRoutePrefix}/products/:product_id/inquire-availability`,
	updateAvailability: `${apiRoutePrefix}/products/:product_id/update-availability`,
};

export const AUTH = {
	login: `${apiRoutePrefix}/login`,
	logout: `${apiRoutePrefix}/logout`,
	register: `${apiRoutePrefix}/register`,
};

export const FAVORITES = {
	products: `${apiRoutePrefix}/user/favorite/product`,
	users: `${apiRoutePrefix}/user/favorite/user`,
};

const HOME = routeNames.root;

export default HOME;
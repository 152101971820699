import React from 'react';
import { Typography } from '@mui/material';
import L from './Lang';

const TypLink = ({term, onClick, ...rest}) => {
	return (
		<Typography
			{...rest}
		>
			<L term={term} partial={0} />
			<Typography
				component="u"
				color="inherit"
				sx={{cursor: "pointer"}}
				onClick={onClick}
			>
				<L term={term} partial={1} />
			</Typography>
		</Typography>
	);
}

export default TypLink;
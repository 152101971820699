import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, useNavigationType } from 'react-router-dom';
import { useCatalogRouteMatch } from '../../../hooks/useCatalogRouteMatch';
import ProductList from '../Product/ProductList';
import { getProducts, toggleFavoriteProduct, clearProductListAction } from '../../../actions/catalogActions';
import useStoreState from '../../../hooks/useStoreState';
import { ProductListContext } from '../../../contexts/ProductListContext';
import { showLoginFormAction } from '../../../actions/appActions';
import {Typography,Box} from '@mui/material'
import ProductFilter from '../Product/ProductFilter';
import MobileProductFilter from '../Product/MobileProductFilter';
import useMediaQuery from '@mui/material/useMediaQuery';
import { usePager } from '../../../hooks/usePager';
import bp from '../../../utils/BarionPixel';
import { routeNames } from '../../../constants/uiRouteNames';
import GoogleAd from '../../Helpers/GoogleAd';
import useFromSearchParams from '../../../hooks/useFromSearchParams';

const CatalogProductList = () =>
{
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const navigationType = useNavigationType();
	const { filterKey, filterFromSearchParams, stringify } = useFromSearchParams();
	
	const matchesMD = useMediaQuery((theme) => theme.breakpoints.up('md'));
	const { productList, pagination, setFavoriteIsPending, categoryListById } = useStoreState('catalog');

	const auth = useStoreState('auth');
	const { categoryIdChain } = useCatalogRouteMatch();

	const requestedCategoryId = categoryIdChain[ categoryIdChain.length - 1 ];
	const requestedCategory = categoryListById?.[ requestedCategoryId ];
	const requestedCategoryName = requestedCategory?.category_name;

	const [initialProductListLength] = React.useState(productList?.length || 0);

	const [categoryId, setCategoryId] = React.useState(requestedCategoryId);

	const [ filter, setFilter ] = React.useState( filterFromSearchParams );
	const [ order, setOrder ] = React.useState("updated_at.desc");
	const [ pager, dispatchPager ] = usePager({});

	// Barion Pixel <<<
	React.useEffect(() => {
		const properties = {
			id: requestedCategoryId,
			name: requestedCategoryName
		};
		bp.track('categorySelection', properties);

	}, [requestedCategoryId, requestedCategoryName])
	// >>> Barion Pixel

	React.useEffect(() =>
	{
		if ( pager.clear ) {
			dispatch(clearProductListAction());
		}
		
		if ( pager.get ) {
			dispatch(getProducts({
				category_id: categoryId,
				filter: filter && Object.keys(filter).length > 0 ? filter : undefined,
				order: order,
				page: pager.page
			}))
		}
	}, [dispatch, categoryId, filter, order, pager]);

	const loadNextPage = () => {
		dispatchPager({type: 'next-page'})
	}

	React.useEffect(() => {
		let newState = undefined;

		if ( pager.page !== 1 ) {
			newState = { page: pager.page };
		}

		if (filter) {
			newState = newState ? { ...newState, filter: filter } : { filter };
		}

		navigate({
			pathname: location.pathname,
			search: filter ? `${filterKey}=${stringify(filter)}` : ''
		}, { replace: true, state: newState } )
	},[navigate, filterKey, stringify, location.pathname, pager.page, filter]);
	
	React.useEffect(() => {
		setCategoryId(requestedCategoryId)

		const noProducts = initialProductListLength === 0;

		switch( navigationType ) {
			case "POP":
				// dispatchPager({type: "prev-page", page: noProducts ? 1 : location.state?.page, get: noProducts});
				// improve based on searchsizeproductlist
				dispatchPager({type: "prev-page", page: noProducts ? 1 : location.state?.page || 1, get: noProducts});
				if (location.state?.filter) {
					setFilter(location.state?.filter);
				}
				break;
			case "PUSH":
				dispatchPager({type: "first-page"});
				break;
			default:
		}
	},[dispatchPager, requestedCategoryId, navigationType, location, initialProductListLength]);

	const setIsFavorite = (productId) => {
		if ( !auth?.isSignedIn ) {
			dispatch(showLoginFormAction());
		}
		else {
			dispatch(toggleFavoriteProduct(productId))
		}
	}

	const handleFilterChange = React.useCallback((attribute, options) =>
	{
		if ( !attribute ) {
			return;
		}

		switch( attribute )
		{
			case 'price':
				setFilter( filter => ({ ...filter, price: options }));
				break;
			case 'children-attributes':
				setFilter(options);
				break;
			default:
				const filterOff = !( Array.isArray(options) && options.length > 0 );

				if (filterOff)
				{
					if ( filter && Object.keys(filter).includes( attribute.toString() ) && Object.keys(filter).length === 1 )
					{
						setFilter(undefined);
					}
					else
					{
						setFilter( filter => ({ ...filter, [attribute]: undefined }));
					}
				}
				else {
					setFilter( filter => ({ ...filter, [attribute]: options }))
				}
		}

		dispatchPager({type: 'first-page'});
  }, [dispatchPager, filter]);
	
	const handleOrderChange = (order) => {
		setOrder(order)
		dispatchPager({type: 'first-page'})
	}
	const handlePriceRangeChange = (price) => handleFilterChange('price', price)
	
	const clearFilter = () => {
		setFilter(undefined)
		// setOrder(undefined)
		dispatchPager({type: 'first-page'});
  }
	
	const setParams = ({filter,order}) => {
		setFilter(filter)
		setOrder(order)
		dispatchPager({type: 'first-page'})
  }

	// React.useEffect(() => {
	// 	if (pager.page === 1) {
	// 		window.scrollTo(0, 0)
	// 	}
	// }, [pager.page])
	// improve based on searchsizeproductlist
	React.useEffect(() => {
		if ( pager.page === 1 && pager.get ) {
			window.scrollTo(0, 0)
		}
	}, [pager.page, pager.get])

	React.useEffect(() => {
		return () => {
			setFilter(undefined)
			dispatchPager({type: 'first-page'});
		};
	}, [dispatchPager, requestedCategoryId, requestedCategoryName]);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					mb: 4,
					flexWrap: "wrap",
					alignItems: "center"
				}}
			>
				<Typography variant="h3" sx={{
					":first-letter": { textTransform: "capitalize" },
					mr: "auto"
				}}>
					{requestedCategoryName}
				</Typography>
				{!matchesMD&&<MobileProductFilter
					category={requestedCategory}
					{ ...{ filter, order, clearFilter, handleFilterChange, handlePriceRangeChange, handleOrderChange, setParams } }
				/>}
			</Box>
			{matchesMD && (
				<ProductFilter
					category={requestedCategory}
					{ ...{ filter, order, clearFilter, handleFilterChange, handlePriceRangeChange, handleOrderChange } }
				/>
			)}
			{Boolean(productList) && <GoogleAd slot="4553532412" sx={{mt: 8}} />}
			{/* <PaginationRow { ...{pagination} }/> */}

			<ProductListContext.Provider
				value={{
					spot: routeNames.catalog,
					pagination,
					loadNextPage: ( pagination && pagination.lastPage > pagination.currentPage ) ? loadNextPage : undefined,
					setIsFavorite,
					setFavoriteIsPending
				}}
			>
				<ProductList list={productList}/>
			</ProductListContext.Provider>
		</>
	);
}

export default CatalogProductList;

// const PaginationRow = ({pagination}) => {
	
// 	if (!pagination) return null;

// 	return (
// 		<Box
// 			sx={{p:4}}
// 		>
// 			<Typography>{`${1}-${pagination.to} / ${pagination.total}`}</Typography>
// 		</Box>
// 	);
// }
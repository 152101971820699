import { FAVORITES } from '../constants';

const initialState = {
	products: null,
	users: null,
	setFavoriteIsPending: false,
};

const favorites = (state=initialState, action) => {
	switch(action.type)
	{
		case FAVORITES.CLEAR_FAVORITE_LISTS:
			return initialState
		case FAVORITES.CLEAR_PRODUCTS:
			return {
				...state,
				products: initialState.products
			}
		case FAVORITES.SET_PRODUCTS:
			return {
				...state,
				products: action.products
			}
		case FAVORITES.CLEAR_USERS:
			return {
				...state,
				users: initialState.users
			}
		case FAVORITES.SET_USERS:
			return {
				...state,
				users: action.users
			}
		case FAVORITES.SET_FAVORITE_IS_PENDING:
			return {
				...state,
				setFavoriteIsPending: action.pending
			}
		default:
			return state;
	}
};

export default favorites;
import React from 'react';
import {useParams} from 'react-router-dom';
import useStoreState from '../hooks/useStoreState';
import { topLevelCategories } from '../utils';

const getCatalogRoute = (pattern) => {
	return pattern?.split('/') || [];
}

export const useCatalogRouteMatch = () =>
{
	const {categoryListById} = useStoreState('catalog');
	const categories = React.useMemo( () => categoryListById ? topLevelCategories(categoryListById) : [], [categoryListById] );

	const params = useParams();
	const categorySlugChain = getCatalogRoute(params['*']);

	const getCategoryIdChain = (slugChain, categories) =>
	{
		if ( Array.isArray(slugChain) && Array.isArray(categories) )
		{
			const slug = slugChain.shift();
			
			const category = categories.find( category => category.slug === slug );

			if (!category) {
				return [];
			}

			return [ category?.category_id, ...(  slugChain.length > 0 ? getCategoryIdChain(slugChain, categoryListById[ category?.category_id ]?.children) : [] ) ];
		}

		return [];
	}

	const categoryIdChain = getCategoryIdChain( [...categorySlugChain], categories );

	return {
		categorySlugChain,
		categoryIdChain
	};
}
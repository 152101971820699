const buttonOverrides = {
	// MuiButtonBase: {
	// 	styleOverrides: {
	// 		root: ({theme}) => ({
	// 			"&.MuiButton-containedPale": {
	// 				color: theme.palette.black
	// 			},
	// 		}),
	// 	}
	// },
	MuiButton: {
		defaultProps: {
			// disableRipple: true,
			// centerRipple: true,
			disableElevation: true
		},
		styleOverrides: {
			root: ({theme}) => ({
				"&.MuiButton-containedPale": {
					color: theme.palette.black
				},
				"&.MuiButton-outlinedBlack": {
					color: theme.palette.black
				},
				"&.Mui-disabled": {
					fill: theme.palette.grey.main2,
					color: theme.palette.grey.main2,
					borderColor: theme.palette.grey.main2,
				},
				"& .MuiButton-startIcon": {
					transition: "fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
				}
			}),
			contained: ({theme}) => ({
				// backgroundColor: theme.palette.primary.main1,
				// paddingTop: 7,
				// paddingBottom: 7,
				// ":hover": {
				// 	backgroundColor: theme.palette.primary.main5,
				// },
				// ":active": {
				// 	backgroundColor: theme.palette.primary.main2,
				// },
			}),
			outlined: ({theme}) => ({
				// border: `2px solid ${theme.palette.black.main}`,
				// ":hover": {
				// 	border: `2px solid ${theme.palette.black.main}`
				// },
				// ":active": {
				// 	border: `2px solid ${theme.palette.black.main}`
				// },
			})
		},
		variants: [
			{
				props: { variant: "contained", color: "primary" },
				style: ({theme}) => ({
					backgroundColor: theme.palette.primary.main1,
					paddingTop: 7,
					paddingBottom: 7,
					":hover": {
						backgroundColor: theme.palette.primary.main5,
					},
					":active": {
						backgroundColor: theme.palette.primary.main2,
					},
				})
			},
			{
				props: { variant: "contained", color: "pale" },
				style: ({theme}) => ({
					backgroundColor: theme.palette.primary.main3,
					color: theme.palette.black,
					":hover": {
						backgroundColor: theme.palette.primary.main1,
					},
					":active": {
						backgroundColor: theme.palette.primary.main4,
					},
					// "& .MuiTouchRipple-child": {
					// 	backgroundColor: theme.palette.success.main
					// }
				})
			},
			{
				props: { variant: "contained", color: "black" },
				style: ({theme}) => ({
					backgroundColor: theme.palette.black.main,
					color: theme.palette.white.main,
					fill: theme.palette.white.main,
					":hover": {
						color: theme.palette.primary.main1,
						fill: theme.palette.primary.main1,
						backgroundColor: theme.palette.black.main,
					},
					":active": {
						color: theme.palette.primary.main3,
						fill: theme.palette.primary.main3,
						backgroundColor: theme.palette.black.main,
					}
				})
			},
			{
				props: { variant: "outlined", color: "black" },
				style: ({theme}) => ({
					border: `2px solid ${theme.palette.black.main}`,
					":hover": {
						color: theme.palette.secondary.main,
						fill: theme.palette.secondary.main,
						border: `2px solid ${theme.palette.secondary.main}`
					},
					":active": {
						border: `2px solid ${theme.palette.black.main}`
					},
					"&.Mui-disabled": {
						borderWidth: `2px`
					}
				})
			},
			{
				props: { variant: "outlined", color: "grey" },
				style: ({theme}) => ({
					border: `2px solid ${theme.palette.grey.main4}`,
					":hover": {
						color: theme.palette.secondary.main,
						fill: theme.palette.secondary.main,
						border: `2px solid ${theme.palette.secondary.main}`
					},
					":active": {
						border: `2px solid ${theme.palette.grey.main4}`
					},
					"&.Mui-disabled": {
						borderWidth: `2px`
					}
				})
			}
		]
	},

	MuiIconButton: {
		styleOverrides: {
			root: ({theme}) => ({
				// "& .MuiTouchRipple-child": {
				// 	backgroundColor: theme.palette.success.main
				// }
			}),
		}
	}
};

export default buttonOverrides;
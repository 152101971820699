import React from 'react';
import {_useDict} from '../../hooks/useDict';

const Lang = props =>
{
	const dictionary = _useDict();
	const translate = ( term = '' ) => 
	{
		let text = dictionary?.app[term] || term;

		if ( props.replacements ) {
			for ( let [ search, replace ] of Object.entries(props.replacements) ) {
				text = text.replace( `:${search}`, replace );
			}
		}

		if ( text.includes("|") )
		{
			return Number.isInteger(props.partial) ? text.split('|')[props.partial] : text.replace('|','');
		}

		if ( props.lowerCase ) {
			text = text.toLowerCase();
		}

		return text;
	};

	return (
		<>
			{translate(props.term||props.children)}
		</>
	);
};

export default Lang;
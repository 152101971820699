import React from 'react';
import {useDispatch} from 'react-redux';
import {closeCatalogMenuAction, closeProfileMenuAction, showLoginFormAction, showSignUpFormAction} from '../actions/appActions';
import useStoreState from '../hooks/useStoreState';
import { routeNames } from '../constants/uiRouteNames';
// import { isDev } from '../utils/env';
import {Link} from 'react-router-dom';
import L from './Helpers/Lang';
import {AppBar, Box, Toolbar, IconButton, Button, Badge} from "@mui/material";
import UserMenu from './UserMenu';
import Logo from './Base/Logo';
import { ReactComponent as CartIcon } from '../assets/icons/shopping-bag.svg';
import { ReactComponent as HeartIcon } from '../assets/icons/heart.svg';
import { ReactComponent as MessageIcon } from '../assets/icons/message.svg';
import { ReactComponent as AddIcon } from '../assets/icons/plus.svg';
import CatalogTabs from './Pages/Catalog/CatalogTabs';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import { ReactComponent as GiftIcon } from '../assets/icons/mdi/gift-outline.svg';

import '../assets/css/animations.css';

// const LanguageChanger = props => {
//     return (
//         <React.Fragment>
//             <button className="ui button" onClick={() => props.setLocale('hu')}>hu</button>
//             <button className="ui button" onClick={() => props.setLocale('en')}>en</button>
//         </React.Fragment>
//     );
// }

const Header = () =>
{
	const auth = useStoreState('auth');
	const config = useStoreState('config');
	const {profileMenuOpen, catalogMenuOpen} = useStoreState('app');
	const {cart} = useStoreState('cart');
	// const {unread_count: unread_notification_count } = useStoreState('notifications');
	const { unread_message_thread_count } = useStoreState('messages');
	const dispatch = useDispatch();

	const handleMobileMenus = () => {
		dispatch( closeProfileMenuAction() );
		dispatch( closeCatalogMenuAction() );
	}

	// const matchesXS = useMediaQuery((theme) => theme.breakpoints.up('xs'));
	// const matchesSM = useMediaQuery((theme) => theme.breakpoints.up('sm'));
	const matchesMD = useMediaQuery((theme) => theme.breakpoints.up('md'));
	// const matchesLG = useMediaQuery((theme) => theme.breakpoints.up('lg'));
	// const matchesXL = useMediaQuery((theme) => theme.breakpoints.up('xl'));

	// const breakPointName = () => {
	// 	if ( matchesXL ) return 'xl';
	// 	if ( matchesLG ) return 'lg';
	// 	if ( matchesMD ) return 'md';
	// 	if ( matchesSM ) return 'sm';
	// 	if ( matchesXS ) return 'xs';
	// }

	const [ playCartAnimation, setPlayCartAnimation ] = React.useState(undefined);
	
	React.useEffect(() => {
		if ( Array.isArray(cart) && cart.length > 0 ) {
			setPlayCartAnimation("animation shake")
			setTimeout( () => setPlayCartAnimation(undefined, 1000) )
		}
	},[cart])

	return (
		<AppBar
			position={ (profileMenuOpen || catalogMenuOpen) ? "relative" : "static" }
			color="background"
			elevation={1}
			sx={{zIndex: 3}}
		>
			<Toolbar sx={{overflow: "hidden"}}>

				<Box sx={{ mr: "auto", display: {xs: "block", md: "none" } }} >
					<Link to={routeNames.root + routeNames.search}>
						<IconButton sx={{mx: 1}}>
							<SearchIcon />
						</IconButton>
					</Link>
				</Box>

				<Box
					// sx={theme=>({fill: theme.palette.black.main, height: 20, ml: { xs: "auto", md: 0}, pl: {xs: 9, md: 0} })}
					sx={theme=>({fill: theme.palette.black.main, height: 20, ml: { xs: 0, md: 0}, pl: {xs: 0, md: 0} })}
				>
					<Link to={routeNames.root} style={{textDecoration: "none"}} onClick={handleMobileMenus}>
						<Logo width="91" height="20" />
					</Link>
				</Box>

				<Box sx={{ flexGrow: 1, display: {xs: "none", md: "block" }, position: "relative" }}>
					{matchesMD && <CatalogTabs />}
				</Box>

				<Box sx={{
						mr: 10,
						display: {xs: "none", md: "flex" }, 
						"@media(max-width: 1200px)": { mr: 2 }
					}}
				>
					<Button
						variant="contained"
						color="pale"
						startIcon={<AddIcon />}
						disableElevation={false}
						sx={{
							mr: 10,
							"@media(max-width: 1200px)": { mr: 2 }
						}}
						component={ auth.isSignedIn ? Link : null }
						to={ auth.isSignedIn ? ( config.is_valid_seller ? routeNames.uploadProduct : `${routeNames.fillSellerInfo}` ) : null}
						onClick={ev => {
							if (!auth.isSignedIn) { dispatch(showLoginFormAction())}
						}}
					>
						<L term="nav.iupload_product" />
					</Button>
					<Link to={routeNames.root + routeNames.cart}>
						<IconButton sx={{mx: 1}} className={playCartAnimation}>
							<Badge badgeContent={ Array.isArray(cart) && cart.length > 0 ? cart.length : undefined } color="secondary">
								<CartIcon />
							</Badge>
						</IconButton>
					</Link>
					{/* <Link to={routeNames.root + routeNames.search}>
						<IconButton sx={{mx: 1}}>
							<SearchIcon />
						</IconButton>
					</Link> */}
					<Box component={Link} to={routeNames.settingsOrders}
						sx={{"@media(max-width: 1000px)": { display: "none" }}}
					>
						<IconButton sx={theme=>({mx: 1, color: theme.palette.grey.main})}>
							{/* <Badge badgeContent={ unread_notification_count } color="secondary"> */}
								<GiftIcon />
							{/* </Badge> */}
						</IconButton>
					</Box>
					{!config.messages_enabled && (
						<Box
							component={ auth?.isSignedIn ? Link : undefined }
							to={ auth?.isSignedIn ? routeNames.favorites : undefined }
							onClick={ev => {if (!auth?.isSignedIn) { dispatch(showLoginFormAction())} }}
							sx={{"@media(max-width: 1000px)": { display: "none" }}}
						>
							<IconButton
								sx={{
									mx: 1
								}}
							>
								<HeartIcon/>
							</IconButton>
						</Box>
					)}
					{config.messages_enabled && (
						<Box
							component={ auth?.isSignedIn ? Link : undefined }
							to={ auth?.isSignedIn ? routeNames.messages : undefined }
							onClick={ev => {if (!auth?.isSignedIn) { dispatch(showLoginFormAction())} }}
						>
							<IconButton
								sx={{
									mx: 1
								}}
							>
								<Badge badgeContent={ unread_message_thread_count } color="error">
									<MessageIcon/>
								</Badge>
							</IconButton>
						</Box>
					)}
				</Box>

				<Box sx={{ display: {xs: "none", md: "flex" } }} >
					{auth.isSignedIn&&
						<React.Fragment>
							<UserMenu sx={{ml: 4}}/>
						</React.Fragment>
					}
					{!auth.isSignedIn&&
						<React.Fragment>
							<Button
								variant="contained"
								color="pale"
								disableElevation
								onClick={() => dispatch(showLoginFormAction())}
								sx={{mr: 4}}
							>
								<L>nav.login</L>
							</Button>
							<Button
								sx={{
									"@media(max-width: 1130px)": {
										display: "none"
									}
								}}
								variant="outlined"
								color="black"
								onClick={() => dispatch(showSignUpFormAction())}
							>
							<L>nav.sign_up</L>
							</Button>
						</React.Fragment>
					}
				</Box>
				<Box sx={{ ml: "auto", display: {xs: "block", md: "none" } }} >
					<Link to={routeNames.root + routeNames.cart} onClick={handleMobileMenus}>
						<IconButton sx={{mx: 1}}>
							<Badge badgeContent={ Array.isArray(cart) && cart.length > 0 ? cart.length : undefined } color="secondary">
								<CartIcon />
							</Badge>
						</IconButton>
					</Link>
				</Box>
			</Toolbar>
		</AppBar>
	);
};


export default Header;

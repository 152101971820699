import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, useNavigationType } from 'react-router-dom';
// import { useSearchSizeRouteMatch } from '../../../hooks/useSearchSizeRouteMatch';
import ProductList from '../Product/ProductList';
import { searchProducts, clearProductListAction, toggleFavoriteProduct } from '../../../actions/searchActions';
import useStoreState from '../../../hooks/useStoreState';
import { ProductListContext } from '../../../contexts/ProductListContext';
import { showLoginFormAction } from '../../../actions/appActions';
import {Box} from '@mui/material'
// import ProductFilter from '../Product/ProductFilter';
import MobileSearchProductFilter from '../Product/MobileSearchProductFilter';
import { usePager } from '../../../hooks/usePager';
import { routeNames } from '../../../constants/uiRouteNames';
import useFromSearchParams from '../../../hooks/useFromSearchParams';
import useMediaQuery from '@mui/material/useMediaQuery';

const SearchProductList = ({
	searchTag,
	setSearchTag,
	searchOptionsByAttribute,
	setSearchOptionsByAttribute,
	// inputValue,
	// setInputValue

	toggleSearchOption
}) =>
{
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const navigationType = useNavigationType();
	const matchesMD = useMediaQuery((theme) => theme.breakpoints.up('md'));

	// const matchesMD = useMediaQuery((theme) => theme.breakpoints.up('md'));
	const { productList, pagination, setFavoriteIsPending } = useStoreState('search');

	const auth = useStoreState('auth');

	const [initialProductListLength] = React.useState(productList?.length || 0);
	const { filterKey, searchTagKey, stringify } = useFromSearchParams();

	const [ tag, setTag ] = React.useState(undefined);
	const [ pattern, setPattern ] = React.useState(undefined);
	const [ filter, setFilter ] = React.useState( undefined );

	const [ order/*, setOrder*/ ] = React.useState("updated_at.desc");
	const [ pager, dispatchPager ] = usePager({});

	const [ reverseDataFlow, setReverseDataFlow ] = React.useState(false);

	const clearFilter = () => {
		setFilter(undefined)
		// setOrder(undefined)
		dispatchPager({type: 'first-page'});
  }

	const handleFilterChange = React.useCallback((attribute, options) =>
	{
		if ( !attribute ) {
			return;
		}

		switch( attribute )
		{
			// case 'price':
			// 	setFilter( filter => ({ ...filter, price: options }));
			// 	break;
			// case 'children-attributes':
			// 	setFilter(options);
			// 	break;
			default:
				const filterOff = !( Array.isArray(options) && options.length > 0 );

				if (filterOff)
				{
					if ( filter && Object.keys(filter).includes( attribute.toString() ) && Object.keys(filter).length === 1 )
					{
						setFilter(undefined);
					}
					else
					{
						setFilter( filter => ({ ...filter, [attribute]: undefined }));
					}
				}
				else {
					setFilter( filter => ({ ...filter, [attribute]: options }))
				}

			dispatchPager({type: 'first-page'})
		}
  }, [dispatchPager, filter]);

	const setParams = ({filter,order}) => {
		setFilter(filter)
		// setOrder(order)
		dispatchPager({type: 'first-page'})
  }

	React.useEffect(() => {
		setFilter( searchOptionsByAttribute );
	}, [searchOptionsByAttribute]);
	
	React.useEffect(() =>
	{
		switch( searchTag?.type ) {
			case "tag":
				setTag(searchTag.label);
				setPattern(undefined);
				break;
				case "pattern":
					setPattern(searchTag.pattern);
					setTag(undefined);
					break;
				default:
					setTag(undefined);
					setPattern(undefined);
		}

		if ( !searchTag?.reverse ) {
			dispatchPager({type: 'first-page'});
		}
	}, [dispatchPager, searchTag])

	React.useEffect(() => {
		if ( reverseDataFlow && tag ) {
			setSearchTag({ label: tag, type: "tag" });
			setReverseDataFlow(false);
		}
	}, [setSearchTag, tag, reverseDataFlow]);
	
	React.useEffect(() => {
		if ( !reverseDataFlow ) { return ; }

		if ( pattern ) {
			setSearchTag({ label: '', pattern, type: "pattern" });
		}
		
		if ( tag ) {
			setSearchTag({ label: tag, type: "tag" });
		}

		if ( filter ) {
			setSearchOptionsByAttribute( options => ({ ...filter  }) )
		}

		setReverseDataFlow(false);
	}, [setSearchTag, setSearchOptionsByAttribute, reverseDataFlow, pattern, tag, filter]);

	React.useEffect(() =>
	{
		if ( pager.clear ) {
			dispatch(clearProductListAction());
		}
		
		if ( pager.get ) {
			dispatch(searchProducts({
				filter: filter && Object.keys(filter).length > 0 ? filter : undefined,
				order,
				tag,
				pattern,
				page: pager.page
			}))
		}
	}, [dispatch, filter, order, pager, tag, pattern]);

	const loadNextPage = () => {
		dispatchPager({type: 'next-page'})
	}

	React.useEffect(() => {
		let newState = undefined;

		if ( pager.page !== 1 ) {
			newState = { ...newState, page: pager.page };
		}

		if (filter) {
			// newState = { ...newState, filter };
		}
		
		if (tag) {
			// newState = { ...newState, tag };
		}
		
		if (pattern) {
			// newState = { ...newState, pattern };
		}

		// if ( newState || filter || searchTag ) {
			navigate({
				pathname: location.pathname,
				search: [
					filter ? `${filterKey}=${stringify(filter)}` : '',
					searchTag ? `${searchTagKey}=${stringify(searchTag)}` : '',
				].filter( v => !!v ).join('&')
			}, { replace: true, state: newState} )
		// }
	},[navigate, filterKey, searchTagKey, stringify, location.pathname, pager.page, filter, tag, pattern, searchTag]);

	React.useEffect(() => {
		const noProducts = initialProductListLength === 0;

		switch( navigationType ) {
			case "POP":
				dispatchPager({type: "prev-page", page: noProducts ? 1 : location.state?.page || 1, get: noProducts});
				if (location.state) {
					// if ( location.state?.filter ) {
					// 	setFilter(location.state?.filter);
					// }
					// if (location.state?.tag) {
					// 	setTag(location.state?.tag);
					// }
					if (location.state?.pattern) {
						setPattern(location.state?.pattern);
					}
					setReverseDataFlow(true);
				}

				break;
			case "PUSH":
				// setFilter(searchSizeAttribute?.id ? { [searchSizeAttribute?.id]: [searchSizeAttributeOption?.id] } : undefined);
				dispatchPager({type: "first-page"});
				break;
			default:
		}
	},[dispatchPager, navigationType, location, initialProductListLength/*, searchSizeAttribute, searchSizeAttributeOption*/]);

	const setIsFavorite = (productId) => {
		if ( !auth?.isSignedIn ) {
			dispatch(showLoginFormAction());
		}
		else {
			dispatch(toggleFavoriteProduct(productId))
		}
	}

	React.useEffect(() => {
		if ( pager.page === 1 && pager.get ) {
			window.scrollTo(0, 0)
		}
	}, [pager.page, pager.get])
	


	return (
		<React.Fragment>
			<Box
				sx={{
					display: "flex",
					mb: 4,
					flexWrap: "wrap",
					alignItems: "center"
				}}
			>
				{!matchesMD&&<MobileSearchProductFilter
					// category={requestedCategory}
					{ ...{ filter, order, clearFilter, handleFilterChange, setParams, toggleSearchOption } }
				/>}
			</Box>

			<ProductListContext.Provider
				value={{
					spot: routeNames.search,
					pagination,
					loadNextPage: ( pagination && pagination.lastPage > pagination.currentPage ) ? loadNextPage : undefined,
					setIsFavorite,
					setFavoriteIsPending,
					searchTag
				}}
			>
				<ProductList list={productList}/>
			</ProductListContext.Provider>
		</React.Fragment>
	);
}

export default SearchProductList;
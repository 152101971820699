import React from 'react';
import {useSelector} from 'react-redux';
import {dictSelector} from '../selectors';

export const _useDict = () => {
	return useSelector( dictSelector );
}

export const useDict = () => {
	const {app: appDict} = _useDict();

	const text = React.useCallback((dictKeyPrefix, dictKey, replacements = {}, fallBackToDictKey = false) => {
		if ( !['error', 'warning', 'success', 'confirm', 'button', 'label'].includes(dictKeyPrefix) ) {
			return fallBackToDictKey ? dictKey : '';
		}

		let text = appDict[ `${dictKeyPrefix}.${dictKey}` ];

		if ( Object.entries(replacements).length > 0 ) {
			for ( let [ search, replace ] of Object.entries(replacements) ) {
				text = text.replace( `:${search}`, replace );
			}
		}

		return text;
	}, [appDict]);

	const label = React.useCallback((...args) => text( 'label', ...args ),[text]);
	const errorMessage = React.useCallback((...args) => text( 'error', ...args ),[text]);
	const warningMessage = React.useCallback((...args) => text( 'warning', ...args ),[text]);
	const successMessage = React.useCallback((...args) => text( 'success', ...args ),[text]);
	const confirmMessage = React.useCallback((...args) => text( 'confirm', ...args ),[text]);

	const buttonLabel = (dictKey) => text('button', dictKey, {}, true)

	return {
		text,
		label,
		errorMessage,
		warningMessage,
		successMessage,
		confirmMessage,
		buttonLabel
	};
}
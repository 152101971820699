export const env = () => {
	return process.env.NODE_ENV;
};

export const isDev = () => {
	return env() === "development";
};

export const isProd = () => {
	return env() === "production";
};
import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {routeNames} from '../constants/uiRouteNames';
import useStoreState from '../hooks/useStoreState';

import Login from './Auth/Login';
import SignUp from './Auth/SignUp';
import ForgotPassword from './Auth/ForgotPassword';
import ResetPassword from './Auth/ResetPassword';
import EmailVerification from './Auth/EmailVerification';

import { hideLoginFormAction, hideSignUpFormAction, hideForgotPasswordFormAction, hideResetPasswordFormAction } from '../actions/appActions';

const AppLayers = ({immediateAction,onDissmissNavigateTo}) =>
{
	const appState = useStoreState('app');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	React.useEffect(() => {
		if ( Array.isArray(immediateAction) )
		{
			immediateAction.every( action => dispatch(action) );
		}
	}, [dispatch, immediateAction]);

	const modalDismissWith = (action) => (...args) =>
	{
		dispatch(action);

		if ( !!immediateAction ) {
			if ( onDissmissNavigateTo ) {
				navigate(onDissmissNavigateTo);
			}
			else {
				navigate(routeNames.root);
			}
		}
	}

	if ( appState.loginFormIsVisible ) {
		return (
			<Login onModalDismiss={modalDismissWith(hideLoginFormAction())} />
		);
	}
	
	if ( appState.signUpFormIsVisible ) {
		return (
			<SignUp onModalDismiss={modalDismissWith(hideSignUpFormAction())} />
		);
	}
	
	if ( appState.passwordForgotFormIsVisible ) {
		return (
			<ForgotPassword onModalDismiss={modalDismissWith(hideForgotPasswordFormAction())} />
		);
	}
	
	if ( appState.passwordResetFormIsVisible ) {
		return (
			<ResetPassword onModalDismiss={modalDismissWith(hideResetPasswordFormAction())} />
		);
	}
	
	if ( appState.emailVerificationIsVisible ) {
		return (
			<EmailVerification onModalDismiss={modalDismissWith(hideResetPasswordFormAction())} />
		);
	}

	return null;
}

export default AppLayers;
import React from 'react';
// import { Link } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import DashboardSlider from './DashboardSlider';
// import HalfPriceDeliverySlider from './HalfPriceDeliverySlider';
// import AlmostZeroDeliverySlider from './AlmostZeroDeliverySlider';
import {getContent, toggleFavoriteProduct} from '../../../actions/dashboardActions';
import useStoreState from '../../../hooks/useStoreState';
// import ProductList from '../Product/ProductList';
import {ProductListContext} from '../../../contexts/ProductListContext';
import { Box } from '@mui/material';
import { showLoginFormAction } from '../../../actions/appActions';
import { routeNames } from '../../../constants/uiRouteNames';
// import L from '../../Helpers/Lang';
import Faq from '../../Faq';
import DashboardContent from './DashboardContent';

const Dashboard = () =>
{
	const dispatch = useDispatch();
	const auth = useStoreState('auth');
	// const { promo : {
	// 	AlmostZeroDelivery : PromoAlmostZeroDelivery,
	// } } = useStoreState('config');

	const { content, setFavoriteIsPending } = useStoreState('dashboard');
	// const [ page, setPage ] = React.useState(1);

	const setIsFavorite = (productId) => {
		if ( !auth?.isSignedIn ) {
			dispatch(showLoginFormAction());
		}
		else {
			dispatch(toggleFavoriteProduct(productId))
		}
	}

	// React.useEffect(() => {
	// 	dispatch( getProductList({page}) );

	// 	// return () => dispatch(clearProductListAction());
	// },[dispatch, page]);
	
	React.useEffect(() => {
		dispatch( getContent() );
	},[dispatch]);

	return (
			<>
				<DashboardSlider startWith={"first"/*"random"*/}/>
				{/* {( PromoHalfPriceDelivery?.enabled && !PromoHalfPriceDelivery?.validForUser ) && <HalfPriceDeliverySlider startWith={"first"}/>} */}
				{/* {PromoAlmostZeroDelivery?.enabled && <AlmostZeroDeliverySlider startWith={"first"}/>} */}
				<Box
					sx={{
						px: { xs: 2, sm: 14 },
					}}
				>
					<ProductListContext.Provider
						value={{
							spot: routeNames.dashboard,
							// pagination,
							// setPage,
							setIsFavorite,
							setFavoriteIsPending
						}}
					>
						{/* <ProductList list={productList}/> */}
						<DashboardContent content={content}/>
					</ProductListContext.Provider>
					{/* {productList && (
						<Box sx={{textAlign:"center", mb: 26}}>
							<Button variant="outlined" color="black"
									component={Link} to={`/${routeNames.catalog}`}
								><L term="label.got_to_catalog"/></Button>
						</Box>
					)} */}
					<Faq />
				</Box>
			</>
		);
};

export default Dashboard;

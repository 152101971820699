export const srcSet1x2x = (image, variants = [], fallback) => {
	if (!image) { return undefined };

	const {srcset: srcSet} = image

	if ( !srcSet && fallback ) { return { src: fallback }; }

	if (!srcSet) { return undefined };

	const [ _1x, _2x ] = variants

	if (!( _1x && _2x ) ) { return undefined };

	if (!( srcSet?.[_1x] && srcSet?.[_2x] ) ) { return undefined };

	return {
		src: `${srcSet[ _1x ]}`,
		srcSet: `${srcSet[ _1x ]} 1x, ${srcSet[ _2x ]} 2x`
	}
}
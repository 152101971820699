import { PRODUCT, SETTINGS } from '../constants';

const initialState = {
	loaded: false,
	product: null,
	formConfig: null,
	temporaryImages: [],
	failedImages: [],
	failedImagesError: undefined,
	productError: null,
	responseErrors: null,
	attributeListById: null,
	categoryListById: null,
	saved: undefined,
	created: undefined,
	firstProductUpload: undefined,
	productCount: undefined,
	productSizeWarningVisible: false,
	savedProduct: undefined,
	highlightResult: undefined,
	highlightResultStatus: undefined,
	highlightedProduct: undefined,
	purchaseHighlightProductDialogIsVisible: false,
	voucherCode: undefined,

	deliveryOptionList: undefined,
	userDeliveryOptions: undefined,
	requestIsPending: false,
	pendingProductId: undefined,

	error: undefined
};

const editProduct = (state=initialState, action) => {
	switch(action.type)
	{
		case PRODUCT.SET_PRODUCT_CREATE_DATA:
			return {
				...state,
				loaded: true,
				product: action.data?.product,
				temporaryImages: action.data?.images,
				formConfig: action.data?.form_config,
				attributeListById: action.data?.attributes,
				categoryListById: action.data?.categories,
				deliveryOptions: action.data?.delivery_options,
				productCount: action.data?.product_count,
				voucherCode: action.data?.voucher_code,
				error: action.error
			};
		case SETTINGS.SET_DELIVERY_OPTIONS:
			return {
				...state,
				deliveryOptions: action.options,
			};
		case PRODUCT.DELETE_TEMPORARY_IMAGE:
			return {
				...state,
				temporaryImages: state.temporaryImages.filter(image=>image.id !== action.id)
			};
		case PRODUCT.ADD_TEMPORARY_IMAGE:
			return {
				...state,
				temporaryImages: [ ...state.temporaryImages, action.image ]
			};
		case PRODUCT.UPDATE_TEMPORARY_IMAGE:
			return {
				...state,
				temporaryImages: state.temporaryImages?.map( image => image.uid === action.uid ? action.image : image)
			};
		case PRODUCT.UPDATE_IMAGE_PRIMARY:
			return {
				...state,
				temporaryImages: state.temporaryImages?.map( image => image.id === action.image?.id ? action.image : { ...image, primary: 0 } )
			};
		case PRODUCT.DELETING_TEMPORARY_IMAGE:
			return {
				...state,
				temporaryImages: state.temporaryImages?.map( image => image.id === action.id ? { ...image, deleting: true } : image)
			};
		case PRODUCT.IMAGE_UPLOAD_FAILED:
			return {
				...state,
				failedImages: [ ...state.failedImages, action.image ],
				failedImagesError: action.error
			};
		case PRODUCT.CLEAR_FAILED_IMAGES:
			return {
				...state,
				failedImages: [],
				failedImagesError: undefined,
			};
		case PRODUCT.CLEAR_PRODUCT_CREATE_DATA:
			return initialState;
			
		case PRODUCT.SET_PRODUCT_ERROR:
			return {
				...state,
				productError: action.error
			};
		case PRODUCT.CLEAR_PRODUCT_ERROR:
			return {
				...state,
				productError: null
			}
		case PRODUCT.SET_RESPONSE_ERROR:
			return {
				...state,
				responseError: action.error
			};
		case PRODUCT.CLEAR_RESPONSE_ERROR:
			return {
				...state,
				responseError: null
			}
		case PRODUCT.SAVED:
			return {
				...state,
				saved: true,
				created: action.create,
				firstProductUpload: action.firstProductUpload,
				savedProduct: action.savedProduct
			}
		case PRODUCT.CLEAR_SAVED:
			return {
				...state,
				saved: undefined,
				created: undefined,
				firstProductUpload: undefined,
				savedProduct: undefined
			}
		case PRODUCT.SHOW_PRODUCT_SIZE_WARNING:
			return {
				...state,
				productSizeWarningVisible: true
			}
		case PRODUCT.HIDE_PRODUCT_SIZE_WARNING:
			return {
				...state,
				productSizeWarningVisible: false
			}
		case PRODUCT.SET_REQUEST_IS_PENDING:
			return {
				...state,
				requestIsPending: action.value,
				pendingProductId: action.value ? action.product_id : undefined
			}
		case PRODUCT.SET_HIGLIGHT_RESULT:
			return {
				...state,
				highlightResult: action.result,
				highlightResultStatus: action.status,
				highlightedProduct: action.product
			}
		case PRODUCT.PURCHASE_HIGHLIGH_DIALOG_VISIBILITY:
			return {
				...state,
				purchaseHighlightProductDialogIsVisible: action.value,
			}
		default:
			return state;
	}
};

export default editProduct;
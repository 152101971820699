import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, useNavigationType } from 'react-router-dom';
import { useSearchSizeRouteMatch } from '../../../hooks/useSearchSizeRouteMatch';
import ProductList from '../Product/ProductList';
import { searchProducts, clearProductListAction, toggleFavoriteProduct } from '../../../actions/searchActions';
import useStoreState from '../../../hooks/useStoreState';
import { ProductListContext } from '../../../contexts/ProductListContext';
import { showLoginFormAction } from '../../../actions/appActions';
import {Typography,Box} from '@mui/material'
// import ProductFilter from '../Product/ProductFilter';
// import MobileProductFilter from '../Product/MobileProductFilter';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { usePager } from '../../../hooks/usePager';
// import bp from '../../../utils/BarionPixel';
import { routeNames } from '../../../constants/uiRouteNames';

const SearchSizeProductList = () =>
{
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const navigationType = useNavigationType();

	// const matchesMD = useMediaQuery((theme) => theme.breakpoints.up('md'));
	const { productList, pagination, setFavoriteIsPending } = useStoreState('search');

	const auth = useStoreState('auth');
	const {
		attribute: searchSizeAttribute,
		option: searchSizeAttributeOption
	} = useSearchSizeRouteMatch();

	const [initialProductListLength] = React.useState(productList?.length || 0);

	const [ filter, setFilter ] = React.useState( undefined );
	const [ order/*, setOrder*/ ] = React.useState("updated_at.desc");
	const [ pager, dispatchPager ] = usePager({});

	// // Barion Pixel <<<
	// React.useEffect(() => {
	// 	const properties = {
	// 		id: requestedCategoryId,
	// 		name: requestedCategoryName
	// 	};
	// 	bp.track('categorySelection', properties);

	// }, [requestedCategoryId, requestedCategoryName])
	// // >>> Barion Pixel

	// React.useEffect(() => {
	// 	setFilter(searchSizeAttribute?.id ? { [searchSizeAttribute?.id]: [searchSizeAttributeOption?.id] } : undefined);
	// 	dispatchPager({type: 'first-page'})
	// 	return () => {
	// 		setFilter(undefined);
	// 	};
	// }, [dispatchPager, searchSizeAttribute, searchSizeAttributeOption]);

	React.useEffect(() =>
	{
		if ( pager.clear ) {
			dispatch(clearProductListAction());
		}
		
		if ( pager.get ) {
			dispatch(searchProducts({
				filter: filter && Object.keys(filter).length > 0 ? filter : undefined,
				order: order,
				page: pager.page
			}))
		}
	}, [dispatch, filter, order, pager]);

	const loadNextPage = () => {
		dispatchPager({type: 'next-page'})
	}

	React.useEffect(() => {
		let newState = undefined;

		if ( pager.page !== 1 ) {
			newState = { page: pager.page };
		}

		if (filter) {
			newState = newState ? { ...newState, filter: filter } : { filter };
		}

		navigate( location.pathname, { replace: true, state: newState} )
	},[navigate, location.pathname, pager.page, filter]);

	React.useEffect(() => {
		const noProducts = initialProductListLength === 0;

		switch( navigationType ) {
			case "POP":
				dispatchPager({type: "prev-page", page: noProducts ? 1 : location.state?.page || 1, get: noProducts});
				if (location.state?.filter) {
					setFilter(location.state?.filter);
				}
				break;
			case "PUSH":
				setFilter(searchSizeAttribute?.id ? { [searchSizeAttribute?.id]: [searchSizeAttributeOption?.id] } : undefined);
				dispatchPager({type: "first-page"});
				break;
			default:
		}
	},[dispatchPager, navigationType, location, initialProductListLength, searchSizeAttribute, searchSizeAttributeOption]);

	const setIsFavorite = (productId) => {
		if ( !auth?.isSignedIn ) {
			dispatch(showLoginFormAction());
		}
		else {
			dispatch(toggleFavoriteProduct(productId))
		}
	}

	// const handleFilterChange = (attribute, options) => {
	// 	if ( !attribute ) {
	// 		return;
	// 	}

	// 	switch( attribute )
	// 	{
	// 		case 'price':
	// 			setFilter( filter => ({ ...filter, price: options }));
	// 			break;
	// 		default:
	// 			if ( ( filter && Object.keys(filter).includes( attribute.toString() ) ) || !Array.isArray(options) || options.length === 0 )
	// 			{
	// 				if ( filter && Object.keys(filter).includes( attribute.toString() ) && Object.keys(filter).length === 1 )
	// 				{
	// 					setFilter(undefined)
	// 				}
	// 				else {
	// 					setFilter( filter => ({ ...filter, [attribute]: undefined }))
	// 				}
	// 			}
	// 			else {
	// 				setFilter( filter => ({ ...filter, [attribute]: options }))
	// 			}
	// 		dispatchPager({type: 'first-page'})
	// 	}
  // }
	
	// const handleOrderChange = (order) => {
	// 	setOrder(order)
	// 	dispatchPager({type: 'first-page'})
	// }
	// const handlePriceRangeChange = (price) => handleFilterChange('price', price)
	
	// const clearFilter = () => {
	// 	setFilter(undefined)
	// 	// setOrder(undefined)
	// 	dispatchPager({type: 'first-page'});
  // }
	
	// const setParams = ({filter,order}) => {
	// 	setFilter(filter)
	// 	setOrder(order)
	// 	dispatchPager({type: 'first-page'})
  // }

	React.useEffect(() => {
		if ( pager.page === 1 && pager.get ) {
			window.scrollTo(0, 0)
		}
	}, [pager.page, pager.get])

	// React.useEffect(() => {
	// 	return () => {
	// 		dispatchPager({type: 'first-page'});
	// 	};
	// }, [dispatchPager, searchSizeAttribute, searchSizeAttributeOption]);

	return (
		<React.Fragment>
			<Box
				sx={{
					display: "flex",
					mb: 4,
					flexWrap: "wrap",
					alignItems: "center"
				}}
			>
	 			<Typography variant="h3" sx={{
					":first-letter": { textTransform: "capitalize" },
					mr: "auto"
				}}>
					{searchSizeAttribute?.label} - {searchSizeAttributeOption?.name}
				</Typography>
				{/* {!matchesMD&&<MobileProductFilter
					category={requestedCategory}
					{ ...{ filter, order, clearFilter, handleFilterChange, handlePriceRangeChange, handleOrderChange, setParams } }
				/>} */}
			</Box>

			{/* {matchesMD && (
				<ProductFilter
					category={requestedCategory}
					{ ...{ filter, order, clearFilter, handleFilterChange, handlePriceRangeChange, handleOrderChange } }
				/>
			)} */}

			<ProductListContext.Provider
				value={{
					spot: routeNames.searchSize,
					pagination,
					loadNextPage: ( pagination && pagination.lastPage > pagination.currentPage ) ? loadNextPage : undefined,
					setIsFavorite,
					setFavoriteIsPending
				}}
			>
				<ProductList list={productList}/>
			</ProductListContext.Provider>
		</React.Fragment>
	);
}

export default SearchSizeProductList;
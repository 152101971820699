import { combine, bold, blueBg, white, red } from'./colorConsole';
import {isDev} from './env';

class FacebookPixel
{
	constructor()
	{
		this.dev = isDev();

		this.fbq = window.fbq;

		if ( typeof this.fbq === 'function' ) {
			this.valid = true;
		}
	}

	static track (event, properties)
	{
		this.handler = new FacebookPixel();
		if ( !this.handler.valid ) {
			if ( this.handler.dev ) {
				console.log(`%cinvalid fbq ${event} TRACK`, ...[ [ red ] ].map( styleArray => combine(...styleArray) ) );
			}
			return;
		}

		if ( this.handler.dev ) {
			console.log(`%c${event}`, ...[ [ blueBg, white, bold ] ].map( styleArray => combine(...styleArray) ) );
			console.log(properties);
		}

		try {
			this.handler.fbq('track', event, properties);
		} catch(error) {
			console.warn( error );
		}
		if ( this.handler.dev ) {
			console.log(`%csuccess fbq ${event} TRACK`, ...[ [ blueBg, white ] ].map( styleArray => combine(...styleArray) ) );
		}
	}
}

export default FacebookPixel;
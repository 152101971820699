const uzYellow1 = "#F6CC45";
const uzYellow2 = "#F6D264";
const uzYellow5 = "#E9B100";
const uzYellow3 = "#FBEBB5";
const uzYellow4 = "#FDF5DA";

const uzGreen1 = "#4C982E";
const uzGreen2 = "#7AA77B";
const uzGreen3 = "#B7D6AB";
const uzGreen4 = "#DBEAD5";

const uzBlue1 = "#006992";
const uzBlue2 = "#E6F0F4";
const uzBlue3 = "#A5C6BE";

const uzRed1 = "#D83B3B";
const uzOrange1 = "#F45D01";
const uzOrange2 = "#f78e4d";
const uzOrange3 = "#faae80";

const uzWhite1 = "#fff";
const uzBlack1 = "#202020";

const uzGrey1 = "#474747";
const uzGrey2 = "#757575";
const uzGrey3 = "#888888";
const uzGrey4 = "#969696";
const uzGrey5 = "#B0B0B0";
const uzGrey6 = "#CBCBCB";
const uzGrey7 = "#E5E5E5";
const uzGrey8 = "#F3F3F3";
const uzGrey9 = "#F3F6F8";

const lightPalette = {
	mode: 'light',
	background: {
		// default: `${uzGrey8}`,
	},
	primary: {
		main: `${uzYellow1}`,
		main1: `${uzYellow1}`,
		main2: `${uzYellow2}`,
		main5: `${uzYellow5}`,
		main3: `${uzYellow3}`,
		main4: `${uzYellow4}`
	},
	secondary: {
		main: `${uzBlue1}`,
	},
	info: {
		main: `${uzBlue1}`,
		main1: `${uzBlue1}`,
		main2: `${uzBlue2}`,
		main3: `${uzBlue3}`,
	},
	success: {
		main: `${uzGreen1}`,
		main1: `${uzGreen1}`,
		main2: `${uzGreen2}`,
		main3: `${uzGreen3}`,
		main4: `${uzGreen4}`,
	},
	error: {
		main: `${uzRed1}`
	},
	warning: {
		main: `${uzOrange1}`,
		main2: `${uzOrange2}`,
		main3: `${uzOrange3}`,
	},
	black: {
		main: `${uzBlack1}`
	},
	white: {
		main: `${uzWhite1}`
	},
	grey: {
		main: `${uzGrey1}`,
		main1: `${uzGrey1}`,
		main2: `${uzGrey2}`,
		main3: `${uzGrey3}`,
		main4: `${uzGrey4}`,
		main5: `${uzGrey5}`,
		main6: `${uzGrey6}`,
		main7: `${uzGrey7}`,
		main8: `${uzGrey8}`,
		main9: `${uzGrey9}`
	},
	pale: {
		main: `${uzYellow3}`,
	}
};

export default lightPalette;
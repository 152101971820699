import React from 'react';
import {usePost} from '../../hooks/useApi';
import {APP as URLS} from '../../constants/urls';

import Button from '@mui/material/Button';
import ResponseLayer from '../Helpers/ResponseLayer';

import L from '../Helpers/Lang'


const EmailVerificationResult = (props) =>
{
	const { response /*, error*/ } = props;
	let [ sendVerificationNotification, {isDone: verificationNotificationWasTried, isSuccess: verificationNotificationWasSuccesful} ] = usePost(URLS.verificationNotification);

	if (!response) return null;

	if ( response.error )
	{
		if ( verificationNotificationWasTried )
		{
			const responseLayerProps = {
				success: verificationNotificationWasSuccesful,
				title: <L term='email-verification.resend.title' />,
				message: <L term={`email-verification.resend.${verificationNotificationWasSuccesful?'success':'error'}.message`} />,
				button: (
					<Button
						variant="contained"
						color={verificationNotificationWasSuccesful?'success':'error'}
						onClick={props.onModalDismiss}
						sx={{mt: 6}}
					>
						{verificationNotificationWasSuccesful?<L term="button.okay"/>:<L term="button.igot_it"/>}
					</Button>
				)
			};

			return <ResponseLayer
				onDismiss={props.onModalDismiss}
				{...responseLayerProps }
			/>;
		}

		const responseLayerProps = {
			error: true,
			button: (
				<Button
					variant="contained"
					color="error"
					onClick={()=>sendVerificationNotification()}
				>
					<L term='button.email-verification.resend' />
				</Button>
			)
		};

		responseLayerProps.title = <L term='email-verification.success.title' />;
		responseLayerProps.message = (
			<>
			<L term='email-verification.error.message' />
			{/* <Typography variant="body2">{response.message}</Typography> */}
			</>
		);

		return <ResponseLayer
			onDismiss={props.onModalDismiss}
			{ ...responseLayerProps }
		/>;
	}

	if ( response.success )
	{
		const responseLayerProps = {
			success: true,
			button: (
				<Button
					variant="contained"
					color="primary"
					onClick={props.onModalDismiss}
					sx={{mt: 6}}
				>
					<L term="button.igot_it"/>
				</Button>
			)
		};

		switch( response.status )
		{
			case 202:
				responseLayerProps.title = <L term='email-verification.success.title' />;
				responseLayerProps.message = <L term='email-verification.success.message' />;
				break;
			case 204:
				responseLayerProps.title = <L term='email-verification.success.title' />;
				responseLayerProps.message = <L term='email-verification.already.message' />;
				break;
			default:
				responseLayerProps.title = "";
				responseLayerProps.message = "";
		}

		return <ResponseLayer
			onDismiss={props.onModalDismiss}
			{ ...responseLayerProps }
		/>;
	}
}

export default EmailVerificationResult;
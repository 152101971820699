import { CART } from '../constants';

const INITIAL_STATE = {
		cart: [],
		detailedCart: undefined,
		cartIsPending: false,
		deliveryAddressList: undefined,
		mplDeliveryOptions: undefined,
		deliveryOptions: undefined,
		cartDeliveryOptions: undefined,
		cartUserDeliveryOptions: undefined,
		firstProductAddedToCart: undefined,
		cartOptions: undefined,
		customProductPrices: undefined
};

const init = (state=INITIAL_STATE, action) => {
	switch(action.type)
	{
		case CART.CLEAR_CART:
			return {
				...state,
				cart: INITIAL_STATE.cart
			};
		case CART.SET_CART:
			return {
				...state,
				cart: action.cart || []
			};
		case CART.SET_DELIVERY_ADDRESSLIST:
			return {
				...state,
				deliveryAddressList: action.delivery_addresses || []
			};
		case CART.CLEAR_DETAILED_CART:
			return {
				...state,
				detailedCart: INITIAL_STATE.detailedCart
			};
			
		case CART.SET_DETAILED_CART:
			return {
				...state,
				detailedCart: action.detailed_cart
			};
		case CART.SET_CART_DELIVERY_OPTIONS:
			return {
				...state,
				cartDeliveryOptions: action.cart_delivery_options
			};
		case CART.SET_DELIVERY_OPTIONS:
			return {
				...state,
				deliveryOptions: action.delivery_options
			};
		case CART.SET_CART_USER_DELIVERY_OPTIONS:
			return {
				...state,
				cartUserDeliveryOptions: action.cart_user_delivery_options
			};
		case CART.SET_CART_OPTIONS:
			return {
				...state,
				cartOptions: action.cart_options
			};
			
		case CART.CART_IS_PENDING:
			return {
				...state,
				cartIsPending: action.pending
			};
			
		case CART.CLEAR_MPL_DELIVERY_OPTIONS:
			return {
				...state,
				mplDeliveryOptions: INITIAL_STATE.mplDeliveryOptions
			};
			
		case CART.SET_MPL_DELIVERY_OPTIONS:
			return {
				...state,
				mplDeliveryOptions: action.options
			};
			
		case CART.FIRST_PRODUCT_ADDED_TO_CART:
			return {
				...state,
				firstProductAddedToCart: action.value
			};
		case CART.SET_CART_CUSTOM_PRODUCT_PRICES:
			return {
				...state,
				customProductPrices: action.prices
			};

		default:
			return state;
	}
};

export default init;
const inputOverrides =
{
	MuiInputBase: {
		defaultProps: {
			color: "secondary"
		}
	},
	
	MuiCheckbox: {
		defaultProps: {
			color: "secondary"
		}
	},
	
	MuiOutlinedInput: {
		styleOverrides: {
			input: {
				padding: 14,
			},
			root: {
				borderRadius: 4,
			},
			// notchedOutline: ({theme}) => ({
				// borderColor: theme.palette.grey.main2,
				// "&.Mui-focused": {
				// 	borderCOlor: theme.palette.info.main1
				// }
			// })
		}
	},


	MuiInputLabel: {
		defaultProps: {
			color: "secondary"
		},

		styleOverrides: {
			root: ({theme}) => ({
				// color: theme.palette.black.main,
				transform: 'translate(14px, 14px) scale(1)',
				"&.Mui-focused, &.MuiFormLabel-filled": {
					transform: 'translate(14px, -6px) scale(0.75)',
				}
			})
		},
	}
};

export default inputOverrides;
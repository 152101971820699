import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { routeNames } from '../constants/uiRouteNames';
// import useStoreState from '../hooks/useStoreState';

const landingStoragePrefix = 'UZUZD_landing';

const landingConfig = [
	{
		name: routeNames.whatis,
		enabled: false,
		shouldAppear: 1
	}
];

const Landings = () =>
{
	const navigate = useNavigate();
	const location = useLocation();

	const storageKey = (name) => [ landingStoragePrefix, name ].join('_');

	// const { promo : {
	// 	HalfPriceDelivery : PromoHalfPriceDelivery
	// } } = useStoreState('config');

	// if ( PromoAlmostZeroDelivery?.enabled )
	// {
	// 	landingConfig.unshift({
	// 		name: routeNames.almostZeroDelivery,
	// 		enabled: true,
	// 		shouldAppear: 1
	// 	});
	// }

	// if ( PromoHalfPriceDelivery?.enabled )
	// {
	// 	landingConfig.unshift({
	// 		name: routeNames.halfPriceDelivery,
	// 		enabled: true,
	// 		shouldAppear: 1
	// 	});
	// }

	React.useEffect(() => {
		if ( window.localStorage && location.pathname === routeNames.root )
		{
			let shouldAppear;
			landingConfig.every( configItem => {
				if ( !configItem.enabled ) { return true; }
				
				const appeared = localStorage.getItem( storageKey(configItem.name) );
	
				if ( !appeared )
				{
					shouldAppear = configItem;
					return false;
				}
	
				return true;
			});

			if ( !shouldAppear ) {
				return null;
			}
	
			localStorage.setItem( storageKey(shouldAppear.name), JSON.stringify({ at: new Date().getTime() }) );
			navigate(shouldAppear.name);

		}
	},[navigate, location.pathname]);

	return null;
}

export default Landings;
import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import ProductListItem from '../Product/ProductListItem';
import L from '../../Helpers/Lang';
import { Link } from 'react-router-dom';
import { ReactComponent as ChevronRightIcon } from '../../../assets/icons/chevron-right.svg';
import GoogleAd from '../../Helpers/GoogleAd';

const DashboardGrid = ({children}) => {
	return (
		<Grid container
			spacing={2}
			sx={{
				pt: 4,
				mt: 0,
				px: { xs: 2, sm: 6, md: 0 }
			}}
		>{children}</Grid>
	);
}

const DashboardContent = ({content}) =>
{
	return (
		<>
			{['highlighted','children'].map( blockName => (
				<DashboardGrid key={blockName}>
					<DashboardContentLine blockName={blockName} products={content?.[blockName]} />
				</DashboardGrid>
			))}
			{Boolean(content) && <GoogleAd slot="2475492979" />}
			{['women','men'].map( blockName => (
				<DashboardGrid key={blockName}>
					<DashboardContentLine blockName={blockName} products={content?.[blockName]} />
				</DashboardGrid>
			))}
		</>
	);
};

const catalogLink = {
	women: 'katalogus/noi/ruhazat',
	children: 'katalogus/gyermek',
	men: 'katalogus/ferfi/ruhak',
}

const DashboardContentLine = ({blockName,products}) => {
	return Array.isArray(products) && products.length > 0 ? (
		<>
			{blockName !== 'highlighted' && (
				<Box sx={{display: "flex", flexGrow: 1, justifyContent: 'space-between'}}>
					<Typography variant="h3"><L term={`label.dashboard.${blockName}`} /></Typography>
					<Button
						component={Link}
						to={catalogLink[ blockName ]}
						variant="contained"
						color="primary"
						size="small"
						endIcon={<ChevronRightIcon height="16" width="16" viewBox="0 0 24 24" style={{fill: 'currentColor'}}/>}
					><L term="nav.show_all" /></Button>
				</Box>
			)}
			<DashboardGrid>
				{products?.map( product => (
					<ProductListItem
						key={product.product_id}
						user={product.user}
						product={product}
						ownProfile={false}
					/>
				))}
			</DashboardGrid>
		</>
	) : null;
}

export default DashboardContent;

import { PAYMENT } from '../constants';

const config = (state = {}, action) => {
	switch(action.type)
	{
		case PAYMENT.SET_PAYMENT_STATE:
			return {
				...action.state
			};
		default:
			return state;
	}
};

export default config;
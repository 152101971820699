import React from 'react';
import { useDispatch } from 'react-redux';
import { searchUsers, clearUserListAction } from '../../../actions/searchActions';
import useStoreState from '../../../hooks/useStoreState';
import {Typography, Box, Grid, Avatar, LinearProgress} from '@mui/material'
import { usePager } from '../../../hooks/usePager';
import fallbackAvatar from '../../../assets/images/UZUZD-black-2row.png';
import { Link } from 'react-router-dom';
import { routeNames } from '../../../constants/uiRouteNames';
import { ReactComponent as ChevronRightIcon } from '../../../assets/icons/chevron-right.svg';
import L from '../../Helpers/Lang';

const SearchUserList = ({
	searchTag,
	setSearchTag
}) =>
{
	const dispatch = useDispatch();
	const { userList/*, pagination, setFavoriteIsPending*/ } = useStoreState('search');

	const [ pager/*, dispatchPager*/ ] = usePager({});

	React.useEffect(() =>
	{
		dispatch(clearUserListAction());

		if ( searchTag?.length > 1 ) {
			dispatch(searchUsers({
				pattern: searchTag,
			}))
		}

	}, [dispatch, searchTag]);

	React.useEffect(() => {
		if ( pager.page === 1 && pager.get ) {
			window.scrollTo(0, 0)
		}
	}, [pager.page, pager.get])

	return (
		<UserList {...{userList}}/>
	);
}

export default SearchUserList;

const UserList = ({userList}) => {
	return (
		<Grid container
			spacing={2}
			sx={{
				pt: 10,
				px: { xs: 2, sm: 6, md: 0 }
			}}
		>
			{userList === undefined && (
				<LinearProgress />
			)}
			{userList?.length === 0 && (
				<Box sx={{p: 4, m: 'auto'}}>
					<Typography variant='h5'><L term="text.no-users-found" /></Typography>
				</Box>
			)}
			{userList?.length > 0 && userList?.map(user => (
				<Grid key={user.user_id} item xs={12} sm={6} md={4}
					sx={{ }}
				>
					<UserListItem {...{user}} />
				</Grid>
			))}
		</Grid>
	);
}

const UserListItem = ({user}) => {
	return (
		<Box
			component={Link}
			to={`${routeNames.root}${routeNames.profile}/${user.user_id}`}
			sx={theme=>({
				p: 2,
				bgcolor: theme.palette.grey.main9,
				display: 'flex',
				textDecoration: 'none',
				'&:hover': {
					bgcolor: theme.palette.grey.main7,
				},
				'&:hover svg': {
					transform: 'scale(1.4)',
					color: theme.palette.secondary.main
				},
				borderRadius: 2
			})}
		>
			<Avatar
				alt={user.name}
				src={user.avatar?.xs || fallbackAvatar}
				sx={{ width: 48, height: 48 }}
			/>
			<Box sx={{
				ml: 2,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center'
			}}>
				<Typography variant="h5">{user.name}</Typography>
				<Typography variant="subtitle1">{user.city_name || ''}</Typography>
			</Box>
			<Box sx={theme=>({ml: 'auto', display: 'flex', alignItems: 'center', color: theme.palette.grey.main4})}>
				<ChevronRightIcon style={{fill: 'currentColor'}} />
			</Box>
		</Box>
	);
}
const mixins = {
	mixins: {
		toolbar: ({theme}) => ({
			minHeight: 64,
			paddingLeft: theme.spacing(6),
			paddingRight: theme.spacing(6),
			"@media (min-width:0px) and (orientation: landscape): ": {},
			[theme.breakpoints.up("sm")]: {
				paddingLeft: theme.spacing(6),
				paddingRight: theme.spacing(6),
			}
		})
	},
};

export default mixins;
export const typographyDefinition = {
	typography: {
		// htmlFontSize: 10,
		h1: {
			fontSize: "2.5rem",
			fontWeight: 700,
			lineHeight: "4.375rem"
		},
		h2: {
			fontSize: "2rem",
			fontWeight: 700,
		},
		h3: {
			fontSize: "1.5rem", // 24px
			fontWeight: 700,
			lineHeight: "1.75rem"
		},
		h4: {
			fontSize: "1.125rem", // 18px
			fontWeight: 700,
		},
		h5: {
			fontSize: "1rem",
			fontWeight: 500,
		},
		subtitle: {
			fontSize: "1rem",
			fontWeight: 400,
		},
		menu1: {
			fontSize: "0.9375rem",
			fontWeight: 400,
		},
		body1: {
			fontSize: "0.875rem",
			fontWeight: 400,
			lineHeight: "1.125rem"
		},
		body2: {
			fontSize: "0.75rem",
			fontWeight: 400,
			lineHeight: "0.875rem"
		},
	},
};

export const typographyOverrides = {
	MuiTypography: {
		styleOverrides: {
			root: ({theme}) => ({
				color: theme.palette.black.main
			}),
		}
	}
};
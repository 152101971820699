import React from 'react';
import { alpha } from '@mui/material/styles';
import {Box, Button} from '@mui/material';
import { Link } from 'react-router-dom';
import { routeNames } from '../../../constants/uiRouteNames';
import { ReactComponent as BagIcon } from '../../../assets/icons/shopping-bag.svg';
import L from '../../Helpers/Lang';
import ProductGridItem from './ProductGridItem';

const UploadProductButton = () => {
	return (
		<ProductGridItem>
			<Box
				sx={theme=>({
					bgcolor: theme.palette.grey.main7,
					width: '100%',
					pt: 'calc( 100% * 4/3 )',
					position: "relative",
					// mt: 10,
					borderRadius: 2
				})}
			>
				<Box
					sx={theme =>({
						position: "absolute",
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						display: "flex",
						flexFlow: "column wrap",
						justifyContent: "space-around",
						alignItems: "center",
						boxSizing: "border-box",
						py: {xs: 4, sm: 10},
						px: {xs: 4, md: 0},
						fill: alpha(theme.palette.secondary.main, .5)
					})}
				>
					<BagIcon width="54" height="60" viewBox="0 0 24 24"/>
					<Button
						component={Link}
						to={`/${routeNames.uploadProduct}`}
						variant="contained"
						color="black"
						sx={{
							textAlign: "center",
							mx: {xs: 0, md: 4}
						}}
					><L term="nav.upload_product" /></Button>
				</Box>
			</Box>
		</ProductGridItem>
	);
}

export default UploadProductButton;
import { MESSAGES } from '../constants';

const INITIAL_STATE = {
	threadList: undefined,
	selectedThread: undefined,
	messages: undefined,
	messageCount: undefined,
	pending: false,
	messageIsSending: false,
	pagination: undefined,
	bargainMessageLimitExceededLayerIsVisible: false,
	unread_message_thread_count: 0
};

const init = (state=INITIAL_STATE, action) => {
	switch(action.type)
	{
		case MESSAGES.SET_THREAD_LIST:
			let newThreadList = action.threadList;
			if ( Array.isArray(state.threadList) && state.threadList.length > 0 ) {
				const threadIds = state.threadList.map( thread => thread.thread_id );
				newThreadList = newThreadList.filter( thread => !threadIds.includes(thread.thread_id) );
				newThreadList = [ ...state.threadList, ...newThreadList ];
			}

			return {
				...state,
				threadList: newThreadList,
				pagination: action.pagination
			};
			
		case MESSAGES.CLEAR_THREAD_LIST:
			return {
				...state,
				threadList: INITIAL_STATE.threadList
			};
		
		case MESSAGES.SET_SELECTED_THREAD:
			return {
				...state,
				// selectedThread: state.threadList.find( thread => thread.thread_id === action.thread_id )
				selectedThread: action.thread
			};
			
		case MESSAGES.CLEAR_SELECTED_THREAD:
			return {
				...state,
				selectedThread: INITIAL_STATE.selectedThread
			};
			
		case MESSAGES.SET_MESSAGES:
			return {
				...state,
				messages: !action.offset ? action.messages : [ ...action.messages, ...state.messages ],
				messageCount: action.messageCount
			};
			
		case MESSAGES.CLEAR_MESSAGES:
			return {
				...state,
				messages: INITIAL_STATE.messages,
				messageCount: INITIAL_STATE.messageCount,
			};

		case MESSAGES.UPDATE_THREAD:
			
			if ( action.product_id )
			{
				return {
					...state,
					threadList: state.threadList.map( thread => ( thread.product?.product_id === action.product_id && !thread.thread_id ) ? action.thread : thread ),
					selectedThread: ( !state.selectedThread.thread_id && state.selectedThread.product && state.selectedThread.product.product_id === action.product_id ) ? action.thread : state.selectedThread
				};
			}
			else
			{
				const updatedThread = { ...action.thread, lastMessage: action.lastMessage };

				return {
					...state,
					threadList: state.threadList.map( thread => thread.thread_id === action.thread_id ? updatedThread : thread ),
					selectedThread: state.selectedThread.thread_id === action.thread_id ? updatedThread : state.selectedThread
				};
			}
			
		case MESSAGES.APPEND_MESSAGE:
			return {
				...state,
				messages: [ ...state.messages, action.message ].slice( state.messages.length >= 10 ? 1 : 0 )
			};
			
		case MESSAGES.SET_REPLY_MESSAGE:
			return {
				...state,
				messages: state.messages.map( message => message.id === action.replied_message_id ? { ...message, reply: action.reply } : message  )
			};

		case MESSAGES.PENDING:
			return {
				...state,
				pending: action.value
			};
			
		case MESSAGES.MESSAGE_IS_SENDING:
			return {
				...state,
				messageIsSending: action.value && state.messages?.length < 10
			};
		case MESSAGES.SET_BARGAIN_MESSAGE_LIMIT_EXCEEDED_LAYER:
			return {
				...state,
				bargainMessageLimitExceededLayerIsVisible: action.value
			};
			
	case MESSAGES.SET_UNREAD_THREAD_COUNT:
			return {
				...state,
				unread_message_thread_count: action.count
			};
			
	case MESSAGES.SET_SELECTED_THREAD_BY_ID:
			return {
				...state,
				selectedThread: state.threadList.find( thread => thread.thread_id === action.thread_id )
			};
	case MESSAGES.SET_OTHER_USER_IS_DISABLED:
			return {
				...state,
				selectedThread: { ...state.selectedThread, other_user_is_disabled: action.other_user_is_disabled }
			};

		default:
			return state;
	}
};

export default init;
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { isDev } from './utils/env';

import App from './components/App';
import TagManager from 'react-gtm-module';

if ( !isDev() ) {
	TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
}

const composeEnhancers = ( isDev() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ) ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
const store = createStore(reducers, composeEnhancers(
	applyMiddleware(thunk)
));

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.querySelector('#root')
);
import { RESPONSE, DASHBOARD } from '../constants';
import API from '../apis/uzuzdApi';
import { APP as URLS, APP as AppUrls } from '../constants/urls';

// import { paginationFromResponse } from '../utils';

// export const getProductList = ({page}) => async dispatch =>
// {
// 	try
// 	{
// 		dispatch(clearProductListAction());
// 		const response = await new API().get( `${URLS.products}`, { params: { page, d: 1 } } );
// 		dispatch({ type: DASHBOARD.SET_PRODUCT_LIST, products: response.data/*, pagination: paginationFromResponse(response.data)*/ });
// 	}
// 	catch(error)
// 	{
// 		dispatch({ type: RESPONSE.SET_ERROR, error });
// 	}
// }

// export const clearProductListAction = () => ({
// 	type: DASHBOARD.CLEAR_PRODUCT_LIST
// })

export const toggleFavoriteProduct = (product_id) => async dispatch =>
{
	try
	{
		dispatch({ type: DASHBOARD.SET_FAVORITE_IS_PENDING, pending: true });
		const response = await new API().post( `${AppUrls.favoriteProduct}`, { product_id } );
		dispatch({ type: DASHBOARD.SET_FAVORITE_IS_PENDING, pending: false });
		dispatch({ type: DASHBOARD.SET_FAVORITE_TO_PRODUCT_LIST_ITEM, product_id, favorite: response.data?.favorite });
	}
	catch(error)
	{
		dispatch({ type: RESPONSE.SET_ERROR, error });
		dispatch({ type: DASHBOARD.SET_FAVORITE_IS_PENDING, pending: false });
	}
}

export const clearContentAction = () => ({
	type: DASHBOARD.CLEAR_CONTENT
})

export const getContent = () => async dispatch =>
{
	try
	{
		dispatch(clearContentAction());
		const response = await new API().get( `${URLS.dashboard}` );
		dispatch({ type: DASHBOARD.SET_CONTENT, content: response.data });
	}
	catch(error)
	{
		dispatch({ type: RESPONSE.SET_ERROR, error });
	}
}
import React from 'react';
import { useSearchParams } from "react-router-dom";

const useFromSearchParams = ({
	filterKey = 'f',
	searchTagKey = 'st'
}={}) =>
{
	const [ searchParams ] = useSearchParams();
	const parse = React.useCallback(str => JSON.parse(unescape(atob(decodeURIComponent(str)))), []);
	const stringify = React.useCallback(data => encodeURIComponent(btoa(escape(JSON.stringify(data)))), []);

	const get = key => searchParams.get( key ) ? parse(searchParams.get( key )) : undefined;

	const filterFromSearchParams = get( filterKey );
	const searchTagFromSearchParams = get( searchTagKey );

	return {
		filterKey,
		searchTagKey,
		filterFromSearchParams,
		searchTagFromSearchParams,
		stringify,
		parse
	};
}

export default useFromSearchParams;
import { SETTINGS } from '../constants';

const initialState = {
	deliveryAddressList: undefined,
	deliveryAddressCannotBeDeleted: undefined,
	requestIsPending: undefined,
	deliveryOptions: undefined,
	bankAccountDetails: undefined,
	bankAccountDetailsCannotBeDeleted: undefined,
	phoneDetails: undefined,
	phoneDetailsCannotBeDeleted: undefined,
	userProductsIsPending: false,
	userProducts: undefined,
	userProductsPagination: undefined,
	productPrices: undefined,
	holiday: undefined,
	optouts: undefined,
};

const settings = (state=initialState, action) => {
		switch(action.type)
		{
				case SETTINGS.SET_DELIVERY_ADDRESSLIST:
					return {
						...state,
						deliveryAddressList: action.list
					};
				case SETTINGS.CLEAR_DELIVERY_ADDRESSLIST:
					return {
						...state,
						deliveryAddressList: undefined
					};
				case SETTINGS.SET_DELIVERY_OPTIONS:
					return {
						...state,
						deliveryOptions: action.options
					};
				case SETTINGS.CLEAR_DELIVERY_OPTIONS:
					return {
						...state,
						deliveryOptions: undefined
					};
				case SETTINGS.CLEANUP:
						return initialState;
				case SETTINGS.CLEAR_USER_PRODUCTS:
					return {
						...state,
						userProducts: undefined,
					};
				case SETTINGS.SET_USER_PRODUCTS_IS_PENDING:
					return {
						...state,
						userProductsIsPending: action.pending,
					};
				case SETTINGS.SET_USER_PRODUCTS:
					return {
						...state,
						userProducts: [ ...( Array.isArray(state.userProducts) ? state.userProducts : [] ), ...action.products ],
						userProductsPagination: action.pagination,
					};
				case SETTINGS.CLEAR_BANK_ACCOUNT_DETAILS:
					return {
						...state,
						bankAccountDetails: undefined
					};
				case SETTINGS.SET_BANK_ACCOUNT_DETAILS:
					return {
						...state,
						bankAccountDetails: action.details
					};
				case SETTINGS.CLEAR_PHONE_DETAILS:
					return {
						...state,
						phoneDetails: undefined
					};
				case SETTINGS.SET_PHONE_DETAILS:
					return {
						...state,
						phoneDetails: action.details
					};
					
				case SETTINGS.SET_HOLIDAY:
					return {
						...state,
						holiday: action.holiday
					};
				case SETTINGS.SET_OPTOUTS:
					return {
						...state,
						optouts: action.optouts
					};
				case SETTINGS.SET_DELIVERY_ADDRESS_CANNOT_BE_DELETED:
					return {
						...state,
						deliveryAddressCannotBeDeleted: true
					};
				case SETTINGS.UNSET_DELIVERY_ADDRESS_CANNOT_BE_DELETED:
					return {
						...state,
						deliveryAddressCannotBeDeleted: undefined
					};
				case SETTINGS.SET_BANK_ACCOUNT_DETAILS_CANNOT_BE_DELETED:
					return {
						...state,
						bankAccountDetailsCannotBeDeleted: true
					};
				case SETTINGS.UNSET_BANK_ACCOUNT_DETAILS_CANNOT_BE_DELETED:
					return {
						...state,
						bankAccountDetailsCannotBeDeleted: undefined
					};
				case SETTINGS.SET_PHONE_DETAILS_CANNOT_BE_DELETED:
					return {
						...state,
						phoneDetailsCannotBeDeleted: true
					};
				case SETTINGS.UNSET_PHONE_DETAILS_CANNOT_BE_DELETED:
					return {
						...state,
						phoneDetailsCannotBeDeleted: undefined
					};
				case SETTINGS.REQUEST_IS_PENDING:
					return {
						...state,
						requestIsPending: action.value
					};
				case SETTINGS.UPDATE_PRODUCT_IN_USER_PRODUCTS:
					return {
						...state,
						userProducts: state.userProducts.map( product => product.product_id === action.product.product_id ? action.product : product )
					};
				case SETTINGS.UPDATE_PRODUCT_AVAILABILITY_IN_USER_PRODUCTS:
					return {
						...state,
						userProducts: state.userProducts?.map( product => product.product_id === action.product_id ? { ...product, recent: action.recent } : product )
					};
				case SETTINGS.SET_PRODUCT_BARGAIN_PRICES:
					return {
						...state,
						productPrices: action.prices.reduce( (m, p) => ({...m, [p.product_id]: p.price}), {})
					};
				default:
					return state;
		}
};

export default settings;
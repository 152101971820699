import { CONFIG } from '../constants';

const config = (state = {}, action) => {
	switch(action.type)
	{
		case CONFIG.SET_CONFIG:
			return {
					...action.config
			};
		default:
			return state;
	}
};

export default config;
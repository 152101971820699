import React from 'react';
import MobileNavigationMenu from './MobileNavigationMenu';
import SettingsMenu from './Pages/Settings/SettingsMenu';

const MobileProfileMenu = ({open,onClose}) => {
	return (
		<MobileNavigationMenu
			open={open}
			onClose={onClose}
			transitionDirection="left"
		>
			<SettingsMenu origin="mobile-profile-menu" handleClose={onClose}/>
		</MobileNavigationMenu>
	);
}

export default MobileProfileMenu;
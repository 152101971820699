import React from 'react';
import {useDispatch} from 'react-redux';
import useStoreState from '../../../hooks/useStoreState';
import { openFilterMenuAction, closeFilterMenuAction } from '../../../actions/appActions';
import {categoryPropFromTree} from '../../../utils/category';
import MobileNavigationMenu from '../../MobileNavigationMenu';
import {Button, Typography, Box, ListItemButton, IconButton/*, Radio, Checkbox, List*/ } from '@mui/material';
import L from '../../Helpers/Lang';
import { ReactComponent as FilterIcon } from '../../../assets/icons/sliders.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/icons/minus.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash.svg';
import PromoFilterDialog from '../Catalog/PromoFilterDialog';
import { SideOptionsBlocks } from '../Search/SearchLayout';

const MobileSearchProductFilter = ({
	category,
	filter,
	order,
	clearFilter,
	setParams,
	// handleFilterChange,
	// handlePriceRangeChange,
	// handleOrderChange,
	toggleSearchOption
}) => {
	const dispatch = useDispatch();
	const {filterMenuOpen} = useStoreState('app');

	const {categoryListById,attributeListById} = useStoreState('catalog');
	const attributes = categoryPropFromTree(categoryListById)('attributes')(category);
	attributes?.sort((a,b) => a.order - b.order);

	const { builtOn } = useStoreState('search');

	const [ promoFilterDialogOpen, setPromoFilterDialogOpen ] = React.useState(false);
	
	const openFilterMenu = () => dispatch(openFilterMenuAction());
	const closeFilterMenu = () => dispatch(closeFilterMenuAction());

	// const [ _filter, _setFilter ] = React.useState(filter);

	// const hasActiveFilter = _filter && Object.keys(_filter).length > 0;
	const hasActiveFilter = filter && Object.keys(filter).length > 0;

	// const _clearFilter = () => {
	// 	_setFilter(undefined)
	// 	// _setOrder(undefined)
	// 	clearFilter?.()
  // }

	return (
		<>
			<Button
				variant="text"
				startIcon={<FilterIcon/>}
				color={hasActiveFilter ? "secondary" : "black"}
				sx={theme=>({
					ml: 'auto',
					textTransform: "none",
					fontWeight: 700,
					"& .MuiButton-startIcon": {
						fill: hasActiveFilter ? theme.palette.secondary.main : theme.palette.black.main
					}
				})}
				onClick={openFilterMenu}
			>
				<L term="nav.filters" />
			</Button>
			{hasActiveFilter && (
					<IconButton
						variant="text"
						color="secondary"
						onClick={clearFilter}
					><DeleteIcon viewBox="0 0 24 24" width='16' height='16' style={{fill: 'currentColor'}} /></IconButton>
			)}
			<MobileNavigationMenu
				open={filterMenuOpen}
				transitionDirection="left"
				dialogSx={{mb: 0}}
				boxSx={{pt: 0}}
			>
				<Box
					sx={{
						display: "flex",
						flexShrink: 0,
						height: 56,
						alignItems: "center",
						px: 4
					}}
				>
					<Typography
						variant="h4"
						sx={{mr: "auto"}}
					><L term="nav.filters" /></Typography>
					<IconButton
						onClick={closeFilterMenu}
						sx={{p: 0, position: 'fixed', top: 8, right: 8}}
					>
						<CloseIcon />
					</IconButton>
				</Box>
				<Box sx={{p: 2}}>
					<SideOptionsBlocks
						{...{
							attributeListById,
							searchOptionsByAttribute: filter,
							builtOn,
							toggleSearchOption
						}}
					/>
				</Box>
				{/* <Box
					sx={theme=>({
						display: "flex",
						position: "fixed",
						width: '100%',
						bottom: 0,
						height: 88,
						px: 4,
						py: 4.5,
						bgcolor: theme.palette.white.main,
						boxShadow: "0 -2px 3px rgba(0,0,0,.1)"
					})}
				>
					<Button
						variant="contained"
						color="black"
						fullWidth
						onClick={setAll}
					><L term="button.set_filter" /></Button>
				</Box> */}
			</MobileNavigationMenu>
			<PromoFilterDialog  { ...{ promoFilterDialogOpen, setPromoFilterDialogOpen } } />
		</>
	);
};

export default MobileSearchProductFilter;

export const MobileProductFilterListItem = ({open,set,label,toggle}) => (
	<ListItemButton
		sx={theme=>({
			height: 56,
			"& .MuiTypography-root": {
				color: set ?  theme.palette.secondary.main: theme.palette.black.main,
			},
			fill: set ?  theme.palette.secondary.main: (open ? theme.palette.black.main : theme.palette.grey.main4),
			border: `1px solid ${theme.palette.grey.main7}`
		})}
		onClick={toggle}
	>
		<Typography
			variant="subtitle"
			sx={theme=>({
				textTransform: "uppercase",
				fontWeight: 500,
				mr: "auto",
			})}
		>
			{label}
		</Typography>
		{open ? <MinusIcon /> : <PlusIcon />}
	</ListItemButton>
);
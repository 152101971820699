import React from 'react';

export const usePager = ({
	get = false,
	clear = false,
	page = 1
}) => {
	const pagerReducer = (state, action) => {
		switch(action.type)
		{
			case 'init':
				const { type, ...others } = action;
				return { ...others };
			case 'prev-page':
				return { get: action.get, clear: false, page: action.page };
			case 'first-page':
				return { get: true, clear: true, page: 1 };
			case 'next-page':
				return { get: true, clear: false, page: state.page + 1 };
			case 'set-page':
				return { get: action.get, clear: action.clear, page: action.page };
			default:
				return state;
		}
	}

	return React.useReducer(pagerReducer, { get, clear, page });
};
import * as React from 'react';
import useStoreState from '../hooks/useStoreState';
import { Menu, IconButton, Avatar } from '@mui/material';
import SettingsMenu from './Pages/Settings/SettingsMenu';

import fallbackAvatar from '../assets/images/UZUZD-black-2row.png';

const UserMenu = (props) =>
{
	const auth = useStoreState('auth');
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		open ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<IconButton
				onClick={handleClick}
				size="small"
				sx={[{ ml: 2 },props.sx]}
			>
				<Avatar
					alt={auth?.user?.name}
					src={auth?.user?.avatar?.xs || fallbackAvatar}
					sx={{ width: 30, height: 30 }}
				/>
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				
				elevation={1}
				PaperProps={{
					elevation: 2,
					sx: {
						// overflow: 'visible'
						// mt: -13
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				// hideBackdrop
				MenuListProps={{
					onMouseLeave: handleClose
				}}
				// BackdropProps={{top: 64}}
				// BackdropProps={{sx:{backgroundColor: "transparent"}}}
			>
				<SettingsMenu
					origin="user-menu"
					handleClose={handleClose}
				/>
			</Menu>
		</React.Fragment>
	);
}

export default UserMenu;
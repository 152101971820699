import { combineReducers } from "redux";
import auth from "./auth";
import init from "./init";
import lang from "./lang";
import app from "./app";
import config from "./config";
import response from "./response";
import settings from "./settings";
import profile from "./profile";
import catalog from "./catalog";
import search from "./search";
import editProduct from "./editProduct";
import product from "./product";
import dashboard from "./dashboard";
import favorites from "./favorites";
import cart from "./cart";
import order from "./order";
import notifications from "./notifications";
import messages from "./messages";
import payment from "./payment";
import statictexts from "./statictexts";

export default combineReducers({
		auth,
		init,
		lang,
		app,
		config,
		response,
		settings,
		profile,
		catalog,
		search,
		editProduct,
		product,
		dashboard,
		favorites,
		cart,
		order,
		notifications,
		messages,
		payment,
		statictexts,
});
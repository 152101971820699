import { combine, bold, blueBg, white, red } from'./colorConsole';
import {isDev} from './env';

class GTag
{
	constructor()
	{
		this.dev = isDev();

		this.dataLayer = window.dataLayer;

		if ( this.dataLayer && typeof this.dataLayer.push === 'function' ) {
			this.valid = true;
		}
	}

	static push (properties)
	{
		this.handler = new GTag();

		const {event} = properties;

		if ( !this.handler.valid ) {
			if ( this.handler.dev ) {
				console.log(`%cinvalid gtag ${event} PUSH`, ...[ [ red ] ].map( styleArray => combine(...styleArray) ) );
			}
			return;
		}

		if ( this.handler.dev ) {
			console.log(`%c${event}`, ...[ [ blueBg, white, bold ] ].map( styleArray => combine(...styleArray) ) );
			console.log(properties);
		}

		try
		{
			this.handler.dataLayer.push(properties);
		} catch(error) {
			console.warn( error );
		}
		if ( this.handler.dev ) {
			console.log(`%csuccess bp ${event} PUSH`, ...[ [ blueBg, white ] ].map( styleArray => combine(...styleArray) ) );
		}
	}
}

export default GTag;
import { NOTIFICATIONS } from '../constants';

const INITIAL_STATE = {
	unread_count: undefined,
	notificationGroups: undefined,
	notifications: undefined,
};

const init = (state=INITIAL_STATE, action) => {
	switch(action.type)
	{
		case NOTIFICATIONS.SET_UNREAD_COUNT:
			return {
				...state,
				unread_count: action.count
			};
			
		case NOTIFICATIONS.SET_NOTIFICATIONS:
			return {
				...state,
				notifications: action.notifications
			};
			
		case NOTIFICATIONS.CLEAR_NOTIFICATIONS:
			return {
				...state,
				notifications: undefined
			};

		case NOTIFICATIONS.SET_NOTIFICATION_GROUPS:
			return {
				...state,
				notificationGroups: action.list
			};
			
		case NOTIFICATIONS.SET_READ_FLAG:
			return {
				...state,
				notificationGroups: state.notificationGroups.map( g => g.id === action.group_id ? { ...g, read: action.read } : g )
			};

		default:
			return state;
	}
};

export default init;
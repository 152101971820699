import React from 'react';
import { Link } from 'react-router-dom';
import {Box,Paper,Grid,IconButton,ListItem,Divider,Typography} from '@mui/material';
import useStoreState from '../../hooks/useStoreState';
// import Typography from '../Helpers/Typography';
import L from '../Helpers/Lang';

import { ReactComponent as ShieldIcon } from '../../assets/icons/shield.svg';
import { ReactComponent as BagIcon } from '../../assets/icons/shopping-bag.svg';
import { ReactComponent as UsersIcon } from '../../assets/icons/users.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
import { ReactComponent as BarionCardLogos } from '../../assets/images/barion-card-strip-intl.svg';
import { routeNames } from '../../constants/uiRouteNames';
import seoBlocks from '../../constants/seoBlocks';

const FooterTopRowItem = ({index,icon,label,linkTo}) => {
	return (
		<Grid
			container
			item
			md={4}
			sx={(theme)=>({
				textDecoration: "none",
				height: "inherit",
				justifyContent: "center",
				alignItems: "center",
				...( index === 1 ? {
					border: `1px solid ${theme.palette.grey.main6}`,
					borderTop: "none",
					borderBottom: "none",
				} : undefined),
				"&:hover": {
					cursor: "pointer",
					// bgcolor: theme.palette.grey.main9
				}
			})}
			component={Link}
			to={linkTo}
		>
			<Box
				sx={(theme) =>({
					width: 64,
					height: 64,
					display: "inline-flex",
					justifyContent: "center",
					alignItems: "center",
					borderRadius: 32,
					mr: 6,
					backgroundColor: theme.palette.success.main4
				})}
			>
				{icon}
			</Box>
			<Typography variant="h4">{label}</Typography>
		</Grid>
	);
}

const Footer = () => {

	const { hello_email_address, customer_service_email_address } = useStoreState('config');

	const typographyLinkProps = {fontWeight: 700, textDecoration: "none", "&:hover": {textDecoration: "underline"}, cursor: "pointer"};

	return (
		<Box component="footer" sx={{display: { xs: "none", md: "block" }}}>
			{/* top row */}
			<Grid
				// element={Paper}
				container
				sx={(theme)=>({
					height: 112,
					alignItems: "stretch",
					bgcolor: theme.palette.white.main,
					borderTop: `1px solid ${theme.palette.grey.main6}`,
				})}
			>
				{
					[
						{
							icon: <ShieldIcon />,
							label: <L term='.title.uzuzd_insurance' />,
							linkTo: routeNames.insurance
						},{
							icon: <BagIcon />,
							label: <L term='.title.uzuzd_description' />,
							linkTo: routeNames.whatis
						},{
							icon: <UsersIcon />,
							label: <L term='.title.uzuzd_thriving' />,
							linkTo: routeNames.grow
						}
					].map((element,index)=>(
						<FooterTopRowItem
							key={index}
							index={index}
							{...element}
						/>))
				}
			</Grid>

			{/* second+social row */}
			<Box
				sx={(theme)=>({
					height: 72,
					display: "flex",
					bgcolor: theme.palette.grey.main8,
					alignItems: "stretch",
					px: 12
				})}
			>{
				[
					<Typography
						component="a"
						target="_blank"
						href={`mailto:${hello_email_address}`}
						variant="subtitle"
						sx={typographyLinkProps}
					>
						<L term=".title.propose" />
					</Typography>,
					<Typography
						component="a"
						target="_blank"
						href={`mailto:${customer_service_email_address}`}
						variant="subtitle"
						sx={typographyLinkProps}
					>
						<L term=".title.support" />
					</Typography>,
					<Typography
						variant="subtitle"
						component={Link}
						to={routeNames.tos}
						sx={typographyLinkProps}
					>
						<L term=".title.tos" />
					</Typography>,
					<Typography
						variant="subtitle"
						component={Link}
						to={routeNames.gdpr}
						sx={typographyLinkProps}
					>
						<L term=".title.gdpr" />
					</Typography>,
					<IconButton
						component="a"
						href={process.env.REACT_APP_FBPAGE_URL}
						target="_blank"
						rel="noreferrer"
					><FacebookIcon/></IconButton>,
					<IconButton
						component="a"
						href={process.env.REACT_APP_INSTA_URL}
						target="_blank"
						rel="noreferrer"
					><InstagramIcon/></IconButton>
				].map((element,index)=>(
					<Box
						key={index}
						sx={{
							display: "inline-flex",
							alignItems: "center",
							px: 4,
							mr: index===3 ? "auto" : undefined,
						}}
					>
						{element}
					</Box>
				))
			}
			</Box>

			{/* seo section */}
			<Paper
				sx={{
					py:10
				}}
			>
				<Grid container sx={{justifyContent: "space-between"}}>
				<Grid item md={1}></Grid>
					{seoBlocks?.map( (block,index) => (
						<Grid key={index} item md={2}>
							{block.items?.map((item,i) => (
								<ListItem key={i} sx={{py: '3px'}}>
									<Typography variant="body1"
										component={Link}
										to={`${routeNames.catalog}/${item.url}`}
										sx={{
											textDecoration: "none",
											whiteSpace: "nowrap",
											"&:first-letter": {
												textTransform: "capitalize"
											},
											"&:hover": {
												textDecoration: "underline"
											}
										}}
									>{`${block.name} ${item.label}`}</Typography>
								</ListItem>
							))}
						</Grid>
					))}
					<Grid item md={1}></Grid>
				</Grid>
			</Paper>
			
			<Box sx={{textAlign: "center", pb: 10}} >
				<BarionCardLogos width="488" height="40" />
			</Box>

			<Divider sx={(theme)=>({bgcolor: theme.palette.grey.main6})} />
			{/* since bottom */}
			<Paper
				sx={{
					height: 46,
					display: "flex",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<Typography variant="body2" color="grey.main2">© {new Date().getFullYear()} UZUZD</Typography>
			</Paper>
		</Box>
	);
}

export default Footer;
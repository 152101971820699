import { Spots } from '../../../constants';

export const categoryLevelItemConfig = {
	[Spots.CategoryLevel.Catalog]: {
		0: { // level
			typography: {
				variant: "menu1",
				sx: {
				
					textTransform: "uppercase"
				}
			}
		},
		1: {
			typography: {
				variant: "subtitle",
				sx: {
					"&:first-letter": {
						textTransform: "capitalize"
					}
				}
			}
		},
		2: {
			typography: {
				variant: "body1",
				sx: {
					"&:first-letter": {
						textTransform: "capitalize"
					}
				}
			}
		},
	},
	[Spots.CategoryLevel.CatalogTabs]: {
		1: {
			typography: {
				variant: "subtitle1",
				sx: {
					"&:first-letter": {
						textTransform: "capitalize"
					}
				}
			}
		},
		2: {
			typography: {
				variant: "body1",
				sx: {
					"&:first-letter": {
						textTransform: "capitalize"
					}
				}
			}
		},
	},
	[Spots.CategoryLevel.MobileCatalog]: {
		0: {
			typography: {
				variant: "subtitle1",
				sx: {
					textTransform: "uppercase"
				}
			},
			menuItem: {
				sx: theme => ({
					height: 56,
					borderBottom: `1px solid ${theme.palette.grey.main7}`
				})
			}
		},
		1: {
			typography: {
				variant: "subtitle1",
				sx: {
					"&:first-letter": {
						textTransform: "capitalize"
					}
				}
			}
		},
		2: {
			typography: {
				variant: "body1",
				sx: {
					"&:first-letter": {
						textTransform: "capitalize"
					}
				}
			}
		},
	}
}
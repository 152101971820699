import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography, Button } from '@mui/material';
import { alpha } from '@mui/material/styles';
import background from '../../../assets/images/dashboard-slider-bg-2309-3.webp';
import { useStepper } from '../../../hooks/stepper';
import { ReactComponent as Chevron } from '../../../assets/icons/chevron-right.svg';
import useWindowSize from '../../../hooks/useWindowSize';
import L from '../../Helpers/Lang';
import { routeNames } from '../../../constants/uiRouteNames';

const texts = [1,2,3,4].map( n => [
	<><L term={`slider.${n}.title`} partial={0} /> <b><L term={`slider.${n}.title`} partial={1} /></b></>,
	<L term={`slider.${n}.desc`} />
]);

const Background = () =>
{
	const pseudoStyles = {
		content: '""',
		display: "flex",
		position: "absolute",
		top: "0",
		width: "100%",
		height: "100%"
	};

	return (
		<Box
			className="Background"
			sx={{
				"&:before": {
				...pseudoStyles,
				background: `url(${background}) center center no-repeat`,
				backgroundSize: "cover"
			},
			"&:after": {
				...pseudoStyles,
				bgcolor: theme => alpha(theme.palette.white.main,.3)
			}
		}}
		></Box>
	);
}

const Slide = ({text}) => {
	return (
		<Box
			sx={{
				flexBasis: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: {xs: "flex-start", sm: "center"},
				py: { xs: 4 }
			}}
		>
			<Typography variant="h1" sx={{
				fontSize: { xs: "2.75rem", sm: "3.75rem" },
				fontWeight: 400,
				mb: {xs: 2, sm: 6 },
				textAlign: "center"
			}}>
				{text[0]}
			</Typography>
			<Typography
				variant="h3"
				sx={{
					fontSize: { xs: "1.2rem", sm: "1.5rem" },
					px: { xs: 4, sm: 20 },
					mb: {xs: 4, sm: 6 },
					textAlign: "center"
				}}
			>
				{text[1]}
			</Typography>
			<Button variant="contained"color="black"
				component={Link}
				to={`/${routeNames.whatis}`}
			><L>.button.uzuzd_details</L></Button>
		</Box>
	);
}

const Dots = props => {
	return (
		<Grid container gap={4} sx={{ position: 'absolute', bottom: 0, justifyContent: "center", mb: 6 }} >
			{[ ...Array(texts.length).keys() ].map(n =>{
				
				// const selected = ( n === props.slide ) || ( props.slide === texts.length && n=== 0 )
				const selected = n === props.slide

				return (
					<Grid
						item
						sx={theme => ({
							width: 10,
							height: 10,
							borderRadius: 5,
							bgcolor: theme => selected ? alpha(theme.palette.black.main,.4) : theme.palette.white.main,
							cursor: "pointer",
							"&:hover": {
								bgcolor: theme => alpha(theme.palette.black.main,.4),
							}
						})}
						key={n}
						onClick={() => props.dotClick(n)}
					></Grid>
				);
			})}
		</Grid>
	);
};

const Pager = ({left, right, ...rest}) => {
	return (
		<Box className="Pager" {...rest}
			sx={theme => ({
				width: "10%",
				height: "100%",
				position: "absolute",
				top: 0,
				[ right ? "right" : "left" ]: 0,
				display: "flex",
				justifyItems: "center",
				alignContent: "center",
				zIndex: 1,
				"&:hover": {
					// bgcolor: theme.palette.success.main3,
					bgcolor: alpha(theme.palette.grey.main8, .1),
					cursor: "pointer"
				}})
			}
		>
			<Box sx={theme=>({
				margin: "auto",
				fill: theme.palette.white.main,
				...(left ? { transform: "scaleX(-1)" } : {})
			})}>
				<Chevron />
			</Box>
		</Box>
	);
};

const DashboardSlider = ({startWith}) =>
{
	// const slidesCount = texts.length + 1;
	const slidesCount = texts.length;
	// const firstSlide = startWith === "first" ? slidesCount - 1 : startWith === "random" ? Math.round(Math.random()*(slidesCount-1)) : 1;
	const firstSlide = 0;
	const switchSlideAfter = 5000;
	const transitionDurationMs = 500;

	// const [switchSlideAfter, setSwitchSlideAfter] = React.useState(1000);
	const { width : windowWidth } = useWindowSize();

	const [slide, setSlide] = useStepper(slidesCount,firstSlide,switchSlideAfter);

	return (
		<Box sx={{ display: "block", height: { xs: 260, sm: 312, md: 292 }, position: "relative" }} >
			<Background />
			<Box className="Mask" sx={{ position: "relative", width: "100%", height: "100%", overflow: "hidden" }} >
				<Pager left xs={1} md={1} onClick={()=>setSlide(Math.max(0, slide - 1))} />
				<Box className="Container"
					sx={{
						// width: `${(texts.length+1) * windowWidth}px`,
						width: `${(texts.length) * windowWidth}px`,
						height: "100%",
						display: "flex",
						// transition: slide === 0 ? "none" : `transform ${transitionDurationMs}ms ease-out`,
						transition: `transform ${transitionDurationMs}ms ease-out`,
						transform: `translateX(-${slide*windowWidth}px)`
					}}
				>
					{texts.map((text,i) => (
						<Slide text={text} key={i} />
					))}
					{/* <Slide text={texts[0]}/> */}
				</Box>
				<Pager right xs={1} md={1} onClick={()=>setSlide(Math.min(slidesCount-1, slide + 1))} />
				{/* <Dots slide={slide} dotClick={n=>n===0 ? setSlide(slidesCount-1) : setSlide(n)} /> */}
				<Dots slide={slide} dotClick={setSlide} />
			</Box>
		</Box>
	);
};

export default DashboardSlider;
import React from 'react';
import {Slide} from '@mui/material';
import {Dialog, Box} from '@mui/material';

const SlideLeft = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const SlideRight = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const MobileNavigationMenu = ({children, transitionDirection, dialogSx, boxSx, ...all}) => {
	return (
		<Dialog
			fullScreen
			// sx={{ zIndex: 1, top: window.scrollY > 0 ? 0 : '64px', }}
			sx={[{ zIndex: 1, top: 0, mb: 20 }, dialogSx]}
			BackdropProps={{ sx: { bgcolor: "transparent" } }}
			TransitionComponent={ transitionDirection === "right" ? SlideRight : SlideLeft  }
			{...all}
		>
			<Box sx={[{pt: 16}, boxSx]}>
				{children}
			</Box>
		</Dialog>
	);
}

export default MobileNavigationMenu;
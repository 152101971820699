import React from 'react';
import {useDispatch} from 'react-redux';
import { showLoginFormAction } from '../../actions/appActions';
import {usePost} from '../../hooks/useApi';
import {APP as URLS} from '../../constants/urls';
import { Button } from '@mui/material';

import TypLink from '../Helpers/TypLink';
import L from '../Helpers/Lang';
import UTypography from '../Helpers/Typography';
import AuthForm from './AuthForm';
import AuthLayer from './AuthLayer';
import ResponseLayer from '../Helpers/ResponseLayer';

const ForgotPassword = (props) =>
{
	const dispatch = useDispatch();
	const [forgotPassword, {isDone, isSuccess, response, error}] = usePost(URLS.forgotPassword);
	const [pending,setPending] = React.useState(false);
	
	React.useEffect(()=>{
		setPending(false);
	},[response]);

	if ( isDone )
	{
		const responseLayerProps = {
			title: isSuccess ? response.message : error?.errors?.email,
			[ isSuccess ? "success" : "error" ]: true,
			button: (
				<Button
					variant="contained"
					color={isSuccess?'success':'error'}
					onClick={props.onModalDismiss}
					sx={{mt: 6}}
				>
					{isSuccess?<L term="button.okay"/>:<L term="button.igot_it"/>}
				</Button>
			)
		};

		return (
			<ResponseLayer
				onDismiss={props.onModalDismiss}
				{ ...responseLayerProps }
			/>
		);
	}

	return (
		<AuthLayer
			onModalDismiss={props.onModalDismiss}
		>
			<UTypography
				variant="h4"
				align="center"
				sx={{mb: 7}}
			>
				.title.forgot_password
			</UTypography>

			<AuthForm
				submitDisabled={pending}
				fields={['email']}
				submitLabel="button.next"
				onSubmit={({email}) => {
					setPending(true);
					forgotPassword({email});
				}}
				validationRules={{
					email: 'required|email'
				}}
			/>

			<TypLink
				variant="body1"
				align="center"
				component="div"
				sx={{mt:6}}

				term=".subtitle.back_to_login"
				onClick={() => dispatch(showLoginFormAction())}
			/>
		</AuthLayer>
	);
}

export default ForgotPassword;
import React from 'react';
import useStoreState from '../../../hooks/useStoreState';
import { alpha } from '@mui/material/styles';
import {Box, Tabs, Tab, Menu, ClickAwayListener} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { topLevelCategories } from '../../../utils';
import { useCatalogRouteMatch } from '../../../hooks/useCatalogRouteMatch';
import CategoryLevel from './CategoryLevel';
import SearchSizeAttributes from '../Search/SearchSizeAttributes';
import {CategoryContext} from '../../../contexts/CategoryContext';
import {SearchSizeContext} from '../../../contexts/SearchSizeContext';
import { Spots } from '../../../constants';
// import { ReactComponent as Ruler } from '../../../assets/icons/mdi/straighten.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
// import L from '../../Helpers/Lang';
import { Link } from 'react-router-dom';
import { routeNames } from '../../../constants/uiRouteNames';

const CatalogTabs = () =>
{
	const {categoryListById} = useStoreState('catalog');
	const theme = useTheme();
	const categories = React.useMemo( () => categoryListById ? topLevelCategories(categoryListById) : [], [categoryListById] );

	const { categoryIdChain } = useCatalogRouteMatch();

	const selectedTopLevelCategory = categoryIdChain?.[0] || false;

	const [ tab, setTab ] = React.useState( selectedTopLevelCategory );

	React.useEffect(() => {
		setTab(selectedTopLevelCategory||false)
	}, [selectedTopLevelCategory]);

	const selectedTopCategory = categoryListById[ tab ];

	const handleTabChange = (ev, newTab) => {
		setTab(newTab);
	};

	const tabMenuInitialState = {
		catalogTabMenuOpen: false,
		catalogTabMenuAnchorEl: null,
		searchSizeTabMenuOpen: false,
		searchSizeTabMenuAnchorEl: null
	};

	const tabMenuReducer = (state, action) => {
		switch(action.type) {
			case 'openCatalogTabMenu':
				return { ...state,
					catalogTabMenuOpen: true,
					searchSizeTabMenuOpen: tabMenuInitialState.searchSizeTabMenuOpen,
					catalogTabMenuAnchorEl: action.anchorEl,
					searchSizeTabMenuAnchorEl: tabMenuInitialState.searchSizeTabMenuAnchorEl
				};
			case 'closeCatalogTabMenu':
				return { ...state, catalogTabMenuOpen: false, catalogTabMenuAnchorEl: tabMenuInitialState.catalogTabMenuAnchorEl };
			case 'openSearchSizeTabMenu':
				return { ...state,
					searchSizeTabMenuOpen: true,
					catalogTabMenuOpen: tabMenuInitialState.catalogTabMenuOpen,
					searchSizeTabMenuAnchorEl: action.anchorEl,
					catalogTabMenuAnchorEl: tabMenuInitialState.catalogTabMenuAnchorEl
				};
			case 'closeSearchSizeTabMenu':
				return { ...state, searchSizeTabMenuOpen: false, searchSizeTabMenuAnchorEl: tabMenuInitialState.searchSizeTabMenuAnchorEl };
			default:
				return state;
		}
	};

	const [ tabMenu, dispatchTabMenu ] = React.useReducer(tabMenuReducer,tabMenuInitialState);

  const handleOpenCatalogTabMenu = (event) => {
		event.stopPropagation();
		dispatchTabMenu({type: 'openCatalogTabMenu', anchorEl: event.currentTarget.parentElement});
  };
  const handleCloseCatalogTabMenu = () => {
		dispatchTabMenu({type: 'closeCatalogTabMenu'});
  };

	// const handleOpenSeachSizeTabMenu = (event) => {
	// 	event.stopPropagation();
	// 	dispatchTabMenu({type: 'openSearchSizeTabMenu', anchorEl: event.currentTarget.parentElement});
  // };
  const handleCloseSearchSizeTabMenu = () => {
		dispatchTabMenu({type: 'closeSearchSizeTabMenu'});
  };

	return (
		<>
			<Tabs
				value={tab}
				onChange={handleTabChange}
				textColor="inherit"
				variant="scrollable"
				scrollButtons
				allowScrollButtonsMobile
				TabIndicatorProps={{
					style: {
						backgroundColor: theme.palette.black.main,
						height: 4
					}
				}}
				sx={{
					minHeight: 26,
					"& .MuiTab-root": {
						minHeight: 26,
						letterSpacing: 0,
						fontSize: '1rem',
						color: "black",
						p: 0,
						mx: 2,
						opacity: 1,
						minWidth: "auto"
					},
					"& .MuiTab-root:last-child": {
						ml: 6
					}
				}}
			>
				{categories.map( category => {
					return (
						<Tab
							key={category.category_id}
							label={category.category_name}
							value={category.category_id}
							disableRipple
							onClick={handleOpenCatalogTabMenu}
						/>
					);
				})}
				<Tab
					key="search"
					label={
						<Link to={routeNames.root + routeNames.search}>
							<SearchIcon />
						</Link>
					}
					value="search"
					disableRipple
					sx={theme => ({color: theme.palette.grey.main2})}
				/>
				{/* <Tab
					key="search-size"
					label={<Box sx={theme=>({display: "flex", alignItems: "center", fill: theme.palette.grey.main2})}><Ruler style={{marginRight: 4}}/><L term="label.sizes" /></Box>}
					value="search-size"
					disableRipple
					sx={theme => ({color: theme.palette.grey.main2})}
					onClick={handleOpenSeachSizeTabMenu}
				/> */}
				</Tabs>
			{tabMenu.catalogTabMenuOpen && <CatalogTabMenu {...{
				anchorEl: tabMenu.catalogTabMenuAnchorEl,
				open: tabMenu.catalogTabMenuOpen,
				handleCloseCatalogTabMenu,
				selectedTopCategory,
				categoryIdChain
			}}/>}
			{tabMenu.searchSizeTabMenuOpen && <SearchSizeTabMenu {...{
				anchorEl: tabMenu.searchSizeTabMenuAnchorEl,
				open: tabMenu.searchSizeTabMenuOpen,
				handleCloseSearchSizeTabMenu
			}}/>}
		</>
	);
}

export default CatalogTabs

const CatalogTabMenu = ({
	anchorEl,
	open,
	handleCloseCatalogTabMenu,
	selectedTopCategory,
	categoryIdChain
}) => {
	return (
		<ClickAwayListener onClickAway={handleCloseCatalogTabMenu} >
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseCatalogTabMenu}
				transformOrigin={{ horizontal: 'left', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
				sx={theme => ({
					top: 64,
					display: "flex",
					width: '100%'
				})}
				BackdropProps={{
					sx: theme => ({
						top: 64,
						bgcolor: alpha(theme.palette.grey.main2,.9)
					})
				}}
				PaperProps={{
					sx: theme => ({
						top: "0 !important",
						// transform: "translateY(-64px) !important"
						width: theme.breakpoints.values.sm
					})
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexFlow: "row nowrap",
						px: 4
					}}
				>
					<CategoryContext.Provider
						value={{
							spot: Spots.CategoryLevel.CatalogTabs,
							// selectedCategory,
							// setSelectedCategory
						}}
					>
						<CategoryLevel
							level={1}
							spot={Spots.CategoryLevel.CatalogTabs}
							closeMenu={handleCloseCatalogTabMenu}
							parent={selectedTopCategory}
							selectedCategoryIdChain={categoryIdChain.splice(1)}
						/>
					</CategoryContext.Provider>
				</Box>
			</Menu>
		</ClickAwayListener>
	);
}

const SearchSizeTabMenu = ({
	anchorEl,
	open,
	handleCloseSearchSizeTabMenu,
	searchSizes
}) => {
	return (
		<ClickAwayListener onClickAway={handleCloseSearchSizeTabMenu} >
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseSearchSizeTabMenu}
				transformOrigin={{ horizontal: 'left', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
				sx={theme => ({
					top: 64,
					display: "flex",
					// width: '100%'
				})}
				BackdropProps={{
					sx: theme => ({
						top: 64,
						bgcolor: alpha(theme.palette.grey.main2,.9)
					})
				}}
				PaperProps={{
					sx: theme => ({
						top: "0 !important",
						// transform: "translateY(-64px) !important"
						width: theme.breakpoints.values.sm,
					})
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexFlow: "row nowrap",
						px: 4
					}}
				>
					<SearchSizeContext.Provider
						value={{
							spot: Spots.SearchSizeLevel.CatalogTabs,
						}}
					>
						<SearchSizeAttributes
							level={0}
							closeMenu={handleCloseSearchSizeTabMenu}
						/>
					</SearchSizeContext.Provider>
				</Box>
			</Menu>
		</ClickAwayListener>
	);
}
import { ORDER } from '../constants';

const INITIAL_STATE = {
	userOrders: undefined,
	pagination: undefined,
	userOrder: undefined,
};

const init = (state=INITIAL_STATE, action) => {
	switch(action.type)
	{
		case ORDER.SET_USER_ORDERS:
			return {
				...state,
				userOrders: action.orders,
				pagination: action.pagination
			};
		case ORDER.CLEAR_USER_ORDERS:
			return {
				...state,
				userOrders: undefined,
				pagination: undefined
			};
			
		case ORDER.SET_USER_ORDER:
			return {
				...state,
				userOrder: action.order
			};
		case ORDER.CLEAR_USER_ORDER:
			return {
				...state,
				userOrder: undefined
			};

		default:
			return state;
	}
};

export default init;
import React from 'react';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {initialize} from '../actions/initActions';
import useStoreState from '../hooks/useStoreState';
import theme from '../theme/theme';
import {ThemeProvider} from '@mui/material/styles';
import Router from './AppRouter';

import CssBaseline from "@mui/material/CssBaseline";
import FullPageLoader from './Helpers/FullPageLoader';
import { useAutoLogout } from '../hooks/useAutoLogout';
// import GTag from '../utils/GTag';

const App = ({init}) =>
{
	const dispatch = useDispatch()
	const { done } = useStoreState('init');
	useAutoLogout();

	useEffect(()=>{
		dispatch(initialize());
	},[dispatch]);

	// with locationchange !!
	// GTag.push({event: 'pageView'});

	return (
		<ThemeProvider theme={theme}>
			{!done && <FullPageLoader />}
			{done && (
				<>
					<CssBaseline />
					<Router />
				</>
			)}
		</ThemeProvider>
	);
}

export default App;
import React from 'react';
import {useDispatch} from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import useStoreState from '../hooks/useStoreState';

import { Paper, BottomNavigation, BottomNavigationAction, Badge } from '@mui/material';
import { ReactComponent as HomeIcon } from '../assets/icons/home.svg';
import { ReactComponent as MessageIcon } from '../assets/icons/message.svg';
import { ReactComponent as SaleIcon } from '../assets/icons/plus-square.svg';
import { ReactComponent as UserIcon } from '../assets/icons/user.svg';
import { ReactComponent as LoginIcon } from '../assets/icons/log-in.svg';
import { ReactComponent as GiftIcon } from '../assets/icons/mdi/gift-outline.svg';

import { routeNames } from '../constants/uiRouteNames';
import { showLoginFormAction, hideLoginFormAction, openProfileMenuAction, closeProfileMenuAction, openCatalogMenuAction, closeCatalogMenuAction } from '../actions/appActions';
import L from './Helpers/Lang';

import MobileProfileMenu from './MobileProfileMenu';
import MobileCatalogMenu from './MobileCatalogMenu';

const MobileNavigation = () =>
{
	const auth = useStoreState('auth');
	const config = useStoreState('config');
	const theme = useTheme();
	const {profileMenuOpen,catalogMenuOpen,filterMenuOpen} = useStoreState('app');
	const { unread_message_thread_count } = useStoreState('messages');

	const location = useLocation();
	const dispatch = useDispatch();

	const openProfileMenu = () => {
		dispatch(hideLoginFormAction());
		dispatch(openProfileMenuAction());
	}
	
	const closeProfileMenu = () => {
		dispatch(closeProfileMenuAction());
	}
	
	const toggleProfileMenu = () => {
		profileMenuOpen ? closeProfileMenu() : openProfileMenu();
	}

	const openCatalogMenu = () => {
		dispatch(hideLoginFormAction());
		dispatch(openCatalogMenuAction());
	}
	
	const closeCatalogMenu = () => {
		dispatch(closeCatalogMenuAction());
	}
	
	const toggleCatalogMenu = () => {
		catalogMenuOpen ? closeCatalogMenu() : openCatalogMenu();
	}
	
	const closeMenus = () => {
		closeProfileMenu()
		closeCatalogMenu()
	}

	const menuItems = [
		{
			label: "nav.catalog",
			icon: <HomeIcon />,
			onClick: toggleCatalogMenu
		},
		// {
		// 	label: "nav.notifications",
		// 	icon: <MessageIcon />,
		// 	to: routeNames.notifications,
		// 	onClick: closeMenus
		// },
		{
			label: "nav.my_orders",
			icon: <GiftIcon style={{color: theme.palette.black.main}}/>,
			to: routeNames.settingsOrders,
			onClick: closeMenus
		},
		{
			label: "nav.iupload_product",
			icon: <SaleIcon />,
			to: auth.isSignedIn ? ( config.is_valid_seller ? routeNames.uploadProduct : `${routeNames.fillSellerInfo}` ) : null,
			onClick: ev => {
				if (!auth.isSignedIn) { dispatch(showLoginFormAction()); closeMenus(); }
			}
		},
		{
			label: "nav.messages",
			icon: <Badge badgeContent={ unread_message_thread_count } color="error"><MessageIcon /></Badge>,
			to: routeNames.messages,
			onClick: closeMenus
		},
		{
			...(
				auth.isSignedIn
				? {
					label: "nav.ownprofile",
					icon: <UserIcon />,
					// to: routeNames.profile,
					onClick: toggleProfileMenu
				}
				: {
					label: "nav.login",
					icon: <LoginIcon />,
					// to: routeNames.profile,
					onClick: () => {
						dispatch(showLoginFormAction())
						closeMenus()
					}
				}
			)
		},
	];

	const activeValue = ( profileMenuOpen || catalogMenuOpen )
		? (
				profileMenuOpen
				? menuItems.length - 1
				: 0
			)
		: menuItems.findIndex( item => '/' + item.to === location.pathname );

	return (
		<>
			<Paper
				sx={{ position: 'fixed', zIndex: filterMenuOpen ? 0 : 2, bottom: 0, left: 0, right: 0, display: { xs: "block", md: "none" } }}
				elevation={3}
				component="nav"
			>
				<BottomNavigation
					showLabels
					value={activeValue}
				>
					{
						menuItems.map((menuItem,i)=>{
							return (
								<BottomNavigationAction
									key={i}
									label={<L term={menuItem.label}/>}
									icon={menuItem.icon}
									component={menuItem.to ? Link : undefined}
									to={menuItem.to}
									onClick={menuItem.onClick}
								/>
							);
						})
					}
				</BottomNavigation>
			</Paper>
			<MobileProfileMenu open={profileMenuOpen} onClose={closeProfileMenu} />
			<MobileCatalogMenu open={catalogMenuOpen} onClose={closeCatalogMenu} />
		</>
	);
};

export default MobileNavigation;
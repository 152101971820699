import React from 'react';
import {TextField} from '@mui/material';
import L from '../../Helpers/Lang';

const PriceTextField = ({name, ...rest}) => (
	<TextField
		variant="standard"
		type="number"
		fullWidth
		sx={{
			mb: 4
		}}
		label={<L term={`label.${name}`} />}
		{...rest}
	/>
)

export default PriceTextField;
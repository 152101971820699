import React from 'react';
import { Form, Field } from 'react-final-form';
import { TextField, Button, FormControlLabel, Checkbox, FormHelperText, FormControl, Box, Typography } from '@mui/material';
// import TypLink from '../Helpers/TypLink';
import L from '../Helpers/Lang';
import { Validator } from '../../utils/validator';
import useValidationDict from '../../hooks/useValidationDict';
import useStoreState from '../../hooks/useStoreState';
import { Link } from 'react-router-dom';
import { routeNames } from '../../constants/uiRouteNames';

const AuthForm = props =>
{
	const validationDict = useValidationDict();
	const {invite_voucher_enabled} = useStoreState('config');
	const validate = new Validator(validationDict).make(props.validationRules);

	const serverSideErrorMessages = props.error?.errors;
	const hasError = (field, meta) => {
		return !!( ( serverSideErrorMessages?.[ field ] ) || ( meta.touched && meta.error ));
	};
	const helperText = (field, meta) => {
		return ( serverSideErrorMessages?.[field] ) || ( (meta.touched&&meta.error) ? meta.error : " " );
	};

	return (
		<Form
			onSubmit={props.onSubmit}
			validate={validate}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>

					{props.fields.includes('name') &&
						<Field
							name="name"
							render={({ input, meta }) => (
								<TextField
									type="text"
									label={<L>auth.label.username</L>}
									error={hasError('name', meta)}
									helperText={helperText('name', meta)}
									fullWidth
									sx={{mb: 2}}
									{...input}
								/>
							)}
						/>
					}

					{props.fields.includes('email') &&
						<Field
							name="email"
							defaultValue={props.defaultValues?.email}
							render={({ input, meta }) => (
								<TextField
									type="text"
									label={<L>auth.label.email</L>}
									error={hasError('email', meta)}
									helperText={helperText('email', meta)}
									fullWidth
									sx={{mb: 2}}
									{...input}
								/>
							)}
						/>
					}

					{props.fields.includes('password') &&
						<Field
							name="password"
							render={({ input, meta }) => (
								<TextField
									type="password"
									label={<L>auth.label.password</L>}
									autoComplete="current-password"
									error={hasError('password', meta)}
									helperText={helperText('password', meta)}
									fullWidth
									sx={{mb: 2}}
									{...input}
								/>
							)}
						/>}

						{props.fields.includes('password_confirmation') &&
							<Field
								name="password_confirmation"
								render={({ input, meta }) => (
									<TextField
										type="password"
										label={<L>auth.label.password_confirmation</L>}
										autoComplete="current-password"
										error={hasError('password_confirmation', meta)}
										helperText={helperText('password_confirmation', meta)}
										fullWidth
										sx={{mb: 2}}
										{...input}
									/>
								)}
							/>
						}

						{props.fields.includes('code') && invite_voucher_enabled &&
							<Field
								name="code"
								defaultValue={props.defaultValues?.code}
								render={({ input, meta }) => (
									<TextField
										type="text"
										label={<L>label.voucher_code</L>}
										error={hasError('code', meta)}
										helperText={helperText('code', meta)}
										fullWidth
										sx={{mb: 2}}
										{...input}
									/>
								)}
							/>
						}

						{props.fields.includes('remember') &&
							<Field
								name="remember"
								label={<L>auth.label.remember</L>}
								render={({ input: { onChange, value }, label, ...rest }) => (
									<FormControlLabel
										control={<Checkbox
											label={label}
											checked={!!value}
											onChange={(event, isInputChecked) => onChange(isInputChecked)}
											{...rest}
										/>}
										label={label}
										sx={{mb: 6}}
									/>
								)}
							/>
						}

						{props.fields.includes('approve') &&
							<Field
								name="approve"

								render={({ input: { onChange, value }, label, meta, ...rest }) => (
									<FormControl
										required
										error={meta.error&&meta.touched}
										sx={{mb: 6}}
									>
										{/* <FormControlLabel
											control={<Checkbox
												checked={!!value}
												// onChange={(event, isInputChecked) => onChange(isInputChecked)}
												{...rest}
												sx={theme=>({color: meta.error && meta.touched ? theme.palette.error.main : undefined})}
											/>}
											label={<TypLink
												color={meta.error && meta.touched ? "error" : undefined}
												variant="body1"
												component="div"
												// color="error"
												term=".subtitle.approve_tos"
											/>}
										/> */}
										<Box
											sx={{
												display: "flex",
												alignItems: "center"
											}}
										>
											<Checkbox
												checked={!!value}
												onChange={(event, isInputChecked) => onChange(isInputChecked)}
												{...rest}
												sx={theme=>({color: meta.error && meta.touched ? theme.palette.error.main : undefined})}
											/>
											<Box>
												<Typography variant="body1" sx={{display: "inline"}}><L term=".subtitle.approve_tos" partial={0}/></Typography>
												<Typography variant="body1"
													sx={{
														display: "inline",
														fontWeight: 700,
														textDecoration: "none",
														"&:hover": {
															textDecoration: "underline",
														}
													}}
													component={Link}
													to={routeNames.tos}
													target="_blank"
												><L term=".subtitle.approve_tos" partial={1}/></Typography>
												<Typography variant="body1" sx={{display: "inline"}}><L term=".subtitle.approve_tos" partial={2}/></Typography>
												<Typography variant="body1"
													sx={{
														display: "inline",
														fontWeight: 700,
														textDecoration: "none",
														"&:hover": {
															textDecoration: "underline",
														}
													}}
													component={Link}
													to={routeNames.gdpr}
													target="_blank"
												><L term=".subtitle.approve_tos" partial={3}/></Typography>
											</Box>
										</Box>

										{(meta.error&&meta.touched)&&<FormHelperText sx={{ml:'14px', color: "error.main"}}><L term="label.please_approve_tos" /></FormHelperText>}
									</FormControl>
								)}
							/>
						}

					<Button
						disabled={props.submitDisabled}
						variant="contained"
						type="submit"
						fullWidth
						sx={{height: 48}}
					>
						<L>{props.submitLabel}</L>
					</Button>

				</form>
			)}
		/>
	);
}

export default AuthForm;
import React from 'react';
import {useDispatch} from 'react-redux';
import {usePost} from '../../hooks/useApi';
import {AUTH as URLS} from '../../constants/urls';
// import {AUTH} from '../../constants';
import {showLoginFormAction} from '../../actions/appActions';
import { initialize } from '../../actions/initActions';
import L from '../Helpers/Lang';

import { Button } from '@mui/material';
import UTypography from '../Helpers/Typography';
import AuthForm from './AuthForm';
import AuthLayer from './AuthLayer';
import TypLink from '../Helpers/TypLink';
import ResponseLayer from '../Helpers/ResponseLayer';

import fbq from '../../utils/FacebookPixel';
import GTag from '../../utils/GTag';
// import useStoreState from '../../hooks/useStoreState';

const SignUp = props =>
{
	const dispatch = useDispatch();
	const [ signUp, { isDone, isSuccess, responseData, error } ] = usePost(URLS.register);
	const [pending, setPending] = React.useState(false);
	
	React.useEffect(()=>{
		setPending(false);
	},[responseData,isDone,isSuccess]);

	if (isDone && isSuccess)
	{
		const responseLayerProps = {
			success: true,
			title: <L term="title.sign_up_success"/>,
			message: <L term="text.sign_up_success"/>,
			button: (
				<Button
					variant="contained"
					color="primary"
					onClick={()=>{ dispatch(initialize()); props.onModalDismiss();}}
					sx={{mt: 6}}
				>
					<L term="button.sign_up_success"/>
				</Button>
			)
		};

		if ( pending ) {
			// Facebook Pixel <<<
			fbq.track('CompleteRegistration');
			// >>> Facebook Pixel
			GTag.push({event: 'sign-up'});
		}

		return (
			<ResponseLayer
				onDismiss={()=>{ dispatch(initialize()); props.onModalDismiss();}}
				{ ...responseLayerProps }
			/>
		);
	}

	return (
		<AuthLayer
			onModalDismiss={props.onModalDismiss}
		>
			<UTypography
				variant="h4"
				align="center"
				sx={{mb: 7}}
			>
				.title.sign_up
			</UTypography>

			<AuthForm
				submitDisabled={pending}
				fields={['name','email','password','password_confirmation','code','approve']}
				submitLabel="nav.sign_up"
				onSubmit={({ name, email, password, password_confirmation, code }) => {
					setPending(true);
					signUp({name, email, password, password_confirmation, code});
				}}
				error={error}
				validationRules={{
					name: 'required|min.string:2',
					email: 'required|email',
					password: 'required|min.string:8|confirmed',
					password_confirmation: 'required|min.string:8',
					approve: 'required',
					code: `regex:^([A-Za-z0-9]{8,11})$`
				}}
			/>

			<TypLink
				variant="body1"
				align="center"
				component="div"
				sx={{mt:6}}
				term=".subtitle.already_have_profile"
				onClick={()=>dispatch(showLoginFormAction())}
			/>
		</AuthLayer>
	);
}

export default SignUp;
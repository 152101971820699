import { APP, RESPONSE } from '../constants';
import API from '../apis/uzuzdApi';
import { APP as URLS } from '../constants/urls';

export const showLoginFormAction = () => dispatch => {
	dispatch({
		type: APP.SHOW_LOGIN_FORM
	});
}

export const hideLoginFormAction = () => ({
	type: APP.HIDE_LOGIN_FORM
});

export const showSignUpFormAction = () => ({
	type: APP.SHOW_SIGNUP_FORM
});

export const hideSignUpFormAction= () => ({
	type: APP.HIDE_SIGNUP_FORM
});

export const showForgotPasswordFormAction = () => ({
	type: APP.SHOW_FORGOT_PASSWORD_FORM
});

export const hideForgotPasswordFormAction = () => ({
	type: APP.HIDE_FORGOT_PASSWORD_FORM
});

export const showResetPasswordFormAction = () => ({
	type: APP.SHOW_RESET_PASSWORD_FORM
});

export const hideResetPasswordFormAction = () => ({
	type: APP.HIDE_RESET_PASSWORD_FORM
});

export const showEmailVerificationAction = () => ({
	type: APP.SHOW_EMAIL_VERIFICATION
});

export const hideEmailVerificationAction = () => ({
	type: APP.HIDE_EMAIL_VERIFICATION
});

export const openProfileMenuAction = () => ({
	type: APP.OPEN_PROFILE_MENU
});

export const closeProfileMenuAction = () => ({
	type: APP.CLOSE_PROFILE_MENU
});

export const openCatalogMenuAction = () => ({
	type: APP.OPEN_CATALOG_MENU
});

export const closeCatalogMenuAction = () => ({
	type: APP.CLOSE_CATALOG_MENU
});

export const openFilterMenuAction = () => ({
	type: APP.OPEN_FILTER_MENU
});

export const closeFilterMenuAction = () => ({
	type: APP.CLOSE_FILTER_MENU
});

export const optOut = (t) => async dispatch =>
{
	try
	{
		const response = await new API().post( `${URLS.optOut}`, { t } );
		dispatch({ type: RESPONSE.SET_SUCCESS, response });
	}
	catch(error)
	{
		dispatch({ type: RESPONSE.SET_ERROR, error });
	}
};
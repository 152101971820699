import React from 'react';
import {Box, MenuItem, Typography} from "@mui/material";
import { Link } from 'react-router-dom';
import useStoreState from '../../../hooks/useStoreState';
import { Spots } from '../../../constants';
import { SearchSizeContext } from '../../../contexts/SearchSizeContext';
import L from '../../Helpers/Lang';
import { routeNames } from '../../../constants/uiRouteNames';
import { useSearchSizeRouteMatch } from '../../../hooks/useSearchSizeRouteMatch';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/icons/minus.svg';
import { searchSizeAttributeLevelItemConfig } from './searchSizeAttributeLevelItemConfig';

const SearchSizeAttributes = ({
	closeMenu,
	level = 0
}) =>
{
	level = 0;
	const {
		attribute: matchedAttribute,
		option: matchedAttributeOption
	} = useSearchSizeRouteMatch();

	const {searchSizeAttributes} = useStoreState('search');
	const searchSizeAttributesByName = searchSizeAttributes?.reduce((byName,attribute) => ({ ...byName, [ attribute.name ]: attribute }),{});
	const [selectedAttribute, setSelectedAttribute] = React.useState(matchedAttribute?.name);

	const searchSizeAttributeOptions = selectedAttribute ? searchSizeAttributesByName[ selectedAttribute ].options : undefined;

	React.useEffect(() => {
		setSelectedAttribute(matchedAttribute?.name)
	},[matchedAttribute?.name]);

	return (
		<SearchSizeContext.Consumer>
			{({spot}) => (
			<>
				<Box
					sx={{
						display: "flex",
						flexFlow: "column wrap",
						pl: [ Spots.SearchSizeLevel.SearchSize, Spots.SearchSizeLevel.MobileSearchSize ].includes( spot ) ? level * 4 : 0
					}}
				>
					{searchSizeAttributes.map((attribute,index) => {
						const selected = selectedAttribute === attribute.name;

						return (
							<React.Fragment key={index}>
								<MenuItem
									key={attribute.name}
									onClick={() => {
										if ( !selected ) {
											setSelectedAttribute(attribute.name);
										}
										else if ( [Spots.SearchSizeLevel.MobileSearchSize].includes(spot) ) {
											setSelectedAttribute(undefined);
										}
									}}
									sx={theme=>({
										fill: theme.palette.grey.main2,
										// display: spot === Spots.SearchSizeLevel.MobileSearchSize && level === 0 ? "none" : "flex",
										display: "flex",
									})}
								>
									<Typography
										variant={searchSizeAttributeLevelItemConfig[spot]?.[level]?.typography.variant}
										sx={{
											fontWeight: selected ? 700 : 400,
											mr: "auto",
											...searchSizeAttributeLevelItemConfig[spot]?.[level]?.typography.sx
										}}
									>
										<L term={attribute.label} />
									</Typography>
									<Box
										sx={theme=>({
											display: "flex",
											fill: selected ? theme.palette.black.main : theme.palette.grey.main4
										})}
									>
										{ ( spot === Spots.CategoryLevel.MobileCatalog ) && (
											<>
												{ selected ? <MinusIcon width="20" height="20" viewBox="0 0 24 24" /> : <PlusIcon width="20" height="20" viewBox="0 0 24 24"/> }
											</>
										) }
									</Box>
								</MenuItem>
								{[Spots.SearchSizeLevel.SearchSize,Spots.SearchSizeLevel.MobileSearchSize].includes(spot) && selected && Boolean(searchSizeAttributeOptions) && (
									<SearchSizeAttributeOptions
										key={`${attribute.name}_options`}
										spot={spot}
										options={searchSizeAttributesByName[ attribute.name ].options}
										setSelectedAttribute={setSelectedAttribute}
										closeMenu={closeMenu}
										attribute={searchSizeAttributesByName[selectedAttribute]}
										matchedAttributeOption={matchedAttributeOption}
										level={level+1}
									/>
								)}
							</React.Fragment>
						);
					})}
				</Box>
				{spot === Spots.SearchSizeLevel.CatalogTabs && Boolean(searchSizeAttributeOptions) && (
					<SearchSizeAttributeOptions
						spot={spot}
						options={searchSizeAttributeOptions}
						setSelectedAttribute={setSelectedAttribute}
						closeMenu={closeMenu}
						attribute={searchSizeAttributesByName[selectedAttribute]}
						matchedAttributeOption={matchedAttributeOption}
						level={level+1}
					/>
				)}
			</>
			)}
		</SearchSizeContext.Consumer>
	);
}

export default SearchSizeAttributes;

const SearchSizeAttributeOptions = ({
	options,
	/*setSelectedAttribute,*/
	closeMenu,
	attribute,
	spot,
	level = 1,
	matchedAttributeOption
}) =>
{
	const chunks = spot === Spots.SearchSizeLevel.CatalogTabs ? ( attribute.name === 'clothes' ? 2 : 3 ) : 2;
	const chunkSize = Math.ceil( options.length / chunks );
	const optionChunks = [];

	for( let i=0; i<chunks;i++ ) {
		optionChunks.push( options.slice( i * chunkSize, (i + 1) * chunkSize ) );
	}
	
	const buildLink = (attribute, option, spot) => {
		return `/${routeNames.searchSize}/${attribute.slug}/${option.slug}`;
	}

	const [selectedAttributeOption, setSelectedAttributeOption] = React.useState(matchedAttributeOption);

	React.useEffect(() => {
		setSelectedAttributeOption(matchedAttributeOption)
	},[matchedAttributeOption]);

	return (
		<SearchSizeContext.Consumer>
			{({spot}) => (
			<>
				{optionChunks.map((options,index) => (
					<Box
						key={index}
						sx={{
							display: "flex",
							flexFlow: "column wrap",
							pl: [ Spots.SearchSizeLevel.SearchSize, Spots.SearchSizeLevel.MobileSearchSize ].includes( spot ) ? level * 4 : 0
						}}
					>
						{options?.map(option => {
							const selected = selectedAttributeOption?.id === option.id;
							
							return (
								<MenuItem
									key={option.id}
									onClick={() => {



										setSelectedAttributeOption?.(option);
										if ( [ Spots.SearchSizeLevel.CatalogTabs, Spots.SearchSizeLevel.MobileSearchSize ].includes( spot ) ) {
											closeMenu?.();
										}
									}}
									component={ Link }
									to={buildLink( attribute, option, spot )}
									sx={theme=>({
										fill: theme.palette.grey.main2,
										display: spot === Spots.SearchSizeLevel.MobileSearchSize && level === 0 ? "none" : "flex",
										minWidth: 100,
									})}
								>
									<Typography
										variant={searchSizeAttributeLevelItemConfig[spot]?.[level]?.typography.variant}
										sx={{
											fontWeight: selected ? 700 : 400,
											mr: "auto",
											...searchSizeAttributeLevelItemConfig[spot]?.[level]?.typography.sx
										}}
									>
										{option.name}
									</Typography>
								</MenuItem>
							);
						})}
					</Box>
				))}
			</>
			)}
			</SearchSizeContext.Consumer>
	);
}
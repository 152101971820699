import React from 'react';
import { Typography as MuiTypography } from "@mui/material";
import Lang from './Lang';

const Typography = ({ children: term, fontWeightBold, sx,  ...rest }) =>
{
	return (
		<MuiTypography
			sx={
			(...args) => ({
				...( typeof sx === "function" ? sx.apply(null, args) : sx),
				...(fontWeightBold ? { fontWeight: 700 } : undefined)
			})
			}
			{...rest}
		>
			<Lang term={term} />
		</MuiTypography>
	);
};

export default Typography;
import React from 'react';
// import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';
import { SearchSizeContext } from '../../../contexts/SearchSizeContext';
import { Spots } from '../../../constants';
import SearchSizeAttributes from './SearchSizeAttributes';
import SearchSizeProductList from './SearchSizeProductList';

const SearchSizeLayout = () => {
	return (
		<Grid container
			sx={(theme)=>({
				pt: 6,
				// bgcolor: theme.palette.grey.main8
			})}
		>
			<Grid
				item
				xs={12}
				md={3}
				sx={{
					display: { xs: "none", md: "flex", flexDirection: "column" },
				}}
			>
				<SearchSizeContext.Provider
					value={{
						spot: Spots.SearchSizeLevel.SearchSize,
					}}
				>
					<SearchSizeAttributes />
				</SearchSizeContext.Provider>
			</Grid>
			<Grid
				item
				xs={12}
				md={8}
				sx={{
					px: { xs: 4, md: 5 }
				}}
			>
				<SearchSizeProductList />
			</Grid>
		</Grid>
	);
}

export default SearchSizeLayout;
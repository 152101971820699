import React from 'react';
import Grid from '@mui/material/Grid';
import Modal from '../Modal';
import authLayerImg1x from '../../assets/images/auth/authlayer-img.jpg';
import authLayerImg2x from '../../assets/images/auth/authlayer-img@2x.jpg';
import authLayerImgBg1x from '../../assets/images/auth/authlayer-bg-img.jpg';
import authLayerImgBg2x from '../../assets/images/auth/authlayer-bg-img@2x.jpg';

import { ReactComponent as Logo } from '../../assets/uzuzd-logo.svg';

const backgroundImageCommonSxProps = {
	backgroundPosition: "center bottom",
	backgroundSize: "cover"
};

const modalBackdropBackgroundSx = (theme) => ({
	[theme.breakpoints.down('md')]: {
		backgroundImage: `image-set( url(${authLayerImgBg1x}) 1x, url(${authLayerImgBg2x}) 2x )`,
		...backgroundImageCommonSxProps
	}
})

const gridLeftBackgroundSX = (theme) => ({
	[theme.breakpoints.up('sm')]: {
		backgroundImage: `image-set( url(${authLayerImg1x}) 1x, url(${authLayerImg2x}) 2x )`,
		...backgroundImageCommonSxProps
	}
})

const AuthLayer = props =>
{
	return (
		<Modal
			onDismiss={props.onModalDismiss}
			backdropSxProp={modalBackdropBackgroundSx}
		>
			<Grid
				container
				spacing={0}
				sx={{
					backgroundColor: '#fff',
					borderRadius: 4,
					minWidth: 290,
					mb: {xs: 20, sm: 0}
				}}
			>
				<Grid
					item
					md={6}
					sx={theme=>({
						position: "relative",
						zIndex: 1,
						bgcolor: "grey.main3",
						justifyContent: "center",
						...(gridLeftBackgroundSX(theme)),
						display: { xs: "none", md: "flex" }
					})}
				>
					<Logo width="135.5" height="30" viewBox="0 0 91 20" fill="#fff" style={{display: "block", margin: "32px auto 0"}} />
				</Grid>
				<Grid
					item
					md={6} xs={12}
					sx={{
						py: 8,
						px: { xs: 4, md: 18 }
					}}
				>
					{props.children}
				</Grid>
			</Grid>
		</Modal>
	);
}

export default AuthLayer;
import React from 'react';
import useStoreState from '../hooks/useStoreState';
import { topLevelCategories } from '../utils';
import CategoryLevel from './Pages/Catalog/CategoryLevel';
import { useCatalogRouteMatch } from '../hooks/useCatalogRouteMatch';
import { CategoryContext } from '../contexts/CategoryContext';
import { Spots } from '../constants';
import MobileNavigationMenu from './MobileNavigationMenu';
import { Box, Typography } from '@mui/material';
import L from './Helpers/Lang'
import { SearchSizeContext } from '../contexts/SearchSizeContext';
// import MobileSearchSizeAttributes from './Pages/Search/MobileSearchSizeAttributes';

const MobileCatalogMenu = ({open,onClose}) =>
{
	const { categoryListById } = useStoreState('catalog');
	const { categoryIdChain } = useCatalogRouteMatch();
	const categories = React.useMemo( () => categoryListById ? topLevelCategories(categoryListById) : [], [categoryListById] );

	return (
		<MobileNavigationMenu
			open={open}
			onClose={onClose}
			transitionDirection="right"
		>
			<Box sx={{mb: 33, display: "flex", flexDirection: "column"}}>
				<CategoryContext.Provider
					value={{
						spot: Spots.CategoryLevel.MobileCatalog,
					}}
				>
					<CategoryLevel
						level={0}
						parent={{children: categories}}
						closeMenu={onClose}
						selectedCategoryIdChain={categoryIdChain}
					/>
				</CategoryContext.Provider>
				<SearchSizeContext.Provider
					value={{
						spot: Spots.SearchSizeLevel.MobileSearchSize,
					}}
				>
					{/* <MobileSearchSizeAttributes
						closeMenu={onClose}
					/> */}
				</SearchSizeContext.Provider>
				<MobileFooter />
			</Box>
		</MobileNavigationMenu>
	);
}

const MobileFooter = () => {
	const { hello_email_address, customer_service_email_address } = useStoreState('config');
	const typographyLinkProps = {fontWeight: 700, p: 4, textDecoration: "none"};
	return (
		<Box sx={theme=>({
			position: 'fixed',
			bottom: 80,
			width: '100%',
			display: "flex",
			// mb: 6,
			bgcolor: theme.palette.white.main
		})}>
			<Box
				sx={theme=>({
					width: '50%',
					display: "flex",
					justifyContent: "center",
					borderRight: `1px solid ${theme.palette.grey.main7}`
				})}
			>
				<Typography
					component="a"
					target="_blank"
					href={`mailto:${hello_email_address}`}
					variant="subtitle"
					sx={typographyLinkProps}
				>
					<L term=".title.propose" />
				</Typography>
				
			</Box>
			<Box
				sx={{
					width: '50%',
					display: "flex",
					justifyContent: "center"
				}}
			>
				<Typography
					component="a"
					target="_blank"
					href={`mailto:${customer_service_email_address}`}
					variant="subtitle"
					sx={typographyLinkProps}
				>
					<L term=".title.support" />
				</Typography>
			</Box>
		</Box>
	)
};

export default MobileCatalogMenu;
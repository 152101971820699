import { CATALOG, RESPONSE } from '../constants';
import API from '../apis/uzuzdApi';
import { APP } from '../constants/urls';
import { paginationFromResponse } from '../utils';

export const clearCatalogAction = () => ({type: CATALOG.CLEAR_CATALOG})

// export const getCatalog = () => async dispatch =>
// {
// 	try
// 	{
// 		dispatch(clearCatalogAction());
// 		const response = await new API().get( `${APP.catalog}` );

// 		dispatch({
// 			type: CATALOG.SET_CATEGORIES,
// 			categories: response.data.categories
// 		});
		
// 		dispatch({
// 			type: CATALOG.SET_ATTRIBUTES,
// 			attributes: response.data.attributes
// 		});
// 	}
// 	catch(error)
// 	{
// 		dispatch({ type: RESPONSE.SET_ERROR, error });
// 	}
// };

export const toggleFavoriteProduct = (product_id) => async dispatch =>
{
	try
	{
		dispatch({ type: CATALOG.SET_FAVORITE_IS_PENDING, pending: true });
		const response = await new API().post( `${APP.favoriteProduct}`, { product_id } );
		dispatch({ type: CATALOG.SET_FAVORITE_IS_PENDING, pending: false });
		dispatch({ type: CATALOG.SET_FAVORITE_TO_PRODUCT_LIST_ITEM, product_id, favorite: response.data?.favorite });
	}
	catch(error)
	{
		dispatch({ type: RESPONSE.SET_ERROR, error });
		dispatch({ type: CATALOG.SET_FAVORITE_IS_PENDING, pending: false });
	}
}


export const getProducts = (params) => async dispatch =>
{
	try
	{
		if ( params.filter ) {
			params.filter = JSON.stringify( params.filter );
		}

		const response = await new API().get( `${APP.products}`, { params } );
		dispatch({ type: CATALOG.SET_PRODUCTLIST, productList: response.data?.data, pagination: paginationFromResponse(response.data) });
	}
	catch(error)
	{
		dispatch({ type: RESPONSE.SET_ERROR, error });
	}
};

export const clearProductListAction = () => ({ type: CATALOG.CLEAR_PRODUCTLIST })
// export const clearPaginationAction = () => ({ type: CATALOG.CLEAR_PAGINATION })
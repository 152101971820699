import { RESPONSE } from '../constants';

const initialState = {};

const response = (state=initialState, action) =>
{
	switch(action.type)
	{
		case RESPONSE.SET_SUCCESS:
			return {
				...action.response
			}
		case RESPONSE.SET_ERROR:
			return {
				...action.error
			}
		case RESPONSE.CLEAR:
			return initialState;
		default:
			return state;
	}
};

export default response;
const seoBlocks = [
	{
		name: 'használt lány',
		items: [
			{
				label: 'póló',
				url: 'lany/polok-felsok'
			},
			{
				label: 'nadrág',
				url: 'lany/nadragok-farmerek'
			},
			{
				label: 'cipő',
				url: 'lany/cipok'
			},
			{
				label: 'kabat',
				url: 'lany/dzsekik-kabatok'
			},
			{
				label: 'ruha',
				url: 'lany/ruhak-szoknyak'
			},
		]
	},
	{
		name: 'használt fiú',
		items: [
			{
				label: 'póló',
				url: 'fiu/polok-ingek'
			},
			{
				label: 'nadrág',
				url: 'fiu/nadragok-farmerek'
			},
			{
				label: 'cipő',
				url: 'fiu/cipok'
			},
			{
				label: 'kabat',
				url: 'fiu/dzsekik-kabatok'
			},
			{
				label: 'pulóver',
				url: 'fiu/puloverek-melegitofelsok'
			},
		]
	},
	{
		name: 'használt baba',
		items: [
			{
				label: 'póló',
				url: 'baba/lany-baba/felsok-polok'
			},
			{
				label: 'body',
				url: 'baba/lany-baba/bodyk'
			},
			{
				label: 'cipő',
				url: 'baba/lany-baba/cipok'
			},
			{
				label: 'pulover',
				url: 'baba/fiu-baba/puloverek-kardiganok'
			},
			{
				label: 'póló',
				url: 'baba/fiu-baba/felsok-polok'
			},
		]
	},
	{
		name: 'használt',
		items: [
			{
				label: 'puzzle',
				url: 'egyeb-termek/puzzle'
			},
			{
				label: 'lego',
				url: 'egyeb-termek/lego-duplo'
			},
			{
				label: 'gyerek takaró',
				url: 'egyeb-termek/kendok-takarok'
			},
			{
				label: 'hordozó',
				url: 'egyeb-termek/hordozok'
			},
			{
				label: 'játék',
				url: 'egyeb-termek/egyeb-jatekok'
			},
		]
	}
];

export default seoBlocks;
import React from 'react';
import useStoreState from '../../../hooks/useStoreState';
import { topLevelCategories } from '../../../utils';
import CategoryLevel from './CategoryLevel';
import { useCatalogRouteMatch } from '../../../hooks/useCatalogRouteMatch';
import {CategoryContext} from '../../../contexts/CategoryContext';
import { Spots } from '../../../constants';

const Catalog = () =>
{
	const { categoryListById } = useStoreState('catalog');
	const { categoryIdChain } = useCatalogRouteMatch();

	const categories = React.useMemo( () => categoryListById ? topLevelCategories(categoryListById) : [], [categoryListById] );

	return (
		<CategoryContext.Provider
			value={{
				spot: Spots.CategoryLevel.Catalog,
			}}
		>
			<CategoryLevel
				level={0}
				parent={{children: categories}}
				selectedCategoryIdChain={categoryIdChain}
				selectedCategoryWasInList={true}
				parentCategoryIsSelected={true}
			/>
		</CategoryContext.Provider>
	);
};

export default Catalog;
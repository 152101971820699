import { combine, bold, blueBg, white, red } from'./colorConsole';
import {isDev} from './env';

class BarionPixel
{
	constructor()
	{
		this.dev = isDev();

		this.bp = window.bp;

		if ( typeof this.bp === 'function' ) {
			this.valid = true;
		}
	}

	static track (event, properties)
	{
		this.handler = new BarionPixel();
		if ( !this.handler.valid ) {
			if ( this.handler.dev ) {
				console.log(`%cinvalid bp ${event} TRACK`, ...[ [ red ] ].map( styleArray => combine(...styleArray) ) );
			}
			return;
		}

		if ( this.handler.dev ) {
			console.log(`%c${event}`, ...[ [ blueBg, white, bold ] ].map( styleArray => combine(...styleArray) ) );
			console.log(properties);
		}

		try {

			if ( properties.id ) {
				properties = { ...properties, id: properties.id.toString() }
			}

			this.handler.bp('track', event, properties);
		} catch(error) {
			console.warn( error );
		}
		if ( this.handler.dev ) {
			console.log(`%csuccess bp ${event} TRACK`, ...[ [ blueBg, white ] ].map( styleArray => combine(...styleArray) ) );
		}
	}

	static grantConsent() {
		BarionPixel.consent('grantConsent');
	}
	
	static rejectConsent() {
		BarionPixel.consent('rejectConsent');
	}

	static consent(type)
	{
		if ( ![ 'grantConsent', 'rejectConsent' ].includes( type ) ) { return; }

		this.handler = new BarionPixel();

		if ( this.handler.dev ) {
			console.log(`%c${type}`, ...[ [ blueBg, white, bold ] ].map( styleArray => combine(...styleArray) ) );
		}

		if (this.handler.valid) {
			this.handler.bp('consent', type);
		}
	}
}

export default BarionPixel;
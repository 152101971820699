import React from 'react';
import { Paper, Container, Typography, Box } from '@mui/material';
import Modal from '../Modal';

const ResponseLayer  = props =>
{
		return (
			<Modal
				onDismiss={props.onDismiss}
			>
				<Container maxWidth="sm">
					<Paper
						sx={theme=>({
							p: 4,
							pt: 8
						})}
					>
						<Typography variant="h3" sx={{mb: 4}}>{props.title}</Typography>
						<Typography variant="subtitle">{props.message}</Typography>
						<Box
							sx={{display: "flex", justifyContent: "flex-end", pt: 4}}
						>
							{props.button}
						</Box>
					</Paper>
				</Container>
			</Modal>
		);
}

export default ResponseLayer;
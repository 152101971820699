import React, {useEffect} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {useGet} from '../../hooks/useApi';
import {APP as URLS} from '../../constants/urls';

import EmailVerificationResult from './EmailVerificationResult';
import FullPageLoader from '../Helpers/FullPageLoader';

const EmailVerification = (props) =>
{
	const {id, hash} = useParams();
	const [searchParams] = useSearchParams();
	const expires = searchParams.get('expires');
	const signature = searchParams.get('signature');

	const url= `${URLS.verifyEmail}/${id}/${hash}?expires=${expires}&signature=${signature}`;
	const [verify, {isDone, response, error}] = useGet(url);

	useEffect(()=>{
		verify();
	},[verify])

	if ( !isDone )
	{
		return (
			<FullPageLoader />
		);
	}

	return (
		<EmailVerificationResult onModalDismiss={props.onModalDismiss} response={response} error={error} />
	);
}

export default EmailVerification;
import React from 'react';
import { Grid } from '@mui/material';
import Catalog from './Catalog';
import CatalogProductList from './CatalogProductList';

const CatalogLayout = () => (
	<Grid container
		sx={{
			pt: 6,
		}}
	>
		<Grid item xs={12} md={3}
			sx={{
				display: { xs: "none", md: "flex" },
				pl: 4
			}}
		>
			<Catalog />
		</Grid>
		<Grid item xs={12} md={8}
			sx={{
				px: { xs: 4, md: 5 }
			}}
		>
			<CatalogProductList />
		</Grid>
	</Grid>
);

export default CatalogLayout;
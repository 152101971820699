import { LANG } from '../constants';


const INITIAL_STATE = {
		dictionary: null,
		dictonaryIsSet: false,
		locale: null,
		localeIsSet: false
};

const lang = (state=INITIAL_STATE, action) => {
		switch(action.type)
		{
				case LANG.SET:
						return {
								...state,
								dictonaryIsSet: true,
								dictionary: action.dictionary
						};
				
				case LANG.LOCALE_SET:
						return {
								...state,
								locale: action.locale,
								localeIsSet: true
						};

				default:
						return state;
		}
};

export default lang;
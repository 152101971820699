import {useSelector} from 'react-redux';
import {responseSelector} from '../selectors';

const useResponseState = (url=undefined, methods=undefined) => {
	const response = useSelector( responseSelector );
	return (
		(url === undefined || url === response?.url ) &&
		( !methods || methods.includes(response.method) )
		? response
		: {}
	);
}

export default useResponseState;
import useStoreState from '../hooks/useStoreState';
import { usePost } from '../hooks/useApi';
import {AUTH as URLS} from '../constants/urls';
import {AUTH} from '../constants';
import { useEffect } from 'react';
import {useDispatch} from 'react-redux';

export const useAutoLogout = () => {
	const dispatch = useDispatch()
	const auth = useStoreState('auth');
	const [ logout ] = usePost(URLS.logout);
	
	useEffect(()=>{
		if ( auth.pending === false && auth.user && auth.user.active !== true ) {
			dispatch({ type: AUTH.UNAUTHENTICATED });
			logout();
		}
	},[auth, dispatch, logout]);
}
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import useResponseState from '../../hooks/useResponseState';
import { RESPONSE } from '../../constants';
import { routeNames } from '../../constants/uiRouteNames';

// import { useSnackbar } from 'notistack';
// import ResponseLayer from '../../Helpers/ResponseLayer';
// import { IconButton } from '@mui/material';
// import { Close as CloseIcon } from '@mui/icons-material';

const ErrorHandler = ({children}) =>
{
	// const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const response = useResponseState();

	React.useEffect(()=>{
		switch(response.status) {
			case 404:
				navigate('/' + routeNames.notFound, {state:{from: location}});
				dispatch({type: RESPONSE.CLEAR})
				break;
			case 403:
				navigate('/' + routeNames.forbidden, {state:{from: location}});
				dispatch({type: RESPONSE.CLEAR})
				break;
			default:
				break;
		}
	// 	if ( responseError?.message )
	// 	{
	// 		console.log("handled ERROR", responseError.message, responseError.details);
	// 		// dispatch({type: RESPONSE_ERROR.CLEAR_ERROR})
	// // 		enqueueSnackbar(responseError.message, {
	// // 			variant: "error",
	// // 			anchorOrigin: { vertical: "bottom", horizontal: "left" },
	// // 			// autoHideDuration: 3000,
	// // 			persist: true,
	// // 			action: key => (
	// // 				<IconButton
	// // 					size="small"
	// // 					color="white"
	// // 					onClick={() => { closeSnackbar(key) }}
	// // 				>
	// // 					<CloseIcon />
	// // 				</IconButton>
	// // 			),
	// // 			onClose: () => dispatch({type: RESPONSE_ERROR.CLEAR_ERROR})
	// // 		})
	// 	}
	},[dispatch, navigate, location, response]);
	// },[dispatch,enqueueSnackbar,closeSnackbar,responseError]);

	return (
		<>
			{children}
		</>
	);
};

export default ErrorHandler;
import React from 'react';
import AlertDialog from '../../Helpers/AlertDialog';
import { useDict } from '../../../hooks/useDict';
import { Typography } from '@mui/material';
import L from '../../Helpers/Lang';

const PromoFilterDialog = ({promoFilterDialogOpen, setPromoFilterDialogOpen}) =>
{
	const closeDialog = React.useCallback(()=>setPromoFilterDialogOpen(false),[setPromoFilterDialogOpen]);
	
	const {buttonLabel} = useDict();

	return (
		<AlertDialog
			open={promoFilterDialogOpen}
			onClose={closeDialog}
			title={undefined}
			content={(
				<Typography variant="body1"><L term="label.promo-filter" /></Typography>
			)}
			buttons={[{
				label: buttonLabel('okay'),
				color: "secondary",
				onClick: closeDialog
			}]}
			severity='info'
		/>
	);
}

export default PromoFilterDialog;
import { Box } from '@mui/material';
import React from 'react';

const GoogleAd = (props) =>
{
    const { slot, sx: propSx } = props;  
    const production = process.env.NODE_ENV === 'production';

    React.useEffect(() => { try { (window.adsbygoogle = window.adsbygoogle || []).push({}); } catch (e) { } },[]);

    const sx = !production ? {
        height: 100,
        bgcolor: '#E9B100',
        display: "flex",
        flexBasis: '100%',
        justifyContent: "center",
        alignItems: "center"
    } : {};

    return (
        <Box sx={[propSx,sx,{
            mb: 4
        }]}>
            {!production && slot}
            {production && <ins
                className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-7072537072813990"
                data-ad-slot={slot}
                data-ad-format="auto"
                data-full-width-responsive="true"
            ></ins>}
        </Box>
    );
};

export default GoogleAd;
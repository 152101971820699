import { APP } from '../constants';

const INITIAL_STATE = {
		loginFormIsVisible: false,
		signUpFormIsVisible: false,
		passwordForgotFormIsVisible: false,
		passwordResetFormIsVisible: false,
		emailVerificationIsVisible: false,
		profileMenuOpen: false,
		catalogMenuOpen: false,
		filterMenuOpen: false
};

const app = (state=INITIAL_STATE, action) => {
		switch(action.type)
		{
				case APP.SHOW_LOGIN_FORM:
						return {
								...INITIAL_STATE,
								loginFormIsVisible: true
						};

				case APP.HIDE_LOGIN_FORM:
						return {
								...INITIAL_STATE
						};

				case APP.SHOW_SIGNUP_FORM:
						return {
								...INITIAL_STATE,
								signUpFormIsVisible: true
						};

				case APP.HIDE_SIGNUP_FORM:
						return {
								...INITIAL_STATE
						};
						
				case APP.SHOW_RESET_PASSWORD_FORM:
						return {
								...INITIAL_STATE,
								passwordResetFormIsVisible: true
						};

				case APP.HIDE_RESET_PASSWORD_FORM:
						return {
								...INITIAL_STATE
						};

				case APP.SHOW_FORGOT_PASSWORD_FORM:
						return {
								...INITIAL_STATE,
								passwordForgotFormIsVisible: true
						};

				case APP.HIDE_FORGOT_PASSWORD_FORM:
						return {
								...INITIAL_STATE
						};

				case APP.SHOW_EMAIL_VERIFICATION:
						return {
								...INITIAL_STATE,
								emailVerificationIsVisible: true
						};

				case APP.HIDE_EMAIL_VERIFICATION:
						return {
								...INITIAL_STATE
						};
						
				case APP.OPEN_PROFILE_MENU:
						return {
								...state,
								catalogMenuOpen: false,
								filterMenuOpen: false,
								profileMenuOpen: true,
						};

				case APP.CLOSE_PROFILE_MENU:
						return {
								...state,
								profileMenuOpen: false
						};
						
				case APP.OPEN_CATALOG_MENU:
						return {
								...state,
								profileMenuOpen: false,
								filterMenuOpen: false,
								catalogMenuOpen: true,
						};

				case APP.CLOSE_CATALOG_MENU:
						return {
								...state,
								catalogMenuOpen: false
						};
						
				case APP.OPEN_FILTER_MENU:
						return {
								...state,
								profileMenuOpen: false,
								catalogMenuOpen: false,
								filterMenuOpen: true,
						};

				case APP.CLOSE_FILTER_MENU:
						return {
								...state,
								filterMenuOpen: false
						};

				default:
						return state;
		}
};

export default app;
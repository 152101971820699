import isEmail from 'validator/es/lib/isEmail';
import isNumeric from 'validator/es/lib/isNumeric';
// export const composeValidators = (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined);
import { isValidAccountNumber } from './isValidAccountNumber';

export class Validator
{
	constructor(_messages) {
		this._messages = _messages;
	}

	// static required = (value) => value !== undefined;
	// static required = (value) => typeof value === 'string' && value.length > 0;
	static required = (value) => value !== undefined && value !== null && !!value;
	static string = (value) =>  typeof value === "string";
	static numeric = (value) => isNumeric( typeof value === 'string' ? value : value.toString() );
	static array = (value) => Array.isArray( value );
	static boolean = (value) => [ true, false ].includes(value);
	static email = (value) => Validator.required(value) && isEmail(value);
	static minstring = (value, min) => Validator.string(value) && value.length >= min;
	static maxstring = (value, max) => Validator.string(value) && value.length <= max;
	static sizestring = (value, len) => Validator.string(value) && value.length === parseInt(len,10);
	static regex = (value, regex) => !value || ( Validator.string(value) && new RegExp(regex).test(value) );
	static phone = (value) => Validator.string(value) && /^(\+36|36)(20|30|31|70|50|51)\d{7}$/.test(value);
	static account_number = (value) => Validator.string(value) && isValidAccountNumber(value);
	static minnumeric = (value, min) => Validator.numeric(value) && parseInt(value,10) >= parseInt(min,10);
	static maxnumeric = (value, max) => Validator.numeric(value) && value <= max;
	static minarray = (value, min) => Validator.array(value) && value.length >= min;
	static maxarray = (value, max) => Validator.array(value) && value.length <= max;
	static confirmed = (value, otherValue) => value === otherValue;

	make = (config) => (values) =>
	{
		const errors = {};
		for (const [fieldName, ruleConfig] of Object.entries(config))
		{
			const [pipedRules, customMessageWithParameter] = ruleConfig.split(';');
			const [customMessageLabel] = customMessageWithParameter?.split('|') || [ undefined, undefined ];

			pipedRules.split('|').every(ruleName =>
			{
				const [rule, param] = ruleName.split(':');
				const action = rule.replace('.','');
				const [topLevelRule] = rule.split('.');
				let pass;

				switch( rule )
				{
					case 'confirmed':
						pass = Validator[ action ]( values[fieldName], values[ fieldName + '_confirmation' ] );
						break;
					default:
						pass = Validator[ action ]( values[fieldName], param );
				}

				if ( !pass )
				{
					if ( rule === 'confirmed' ) {
						const _fieldName = fieldName + '_confirmation';
						errors[ _fieldName ] = this.getMessage(fieldName, 'same', { otherAttribute: '_confirmation' });
					}
					else {
						if ( customMessageLabel ) {
							errors[fieldName] = customMessageLabel;
						}
						else {
							errors[fieldName] = this.getMessage(fieldName, rule, {[topLevelRule]: param});
						}
					}
				}

				return pass;
			});
		}

		return errors;
	};

	getMessage = (_attribute = '', rule = '', parameters) =>
	{
		const attribute = this._messages?.attributes?.[ _attribute ] || _attribute;
		let message = this._messages && Validator.string(rule) ? rule.split('.').reduce( ( message, key ) => {
			return key ? message[ key ] : message;
		}, this._messages ) : '';

		message = message.replace(':attribute', attribute);
		if ( parameters.otherAttribute ) {
			const otherAttribute = this._messages?.attributes?.[ parameters.otherAttribute ] || parameters.otherAttribute;
			message = message.replace( ':other', otherAttribute );
		}

		for (const [topLevelRule, value] of Object.entries(parameters))
		{
			message = message.replace(`:${topLevelRule}`, value);
		}

		return message;
	}
}

export const errorText = (responseErrors, field, meta) => ( responseErrors?.[field] ) || ( (meta.touched && meta.error) ? meta.error : "" )
export const hasError = (...args) => !!errorText(...args)
import { PRODUCT } from '../constants';

const initialState = {
	product: null,
	categoryListById: null, // breadcrumb
	attributeListById: null,
	productDeleted: false,
	userProducts: null,
	pagination: undefined,
	setFavoriteIsPending: false,
	productIsFetching: false,
	availabilityInquired: false
};

const product = (state=initialState, action) =>
{
	switch(action.type)
	{
		case PRODUCT.SET_PRODUCT_DATA:
			return {
				...state,
				loaded: true,
				product: action.data
			};
		case PRODUCT.SET_ATTRIBUTES:
			return {
				...state,
				attributeListById: action.attributes
			};
		case PRODUCT.SET_CATEGORIES:
			return {
				...state,
				categoryListById: action.categories
			};
		case PRODUCT.CLEAR_PRODUCT_DATA:
			return initialState;
		case PRODUCT.SET_PRODUCT_DELETED:
			return { ...state, productDeleted: true };
		case PRODUCT.SET_USER_PRODUCTS:
			return {
				...state,
				userProducts: [ ...( Array.isArray(state.userProducts) ? state.userProducts : [] ), ...action.products ],
				pagination: action.pagination
			};

		case PRODUCT.SET_FAVORITE:
			return {
				...state,
				product: { ...state.product, favorite: action.favorite }
			};
			
		case PRODUCT.SET_FAVORITE_IN_PRODUCTLIST:
			return {
				...state,
				product: {
					...state.product,
					favorite: state.product?.product_id === action.product_id ? action.favorite : state.product?.favorite,
				},
				userProducts: state.userProducts?.map( product => product.product_id === action.product_id ? { ...product, favorite: action.favorite } : product )
			};
			
		case PRODUCT.SET_FAVORITE_IS_PENDING:
			return {
				...state,
				setFavoriteIsPending: action.pending
			};
			
		case PRODUCT.PRODUCT_IS_FETCHING:
			return {
				...state,
				productIsFetching: action.fetching
			};
			
		case PRODUCT.SET_AVAILABILITY_INQUIRED:
			return {
				...state,
				availabilityInquired: action.value
			};
		
		case PRODUCT.UPDATE_PRODUCT_AVAILABILITY:
			return {
				...state,
				product: { ...state.product, recent: action.recent },
			};

		default:
			return state;
	}
};

export default product;
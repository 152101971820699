import React from 'react';
import {useDispatch} from 'react-redux';
import {usePost} from '../../hooks/useApi';
import {AUTH as URLS} from '../../constants/urls';
import {AUTH,CART} from '../../constants';
import {showSignUpFormAction, hideLoginFormAction, showForgotPasswordFormAction} from '../../actions/appActions';
import { initialize } from '../../actions/initActions';

import UTypography from '../Helpers/Typography';
import AuthForm from './AuthForm';
import AuthLayer from './AuthLayer';
import TypLink from '../Helpers/TypLink';

const Login = props =>
{
	const dispatch = useDispatch();
	const [ login, { isDone, isSuccess, responseData, error } ] = usePost(URLS.login);
	const [pending,setPending] = React.useState(false);
	
	React.useEffect(() => {
		if ( isDone && isSuccess )
		{
			dispatch({
				type: AUTH.AUTHENTICATED,
				user: responseData.user
			});
			
			dispatch({
				type: CART.SET_CART,
				cart: responseData.cart
			});

			dispatch(initialize());

			dispatch(hideLoginFormAction());
		}
		setPending(false);
	}, [ isDone, isSuccess, responseData, dispatch ]);

	return (
		<AuthLayer
			onModalDismiss={props.onModalDismiss}
		>
			<UTypography
				variant="h4"
				align="center"
				sx={{mb: 7}}
			>
				.title.login
			</UTypography>

			<AuthForm
				submitDisabled={pending}
				fields={['email','password','remember']}
				submitLabel="nav.login"
				onSubmit={({ email, password, remember }) => {
					setPending(true);
					login({email, password, remember});
				}}
				error={error}
				validationRules={{
					email: 'required|email',
					password: 'required|min.string:8'
				}}
			/>

			<TypLink
				variant="body1"
				align="center"
				component="div"
				sx={{mt:6}}

				term=".subtitle.have_no_profile"
				onClick={() => dispatch(showSignUpFormAction())}
			/>

			<TypLink
				variant="body1"
				align="center"
				component="div"
				sx={{mt:2}}

				term=".subtitle.forgot_password"
				onClick={() => dispatch(showForgotPasswordFormAction())}
			/>

		</AuthLayer>
	);
}

export default Login;
import { useState, useReducer, useCallback } from 'react';
import API from '../apis/uzuzdApi';

const requestStates = {
	initial: { isPending: false, isSuccess: false, isError: false, isDone: false, response: null, error: null },
	loading: { isPending: true, isSuccess: false, isError: false, isDone: false },
	success: { isPending: false, isSuccess: true, isError: false, isDone: true },
	error: { isPending: false, isSuccess: false, isError: true, isDone: true }
};

const stateReducer = ( state, action ) =>
{
	switch(action.type)
	{
		case "loading":
			return {
				...state,
				...requestStates.loading
			};
		case "success":
			return {
				...state,
				response: action.response,
				responseData: action.response?.data,
				...requestStates.success
			};
		case "error":
			return {
				...state,
				response: action.error,
				error: action.error,
				...requestStates.error
			}
		default:
			return state;
	}
}

export const useApiGeneric = (method, url, _params = {}) =>
{
	const [params, setParams] = useState(_params);
	const [state, dispatch] = useReducer(stateReducer, requestStates.initial);

	const memoizedCallback = useCallback(
		async data => {
			dispatch({type:"loading"});
			try {
				const response = await new API().request({ url, method, params, data });

				dispatch({type: "success", response});
			} catch (error) {
				dispatch({type: "error", error});
			}
		},
		[method, url, params]
	);

	return [memoizedCallback, state, { params, setParams }];
};

export const useGet = ( ...args ) => useApiGeneric( 'get', ...args );
export const usePost = url => useApiGeneric( 'post', url );
export const usePut = url => useApiGeneric( 'put', url );
export const useDelete = url => useApiGeneric( 'delete', url );
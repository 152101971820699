import React from 'react';
import { showLoginFormAction } from '../../actions/appActions';
import useStoreState from '../../hooks/useStoreState';

// import AppLayout from '../AppLayout';
import AppLayers from '../AppLayers';

const RequireAuth = ({ children, ...rest })  => {
	const auth = useStoreState('auth');

	// const location = useLocation(); // intendedroute
	// return <Navigate to="/login" state={{ from: location }} />;
	// let from = location.state?.from?.pathname || "/";

	return auth.isSignedIn ? children : <AppLayers immediateAction={[ showLoginFormAction() ]} {...rest} />;
};

export default RequireAuth;
import React from 'react';
import { Link } from 'react-router-dom';
import ProductListItem from './ProductListItem';
import {ProductListItemSkeleton} from './ProductListItem';
import UploadProductButton from './UploadProductButton';
import {Grid, Typography, Button, Box} from '@mui/material';
import {showLoginFormAction} from '../../../actions/appActions';
import L from '../../Helpers/Lang';
import {ProductListContext} from '../../../contexts/ProductListContext';
import { routeNames } from '../../../constants/uiRouteNames';
import useStoreState from '../../../hooks/useStoreState';
import { useDispatch } from 'react-redux';
import { ReactComponent as AddIcon } from '../../../assets/icons/plus.svg';

const ProductList = ({list,ownProfile}) => {
	return (
		<Grid container
			spacing={2}
			sx={{
				pt: 10,
				px: { xs: 2, sm: 6, md: 0 }
			}}
		>
			{!Array.isArray(list) && [ 1,2,3,4 ].map( i => <ProductListItemSkeleton key={i}/>)}
			{(Array.isArray(list) && list.length > 0 ) && list?.map(product => (
				<ProductListItem
					key={product.product_id}
					user={product.user}
					product={product}
					ownProfile={ownProfile}
				/>
			))}
			{ (Array.isArray(list) && list.length === 0 ) && (
				<ProductListContext.Consumer>
					{({spot,searchTag}) => (
						<EmptyProductList { ...{spot,searchTag} } />
					)}
				</ProductListContext.Consumer>
			)}
			{ownProfile && <UploadProductButton />}
			<ProductListContext.Consumer>
				{({pagination, loadNextPage}) => ( Boolean(loadNextPage) ) && (
					<Grid item xs={12}
						sx={{
							display: "flex",
							justifyContent: "center",
							py: 8
						}}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={loadNextPage}
						>
							{<L term="label.next"/>} {/* `${pagination.perPage} [ ${pagination.to + 1}-${pagination.to + pagination.perPage} ]` */}
						</Button>
					</Grid>
				)}
			</ProductListContext.Consumer>
		</Grid>
	);
}

export default ProductList;

const EmptyProductList = ({spot, searchTag}) =>
{
	const dispatch = useDispatch();
	const auth = useStoreState('auth');
	const config = useStoreState('config');

	return (
		<Grid item xs={12}
			sx={theme=>({
				display: "flex",
				pt: '20% !important',
				flexDirection: "column",
				alignItems: "center",
				alignContent: "center",
				fill: theme.palette.grey.main6,
				color: theme.palette.grey.main4,
				textAlign: "center"
			})}
		>
			{!spot && (
				<Typography variant="h5" sx={{fontWeight: 400}} color="inherit"><L term=".product.emptylist" /></Typography>
			)}
			{spot === routeNames.favorites && (
				<Typography variant="h5">
					<L term=".product.emptylist.favorites" partial={0} />
					<Box
						component={Link}
						to={`/${routeNames.catalog}`}
						sx={[(theme)=>({
							textDecoration: "none",
							color: theme.palette.info.main
						})]}
					>
						<L term=".product.emptylist.favorites" partial={1} />
					</Box>
				</Typography>
			)}
			{spot === routeNames.catalog && (
				<Box>
					<Typography variant="h5" sx={{mb: 6}}><L term=".product.emptylist.catalog.text"/></Typography>
					<Button
						variant="contained"
						color="pale"
						startIcon={<AddIcon />}
						component={ auth.isSignedIn ? Link : null }
						to={ auth.isSignedIn ? ( config.is_valid_seller ? `/${routeNames.uploadProduct}` : `/${routeNames.fillSellerInfo}` ) : null}
						onClick={ev => {
							if (!auth.isSignedIn) { dispatch(showLoginFormAction())}
						}}
						sx={[(theme)=>({
							textDecoration: "none",
							color: theme.palette.black.main,
							fill: theme.palette.black.main
						})]}
					>
						<L term=".product.emptylist.catalog.button-label"/>
					</Button>
				</Box>
			)}
			{spot === routeNames.search && (
				<Box sx={{px: { xs: 2, sm: 20}}}>
					{searchTag?.pattern && <Typography variant="h5" sx={theme=>({mb: 6, color: theme.palette.grey.main2})}><L term=".product.emptylist.search.text" replacements={{pattern: searchTag.pattern}}/></Typography>}
					<Typography variant="h5" sx={theme=>({mb: 6, color: theme.palette.grey.main2})}><L term=".product.emptylist.search.subtext"/></Typography>
				</Box>
			)}
		</Grid>
	);
}
import { AUTH, LANG, INIT, CONFIG, CATALOG, CART,/*, NOTIFICATIONS*/ SEARCH, MESSAGES} from '../constants';
import API from '../apis/uzuzdApi';
import { INIT as URLS/*, LANG as LANGURLS*/ } from '../constants/urls';

export const initialize = () => async dispatch =>
{

	try
	{
		await new API().get(URLS.csrfCookie);
		dispatch({
			type: AUTH.SET_CSRF_COOKIE,
		});

		// for testing purposes
		// await new API().post(LANGURLS.locale, { language: "hu" });
		
		const response = await new API().get(URLS.getUser);
		
		dispatch({
			type: AUTH.AUTHENTICATED,
			user: response.data.user
		});

	}
	catch(error)
	{
		dispatch({
			type: AUTH.UNAUTHENTICATED
		});
	}

	try
	{
		const response = await new API().get(URLS.initialize);
		
		dispatch({
			type: LANG.SET,
			dictionary: response.data.lang
		});
		
		dispatch({
			type: LANG.LOCALE_SET,
			locale: response.data.locale
		});

		dispatch({
			type: CONFIG.SET_CONFIG,
			config: response.data?.config
		});
		
		dispatch({
			type: CATALOG.SET_CATEGORIES,
			categories: response.data?.categories
		});
		
		dispatch({
			type: SEARCH.SET_SEARCH_SIZE_ATTRIBUTES,
			searchSizeAttributes: response.data?.search_size_attributes
		});
		
		dispatch({
			type: CATALOG.SET_ATTRIBUTES,
			attributes: response.data?.attributes
		});
		
		// dispatch({
		// 	type: CATALOG.SET_CATEGORYMAP,
		// 	category_map: response.data?.category_map
		// });
		
		dispatch({
			type: CART.SET_CART,
			cart: response.data?.cart
		});
		
		// dispatch({
		// 	type: NOTIFICATIONS.SET_UNREAD_COUNT,
		// 	count: response.data?.unread_notification_count
		// });
		
		dispatch({
			type: MESSAGES.SET_UNREAD_THREAD_COUNT,
			count: response.data?.unread_message_thread_count
		});

		dispatch({
			type: INIT.INITIALIZED
		});
	}
	catch(error)
	{
		dispatch({
			type: INIT.UNINITIALIZED
		});
	}
};
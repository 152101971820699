import React from 'react';
import { Grid, Box, TextField, Autocomplete, Typography, Chip, Select, MenuItem } from '@mui/material';
import SearchProductList from './SearchProductList';
import API from '../../../apis/uzuzdApi';
import {APP as URLS} from '../../../constants/urls';
import useStoreState from '../../../hooks/useStoreState';
import { useDict } from '../../../hooks/useDict';
import L from '../../Helpers/Lang';
import useFromSearchParams from '../../../hooks/useFromSearchParams';
import SearchUserList from './SearchUserList';

const SearchLayout = () =>
{
	const { attributeListById } = useStoreState('catalog');
	
	const { label } = useDict();
	const { builtOn } = useStoreState('search');

	const error = false;
	const inputProps = undefined; //{type: "number"};
	const { filterFromSearchParams, searchTagFromSearchParams } = useFromSearchParams();

	const [searchTarget, setSearchTarget] = React.useState('catalog');

	const [inputValue, setInputValue] = React.useState( '' );
	const [searchTag, setSearchTag] = React.useState( searchTagFromSearchParams || null );
	const [open, setOpen] = React.useState(true);
	const [options, setOptions] = React.useState([]);


	const [userSearchInputValue, setUserSearchInputValue] = React.useState( '' );
	const [userSearchTag, setUserSearchTag] = React.useState( null );

	const setSearchTagReverse = React.useCallback(tag =>
	{
		setSearchTag({...tag, reverse: true});
	}, []);

	const patternOption = React.useMemo(()=>({ label: `"${inputValue}" ${label('search_for')}`, type: "pattern", pattern: inputValue }), [label, inputValue]);

	const [searchOptionsByAttribute, setSearchOptionsByAttribute] = React.useState( filterFromSearchParams );

	const toggleSearchOption = React.useCallback((option) =>
	{
		const attribute_id = option.attribute_id.toString();

		setSearchOptionsByAttribute( state => {
			let withoutAttribute = Object.fromEntries( Object.entries(state||{}).filter(([key]) => key !== attribute_id ) );
			if ( Object.keys(withoutAttribute||{}).length === 0 ) {
				withoutAttribute = undefined;
			}

			return (
					Array.isArray(state?.[ attribute_id ])
						? (
							state[ attribute_id ].includes( option.option_id )
							? (
								state[ attribute_id ].length === 1
								? withoutAttribute
								: { ...state, [attribute_id]: state[ attribute_id ].filter( option_id => option_id !== option.option_id ) }
							)
							: { ...state,  [attribute_id]: [ ...state[ attribute_id ], option.option_id ]}
						)
						: { ...state, [attribute_id]: [ option.option_id ] }
				)
			;
		});
	}, []);

	React.useEffect(() => {
		if ( inputValue.length < 2 ) {
			return;
		}

		if ( searchTag && ( searchTag.label === inputValue || searchTag.pattern === inputValue ) ) {
			return;
		}

		const tId = setTimeout(
			async () => {
				const response = await new API().get(URLS.searchTags, { params: {pattern: inputValue} });
				const tags = response?.data;
				
				if ( Array.isArray(tags) )
				{
					setOptions([
						...tags.map(tag => ({ ...tag, type: "tag" })),
						// { label: `"${inputValue}" ${label('search_for')}`, type: "pattern", pattern: inputValue }
						patternOption
					]);
				}
			},
		500);

		return () => {
			clearTimeout(tId);
		}
	}, [label, searchTag, patternOption, inputValue]);

	return (
		<Grid container
			sx={(theme)=>({
				pt: 6,
				// bgcolor: theme.palette.grey.main8
			})}
		>
			{searchTarget === 'users' && (
				<Grid
					item
					xs={12}
					md={3}
					sx={{
						display: { xs: "none", md: "flex", flexDirection: "column" },
					}}
				/>
			)}
			{searchTarget === 'catalog' && (
				<Grid
					item
					xs={12}
					md={3}
					sx={{
						display: { xs: "none", md: "flex", flexDirection: "column" },
					}}
				>
					<SideOptionsBlocks
						{...{
							attributeListById,
							searchOptionsByAttribute,
							builtOn,
							toggleSearchOption
						}}
					/>
				</Grid>
			)}
			<Grid
				item
				xs={12}
				md={8}
				sx={{
					px: { xs: 4, md: 5 }
				}}
			>
				<Box sx={{display: 'flex', alignItems: 'center'}}>
					<SearchTargetSelect
						value={searchTarget}
						onChange={ev => setSearchTarget(ev.target.value)}
					/>
					{searchTarget === 'catalog' && (
						<SearchAutoComplete
							{...{
								open, setOpen,
								options, setOptions,
								setInputValue,
								error,
								searchTag, setSearchTag,
								inputProps,
								patternOption
							} }
						/>
					)}
					{searchTarget === 'users' && (
						<SearchAutoComplete
							open={false}
							setOpen={() => undefined}
							options={[]}
							setOptions={() => undefined}
							setInputValue={setUserSearchInputValue}
							error={false}
							searchTag={undefined}
							setSearchTag={setUserSearchTag}
							inputProps={undefined}
							patternOption={userSearchInputValue}
						/>
					)}
				</Box>
				{builtOn?.category_tags?.length > 0 && (
					<Box sx={{py: 4}} >
						{builtOn?.category_tags?.map((tag,index)=><Chip key={index} variant="outlined" size="small" sx={{m: 1}} label={tag.name}/>)}
					</Box>
				)}
				{searchTarget === 'catalog' && (
					<SearchProductList
						searchTag={searchTag}
						setSearchTag={setSearchTagReverse}
						searchOptionsByAttribute={searchOptionsByAttribute}
						setSearchOptionsByAttribute={setSearchOptionsByAttribute}
						inputValue={inputValue}
						setInputValue={setInputValue}
						/* MobileSearchProductFilter */
						toggleSearchOption={toggleSearchOption}
					/>
				)}
				{searchTarget === 'users' && (
					<SearchUserList
						searchTag={userSearchTag}
						setSearchTag={undefined}
					/>
				)}
			</Grid>
		</Grid>
	);
}

export const SideOptionsBlocks = ({
	attributeListById,
	searchOptionsByAttribute,
	builtOn,
	toggleSearchOption
}) =>
{
	return (
		<>
			{/** szin */}
			<SideOptionsBlock
				label={attributeListById[1].attribute_name}
				options={attributeListById[1].options}
				selectedOptions={searchOptionsByAttribute?.[1]}
				onOptionClick={(option) => toggleSearchOption(option)}
				builtOn={builtOn?.option_tags?.filter(tag=>tag.parameters.attribute === 1)?.map(tag => tag.parameters.value)}
			/>
			{/** marka */}
			<SideOptionsBlock
				label={attributeListById[9].attribute_name}
				options={attributeListById[9].options}
				selectedOptions={searchOptionsByAttribute?.[9]}
				onOptionClick={(option) => toggleSearchOption(option)}
				builtOn={builtOn?.option_tags?.filter(tag=>tag.parameters.attribute === 9)?.map(tag => tag.parameters.value)}
			/>
			{/** allapot */}
			<SideOptionsBlock
				label={attributeListById[2].attribute_name}
				options={attributeListById[2].options}
				selectedOptions={searchOptionsByAttribute?.[2]}
				onOptionClick={(option) => toggleSearchOption(option)}
				builtOn={builtOn?.option_tags?.filter(tag=>tag.parameters.attribute === 2)?.map(tag => tag.parameters.value)}
			/>
			{/** női ruha meret */}
			<SideOptionsBlock
				label={attributeListById[3].attribute_description}
				options={attributeListById[3].options}
				selectedOptions={searchOptionsByAttribute?.[3]}
				onOptionClick={(option) => toggleSearchOption(option)}
				builtOn={builtOn?.option_tags?.filter(tag=>tag.parameters.attribute === 3)?.map(tag => tag.parameters.value)}
			/>
			{/** női cipo meret */}
			<SideOptionsBlock
				label={attributeListById[5].attribute_description}
				options={attributeListById[5].options}
				selectedOptions={searchOptionsByAttribute?.[5]}
				onOptionClick={(option) => toggleSearchOption(option)}
				builtOn={builtOn?.option_tags?.filter(tag=>tag.parameters.attribute === 5)?.map(tag => tag.parameters.value)}
			/>
			{/** gyerek ruha meret */}
			<SideOptionsBlock
				label={attributeListById[7].attribute_description}
				options={attributeListById[7].options}
				selectedOptions={searchOptionsByAttribute?.[7]}
				onOptionClick={(option) => toggleSearchOption(option)}
				builtOn={builtOn?.option_tags?.filter(tag=>tag.parameters.attribute === 7)?.map(tag => tag.parameters.value)}
			/>
			{/** gyerek cipo meret */}
			<SideOptionsBlock
				label={attributeListById[8].attribute_description}
				options={attributeListById[8].options}
				selectedOptions={searchOptionsByAttribute?.[8]}
				onOptionClick={(option) => toggleSearchOption(option)}
				builtOn={builtOn?.option_tags?.filter(tag=>tag.parameters.attribute === 8)?.map(tag => tag.parameters.value)}
			/>
			{/** férfi ruha meret */}
			<SideOptionsBlock
				label={attributeListById[10].attribute_description}
				options={attributeListById[10].options}
				selectedOptions={searchOptionsByAttribute?.[10]}
				onOptionClick={(option) => toggleSearchOption(option)}
				builtOn={builtOn?.option_tags?.filter(tag=>tag.parameters.attribute === 10)?.map(tag => tag.parameters.value)}
			/>
			{/** férfi cipo meret */}
			<SideOptionsBlock
				label={attributeListById[6].attribute_description}
				options={attributeListById[6].options}
				selectedOptions={searchOptionsByAttribute?.[6]}
				onOptionClick={(option) => toggleSearchOption(option)}
				builtOn={builtOn?.option_tags?.filter(tag=>tag.parameters.attribute === 6)?.map(tag => tag.parameters.value)}
			/>
		</>
	);
}

const SideOptionsBlock = ({
	label, options, selectedOptions, onOptionClick, builtOn
}) => {
	const collapsedSize = 5;
	let disabled = false;

	const [collapsed, setCollapsed] = React.useState( options.length > collapsedSize );
	const unfold = React.useCallback(() => setCollapsed(false),[]);

	if (Array.isArray(builtOn) && builtOn.length > 0)
	{
		selectedOptions = [ ...builtOn ];
		disabled = true;
	}

	options.sort((a,b) => selectedOptions?.includes(a.option_id) ? -1 : ( selectedOptions?.includes(b.option_id) ? 1 : 0 ));

	if ( collapsed ) {
		options = options.slice(0,5);
	}

	return (
		<Box sx={{p: 2, mb: 2}}>
			<Typography variant="body1" sx={{mb: 2, fontWeight: 700, "&:first-letter": { textTransform: "capitalize" },}}>{label}</Typography>
			<Box>
				{options?.map(option => (
					<Chip
						disabled={disabled}
						key={option.option_id}
						label={option.option_name}
						sx={{mr: 1, mb: 1, cursor: "pointer"}}
						onClick={() => onOptionClick(option)}
						variant={ selectedOptions?.includes(option.option_id) ? "filled" : "outlined" }
						color={ selectedOptions?.includes(option.option_id) ? "secondary" : undefined }
					/>
				))}
				{collapsed && (
					<Typography variant="body2" sx={theme=>({mb: 2, cursor: "pointer", color: theme.palette.grey.main2})} onClick={unfold}><L term="label.show_all" /> ›</Typography>
				)}
			</Box>
		</Box>
	);
}

const SearchTargetSelect = ({value, onChange}) => {
	return (
		<Select
			value={value}
			onChange={onChange}
			sx={theme=>({
				height: 53,
				"& .MuiOutlinedInput-notchedOutline": {
					borderRight: 'none',
					borderTopRightRadius: 0,
					borderBottomRightRadius: 0,
				},
				"&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline": {
					borderWidth: '1px',
					borderColor: theme.palette.grey.main6
				}
			})}
		>
			<MenuItem value="catalog"><L term='label.search-in.catalog' /></MenuItem>
			<MenuItem value="users"><L term='label.search-in.users' /></MenuItem>
		</Select>
	);
}

export default SearchLayout;

const SearchAutoComplete = ({
	open, setOpen,
	options, setOptions,
	setInputValue,
	error,
	searchTag, setSearchTag,
	inputProps,
	patternOption
}) => {
	return (
		<Autocomplete
			open={open}
			onOpen={()=>setOpen(true)}
			onClose={()=>setOpen(false)}
			getOptionLabel={option => option.type === "tag" ? option?.label : option.pattern}
			isOptionEqualToValue={(option,value) => value.label===option?.label}
			options={options}
			noOptionsText={""}
			value={searchTag}
			onChange={(event, newValue) => {
				setSearchTag(newValue);
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
				if (!newInputValue) {
					setOptions([]);
				}
			}}
			forcePopupIcon={false}
			renderInput={(params) => (
				<TextField
					type="text"
					error={error}
					autoComplete="new-password"
					fullWidth
					
					{...params}
					{...inputProps}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
						sx: theme => ({
							"& .MuiOutlinedInput-notchedOutline": {
								// borderLeft: 'none',
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0,
							},
							"&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline":{
								borderWidth: '1px',
								borderColor: theme.palette.grey.main6
							}
						})
					}}
				/>
			)}
			renderOption={(props, option) => {
				return (
					<li {...props } style={{lineHeight: '24px'}}>
						{`${option.label}`}
					</li>
				);
			}}
			filterOptions={(options)=>options}
			sx={{ flex: '1 1' }}
			ListboxProps={{sx: theme => ({border: `1px solid ${theme.palette.grey.main6}`, borderTop: "none", py: 0})}}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					setSearchTag(patternOption);
					setOpen(false);
				}
			}}
			componentsProps={{
				paper: {
					sx: {"& .MuiAutocomplete-noOptions": {display: 'none'}}
				}
			}}
		/>
	);
}
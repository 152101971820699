import { PROFILE } from '../constants';

const initialState = {
	setFavoriteIsPending: false,
	pending: false,
	products: undefined,
	pagination: undefined
};

const profile = (state=initialState, action) => {
	switch(action.type)
	{
		case PROFILE.SET_PROFILE_DATA:
			return {
				...action.data
			};
		
		case PROFILE.UPDATE_PROFILE_DATA:
			return {
				...state,
				...action.update
			};

		case PROFILE.CLEAR_PROFILE_DATA:
			return initialState;

		case PROFILE.SET_AVATAR:
			return {
				...state,
				avatar: action.avatar
			};
			
		case PROFILE.SET_FAVORITE:
			return {
				...state,
				favorite: action.favorite
			};

		case PROFILE.SET_FAVORITE_IS_PENDING:
			return {
				...state,
				setFavoriteIsPending: action.pending
			};
		
		case PROFILE.SET_PENDING:
			return {
				...state,
				pending: action.pending
			};
			
		case PROFILE.SET_USER_PRODUCTS:
			return {
				...state,
				products: [ ...( Array.isArray(state.products) ? state.products : [] ), ...action.products ],
				pagination: action.pagination,
			};
			
		case PROFILE.SET_FAVORITE_IN_PRODUCTLIST:
			return {
				...state,
				products: state.products?.map( product => product.product_id === action.product_id ? { ...product, favorite: action.favorite } : product )
			};
		
		default:
			return state;
	}
};

export default profile;
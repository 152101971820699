import React from 'react';
import {Link} from 'react-router-dom';
import { routeNames } from '../../../constants/uiRouteNames';
import {Box, Avatar, Typography, Skeleton, Checkbox} from '@mui/material';
import {srcSet1x2x} from '../../../utils/srcSet';
import ProductGridItem from './ProductGridItem';
import { priceFormat } from '../../../utils/numberFormat';
import FavoriteButton from './FavoriteButton';
import useStoreState from '../../../hooks/useStoreState';
import {ProductListContext} from '../../../contexts/ProductListContext';
import fallbackAvatar from '../../../assets/images/UZUZD-black-2row.png';

import bp from '../../../utils/BarionPixel';
import HighlightedFlag from './HighligthedFlag';
import { alpha } from '@mui/material/styles';
import ExtraIcon from '../../ExtraIcon';

const ProductListItem = ({user,product,ownProfile}) => {

	const primaryImage = product?.images?.find(image => image.primary === 1);
	const primaryAttribute = product?.attributes?.[0];
	const auth = useStoreState('auth');

	const ownProduct = product?.user.user_id === auth?.user?.user_id;

	return (
		<ProductListContext.Consumer>
		{({setIsFavorite,setFavoriteIsPending,selectItemEnabled,selectedItems,onToggleItemSelection,spot}) => {
			const isSelected = selectedItems?.includes(product?.product_id);
			
			const selectItem = (product_id, selected) => {
				onToggleItemSelection.apply(undefined, [product_id, selected])
			}

			const handleProfileLinkClick = ev => {
				if ( selectItemEnabled ) {
					ev.preventDefault();
					selectItem(product?.product_id, !isSelected );
				}
			}
			
			const handleProductLinkClick = ev => {
				if ( selectItemEnabled ) {
					ev.preventDefault();
					selectItem(product?.product_id, !isSelected );
				}
				// Barion Pixel <<<
				else
				{
					if ( [routeNames.dashboard,routeNames.catalog].includes(spot) )
					{
						const properties = {
							contentType: 'Page',
							currency: 'HUF',
							id: product.product_id,
							name: product.product_name,
							quantity: 1.0,
							unit: 'darab',
							unitPrice: product.product_price
						};
						
						bp.track('clickProduct', properties);
					}
				}
				// >>> Barion Pixel
			}

			return (
				<ProductGridItem>
					<Box
						sx={{
							cursor: "pointer",
							textDecoration: "none"
						}}
					>
						{/* avatar */}
						{!ownProfile && (
							<Box
								// component={Link}
								// to={`/${routeNames.profile}/${product?.user.user_id}`}
								sx={{
									display: "flex",
									flexFlow: "row nowrap",
									alignItems: "center",
									mb: 2
								}}
							>
								<Link onClick={handleProfileLinkClick} to={`/${routeNames.profile}/${product?.user.user_id}`} style={{textDecoration: "none"}} >
									<Avatar src={user.avatar?.xs || fallbackAvatar} sx={{width: 32, height: 32}}/>
								</Link>
								<Box sx={{display: "flex", alignItems: "center"}}>
									<Link onClick={handleProfileLinkClick} to={`/${routeNames.profile}/${product?.user.user_id}`} style={{textDecoration: "none"}} >
										<Typography variant="body2" color="grey.main2" sx={{ml: 3, fontWeight: 700}}>{user.name}</Typography>
									</Link>
									{user.extra && <ExtraIcon sx={{ml: 2}} />}
								</Box>
								{selectItemEnabled && (
									<Checkbox
										checked={isSelected}
										onClick={handleProductLinkClick}
										sx={{ml: "auto"}}
									/>
								)}
							</Box>
						)}
						{/* image */}
						<Box
							sx={theme=>({
								position: "relative",
								borderRadius: 2,
								overflow: "hidden",
								bgcolor: theme.palette.grey.main8,
								"&::before": {
									content: "''",
									display: "block",
									width: '100%',
									height: 0,
									pt: 'calc( 100% * 4/3 )'
								}
							})}
						>
							<Link onClick={handleProductLinkClick} to={`/${routeNames.products}/${product?.product_id}`} style={{textDecoration: "none"}} >
								{!ownProduct && setIsFavorite && (
									<FavoriteButton
										isFavorite={product?.favorite}
										setIsFavorite={() => setIsFavorite?.(product?.product_id)}
										disabled={setFavoriteIsPending}
									/>
								)}
								<img
									style={{
										width: '100%',
										height: '100%',
										position: "absolute",
										top: 0
									}}
									{...srcSet1x2x(primaryImage, ['sm','md'])} alt={`product-${product.product_id}`}
								/>
							</Link>
						</Box>
						{/* attributes */}
						<Box
							sx={theme=>({
								px: !!product?.highlighted ? 2 : undefined,
								pt: !!product?.highlighted ? 4 : 2,
								mt: !!product?.highlighted ? -2 : undefined,
								bgcolor: product?.highlighted ? alpha(theme.palette.primary.main,.5) : undefined,
								minHeight: !!product?.highlighted ? 104 : 96,
								boxSizing: "border-box"
							})}
						>
							<Link onClick={handleProductLinkClick} to={`/${routeNames.products}/${product?.product_id}`} style={{textDecoration: "none"}} >
								<Typography component="div" variant="body1" sx={{mb: 2}}>{product.product_name}</Typography>
								<Typography component="div" variant="body2" sx={{mb: 2}}>{primaryAttribute?.attribute_name}: {primaryAttribute?.option_name}</Typography>
								<Typography component="div" variant="body2" sx={{fontWeight: 700}}>{priceFormat(product.product_price)}</Typography>
							</Link>
						</Box>
					</Box>
					{!!product?.highlighted && (
						<HighlightedFlag
							sx={{
								position: "absolute",
								top: {xs: 56, sm: 56, md: 64},
								right: {xs: 8, sm: 8, md: 16}
							}}
						/>
					)}
				</ProductGridItem>
			);
		}}
		</ProductListContext.Consumer>
	);
}

export default ProductListItem;

export const ProductListItemSkeleton = () => {
	return (
		<ProductGridItem>
			<Box
					sx={{
						cursor: "pointer",
						textDecoration: "none"
					}}
				>
					<Box
						
						sx={{
							display: "flex",
							flexFlow: "row nowrap",
							alignItems: "center",
							mb: 2
						}}
					>
						<Skeleton variant="circular" width={32} height={32} />
						<Typography variant="body2" color="grey.main2" sx={{ml: 3, fontWeight: 700, width: 50}}><Skeleton /></Typography>
					</Box>
					
					<Box
						sx={{
							position: "relative",
							borderRadius: 2,
							overflow: "hidden",
							"&::before": {
								content: "''",
								display: "block",
								width: '100%',
								height: 0,
								pt: 'calc( 100% * 4/3 )'
							}
						}}
					>
						<Skeleton variant="rectangular" width="100%" sx={{
							width: '100%',
							height: '100%',
							position: "absolute",
							top: 0
						}}/>
					</Box>
					<Box
						sx={{
							mt: 2
						}}
					>
						<Typography component="div" variant="body1" sx={{mb: 2, width: '70%'}}><Skeleton /></Typography>
						<Typography component="div" variant="body2" sx={{mb: 2, width: '90%'}}><Skeleton /></Typography>
						<Typography component="div" variant="body2" sx={{fontWeight: 700, width: '50%'}}><Skeleton /></Typography>
					</Box>
				</Box>
		</ProductGridItem>
	);
};
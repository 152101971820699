import React from 'react';
import {IconButton} from '@mui/material';
import {ReactComponent as HeartIcon} from '../../../assets/icons/heart.svg';
import {ReactComponent as HeartFilledIcon} from '../../../assets/icons/heart-filled.svg';

const FavoriteButton = ({sx, isFavorite, setIsFavorite, disabled}) => {
	return (
		<IconButton
			disabled={disabled}
			sx={[theme => ({
				position: "absolute",
				right: 8,
				bottom: 8,
				zIndex: 1,
				bgcolor: isFavorite ? theme.palette.black.main : theme.palette.primary.main3,
				p: '5px',
				fill: isFavorite ? theme.palette.white.main : theme.palette.black.main,
				"&:hover": {
					bgcolor: isFavorite ? theme.palette.grey.main : theme.palette.primary.main4,
				},
				"&:active": {
					bgcolor: isFavorite ? theme.palette.secondary.main : theme.palette.primary.main,
				},
			}),sx]}
			onClick={ev => {
				ev.stopPropagation()
				ev.preventDefault()
				setIsFavorite.call();
			}}
		>
			{isFavorite && <HeartFilledIcon width="14" height="14" viewBox="0 0 24 24"/>}
			{!isFavorite && <HeartIcon width="14" height="14" viewBox="0 0 24 24"/>}
		</IconButton>
	);
}

export default FavoriteButton;
import { AUTH, PROFILE } from '../constants';

const INITIAL_STATE = {
	pending: false,
	isSignedIn: false,
	user: null
};

const auth = (state=INITIAL_STATE, action) => {
	switch(action.type)
	{
		case AUTH.LOGGING_OUT:
			return {
				...state,
				pending: true
			};

		case AUTH.AUTHENTICATED:
			return {
				...state,
				pending: false,
				isSignedIn: true,
				user: action.user
			};

		case AUTH.UNAUTHENTICATED:
			return {
				...state,
				pending: false,
				isSignedIn: false,
				user: null
			};

		case PROFILE.AVATAR_CHANGED:
			return {
				...state,
				user: { ...state.user, avatar: action.avatar }
			};

		default:
			return state;
	}
};

export default auth;
import { RESPONSE, STATIC_TEXTS } from '../constants';
import API from '../apis/uzuzdApi';
import { APP as URLS } from '../constants/urls';

export const getContent = ({kind}) => async dispatch =>
{
	try
	{
		const response = await new API().get(`${URLS[kind]}`);
		dispatch({ type: STATIC_TEXTS.SET_CONTENT, kind, content: response.data });
	}
	catch(error)
	{
		dispatch({ type: RESPONSE.SET_ERROR, error });
	}
};

export const clearContent = ({kind}) => ({ type: STATIC_TEXTS.SET_CONTENT, kind, content: undefined });
import React from 'react';
import { Box } from '@mui/material';
import L from '../../Helpers/Lang';

const HighlightedFlag = ({sx,label}) =>
{
	return (
		<Box
			component="span"
			sx={[theme=>({
				bgcolor: theme.palette.warning.main,
				color: theme.palette.white.main,
				textTransform: "uppercase",
				px: 1,
				py: '2px',
				borderRadius: 2,
				fontWeight: 700,
				whiteSpace: "nowrap"
			}), sx]}
		>
			<L term={ label || `label.highlighted`} />
		</Box>
	);
}

export default HighlightedFlag;
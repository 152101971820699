import React from 'react';
import { Tooltip, Box } from '@mui/material';
import L from './Helpers/Lang';
import { ReactComponent as ExtraIconSvg } from '../assets/icons/extra-final.svg';
import { Link } from 'react-router-dom';
import { routeNames } from '../constants/uiRouteNames';

const ExtraIcon = ({size=24, sx, iconOnly=false}) => {
	return (
		<Box sx={[{display: "flex"}, sx]} component={iconOnly ? "div" : Link} to={`/${routeNames.halfPriceDelivery}`}>
			<Tooltip title={iconOnly ? undefined : <L term="label.extra" />}>
				<ExtraIconSvg width={size} height={size} viewBox="0 0 24 24" />
			</Tooltip>
		</Box>
	);
}

export default ExtraIcon;
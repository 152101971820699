import { SEARCH } from '../constants';

const initialState = {
	productList: undefined,
	userList: undefined,
	pagination: undefined,
	setFavoriteIsPending: false,
	searchSizeAttributes: undefined,
	builtOn: undefined
};

const search = (state=initialState, action) => {
	switch(action.type)
	{
		case SEARCH.SET_SEARCH_SIZE_ATTRIBUTES:
			return {
					...state,
					searchSizeAttributes: action.searchSizeAttributes
			};

		case SEARCH.CLEAR_PRODUCTLIST:
			return {
				...state,
				productList: initialState.productList,
				pagination: initialState.pagination,
				builtOn: initialState.builtOn
			};
			
		// case CATALOG.CLEAR_PAGINATION:
		// 	return {
		// 		...state,
		// 		pagination: initialState.pagination
		// 	};

		case SEARCH.SET_PRODUCTLIST:
			return {
				...state,
				// productList: action.pagination.currentPage === 1 ? action.productList : [ ...state.productList, ...action.productList ],
				productList: [ ...( Array.isArray(state.productList) ? state.productList : [] ), ...action.productList ],
				pagination: action.pagination,
				builtOn: action.builtOn
			};

		case SEARCH.SET_FAVORITE_IS_PENDING:
			return {
				...state,
				setFavoriteIsPending: action.pending
			}
		case SEARCH.SET_FAVORITE_TO_PRODUCT_LIST_ITEM:
			return {
				...state,
				productList: state.productList.map( product => product.product_id === action.product_id ? { ...product, favorite: action.favorite } : product )
			}
		case SEARCH.SET_USERLIST:
			return {
				...state,
				userList: action.userList
			};

		default:
			return state;
	}
};

export default search;
import React from 'react';
import {useDispatch} from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import L from '../../Helpers/Lang';
import {usePost} from '../../../hooks/useApi';

import {AUTH as URLS} from '../../../constants/urls';
import {AUTH, CART} from '../../../constants';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings.svg';
import { ReactComponent as ListIcon } from '../../../assets/icons/list.svg';
import { ReactComponent as PackageIcon } from '../../../assets/icons/mdi/gift-outline.svg';
// import { ReactComponent as ExtraIcon } from '../../../assets/icons/extra.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/log-out.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
// import { ReactComponent as BagIcon } from '../../../assets/icons/shopping-bag.svg';
import { ReactComponent as HeartIcon } from '../../../assets/icons/heart.svg';
import { routeNames } from '../../../constants/uiRouteNames';
import useStoreState from '../../../hooks/useStoreState';

const SettingsMenu = ({origin,handleClose}) =>
{
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [ logout, {isSuccess, isDone} ] = usePost(URLS.logout);
	const { user: { user_id: authUserId } } = useStoreState('auth');
	

	React.useEffect(() => {
		if ( isDone && isSuccess )
		{
			dispatch({ type: AUTH.UNAUTHENTICATED });
			dispatch({ type: CART.CLEAR_CART });
			handleClose?.call();
			navigate( routeNames.root )
		}
	}, [ isDone, isSuccess, dispatch, navigate, handleClose ]);

	const menuItemProps = {
		sx: theme => ({
			fill: theme.palette.grey.main4,
			"&:hover": {
				bgcolor: theme.palette.primary.main4
			},
			"&.Mui-selected": {
				backgroundColor: theme.palette.white.main,
			},
			"&.Mui-selected .MuiListItemIcon-root": {
				fill: theme.palette.black.main
			},
			"&.Mui-selected .MuiListItemText-root .MuiTypography-root": {
				color: theme.palette.black.main,
				fontWeight: 700,
			},
			"& .MuiListItemText-root .MuiTypography-root": {
				fontSize: origin === 'mobile-profile-menu' ? { xs: '1rem', sm: '2rem', md: 0 } : null
			},
			"& .MuiListItemIcon-root": {
				transform: origin === 'mobile-profile-menu' ? { xs: "none", sm: "scale(1.4)", md: "none" } : null
			},
			my: origin === 'mobile-profile-menu' ? { xs: 0, sm: 8, md: 0 } : null,
		})
	};

	const menuItems = [
		{
			icon: <UserIcon />,
			text: "nav.profile",
			component: Link,
			to: `/${routeNames.profile}${ authUserId ? '/' + authUserId : '' }`,
			onClick: handleClose,
		},
		// {
		// 	icon: <MessageIcon />,
		// 	text: "nav.messages",
		// 	component: Link,
		// 	to: `/${routeNames.messages}`,
		// 	onClick: handleClose,
		// },
		{
			icon: <PackageIcon />,
			text: "nav.my_orders",
			component: Link,
			to: `/${routeNames.settings}/${routeNames.orders}/${routeNames.in}`,
			onClick: handleClose,
		},
		{
			icon: <ListIcon />,
			text: "nav.my_products",
			component: Link,
			to: `/${routeNames.settings}/${routeNames.products}`,
			onClick: handleClose,
		},
		{
			icon: <HeartIcon />,
			text: "nav.my_favorites",
			component: Link,
			to: `/${routeNames.favorites}`,
			onClick: handleClose,
		},
		{
			icon: <SettingsIcon />,
			text: "nav.settings",
			component: Link,
			to: `/${routeNames.settings}`,
			onClick: handleClose,
		},
		// {
		// 	icon: <ExtraIcon />,
		// 	text: "nav.gonna_be_extra",
		// 	component: Link,
		// 	to: `/${routeNames.settings}/${routeNames.extra}`,
		// 	onClick: handleClose,
		// },
		{
			icon: <LogoutIcon />,
			text: "nav.logout",
			to: `/${routeNames.settings}/${routeNames.logout}`,
			onClick: () => {
				logout.call();
			}
		},
	];
	
	return (
		<>
			{menuItems.map(({icon,text, to, ...rest},i) => {
				return (
					<MenuItem
						key={i}
						to={to}
						{...rest}
						{...menuItemProps}
						selected={(to===location?.pathname) || (
							to===`/${routeNames.settingsOrders}` && location?.pathname?.includes(to)
						)}
					>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primaryTypographyProps={{variant: "subtitle"}} >
							<L term={text} />
						</ListItemText>
					</MenuItem>
				);
			})}
		</>
	);
}

export default SettingsMenu;
import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialog({
	open,
	title,
	content,
	buttons,
	onClose,
	onBackdropClick,
	severity
}) {
	const getDlgSx = theme => {

		switch( severity )
		{
			case 'error':
				return {
					bgcolor: alpha(theme.palette.error.main, .4)
				}
			case 'warning':
				return {
					bgcolor: alpha(theme.palette.warning.main, .4)
				}
			case 'success':
				return {
					bgcolor: alpha(theme.palette.success.main, .4)
				}
			case 'info':
				return {
					bgcolor: alpha(theme.palette.info.main, .4)
				}
			default:
				return {};
		}
	}

  return (
		<Dialog
			open={open}
			onClose={onClose}
			TransitionComponent={Transition}
			fullWidth
			onBackdropClick={onBackdropClick}
      maxWidth="sm"
			sx={getDlgSx}
		>
			{title && (
				<DialogTitle
					sx={theme=>({
						display: "flex",
						alignItems: "center",
						fill: theme.palette.grey.main2
					})}
				>
					<Box sx={{ mr: "auto" }} >{title}</Box>
					{onClose ? (
						<IconButton onClick={onClose} >
							<CloseIcon />
						</IconButton>
					) : null}
				</DialogTitle>
			)}
			{content && <DialogContent>
				{/* <DialogContentText> */}
					{content}
				{/* </DialogContentText> */}
			</DialogContent>}
			{Array.isArray(buttons) && <DialogActions>
				{buttons.map(({label, ...other}, index) => (
					<Button key={index} {...other}>{label}</Button>
				))}
			</DialogActions>}
		</Dialog>
  );
}
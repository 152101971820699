import isNumeric from "validator/es/lib/isNumeric";

export const priceFormat = (price, currency = 'HUF') => {
	
	if ( !(typeof(price) === "number" || ( typeof price === "string" && isNumeric(price) )) ){
		return '';
	}

	return new Intl.NumberFormat('hu-HU',
		{
			style: 'currency',
			currency,
			minimumFractionDigits:0
		}).format(price);
}

export const accountNumberFormat = (account_number, delimiter = '-') =>
{
	account_number = account_number.replace(/[^0-9]/gi, '');
	account_number = account_number.substr(0,24);

	if ( account_number.length > 8 ) {
		account_number = `${account_number.substr(0,8)}${delimiter}${account_number.substr(8)}`;
	}
	
	if ( account_number.length > 16 + delimiter.length ) {
		account_number = `${account_number.substr(0,16 + delimiter.length)}${delimiter}${account_number.substr(16 + delimiter.length)}`;
	}

	return account_number;
}

export const phoneNumberFormat = (phone_number, delimiter = ' ') =>
{
	phone_number = phone_number.replace(/[^0-9]/gi, '');
	phone_number = phone_number.substr(0,11);

	if ( phone_number.length > 2 ) {
		phone_number = `${phone_number.substr(0,2)}${delimiter}${phone_number.substr(2)}`;
	}
	
	if ( phone_number.length > 4 + delimiter.length ) {
		phone_number = `${phone_number.substr(0,4 + delimiter.length)}${delimiter}${phone_number.substr(4 + delimiter.length)}`;
	}

	return `+${phone_number}`;
}
import React from 'react';
import {useParams} from 'react-router-dom';
import useStoreState from '../hooks/useStoreState';

const getSearchSizeRoute = (pattern) => {
	return pattern?.split('/') || [];
}

export const useSearchSizeRouteMatch = () =>
{
	const {searchSizeAttributes} = useStoreState('search');
	const searchSizeAttributesBySlug = React.useMemo( () => searchSizeAttributes?.reduce((byName,attribute) => ({ ...byName, [ attribute.slug ]: attribute }),{}),[searchSizeAttributes]);

	const params = useParams();
	const [ attributeSlug, optionSlug ] = getSearchSizeRoute(params['*']);

	const attribute = searchSizeAttributesBySlug?.[ attributeSlug ];
	const option = attribute?.options?.find( option => option.slug === optionSlug );

	return {
		attribute,
		option
	};
}
import React from 'react';
import useStoreState from '../../../hooks/useStoreState';
import {Box, MenuItem, Typography} from "@mui/material";
import {Link} from 'react-router-dom';
import L from '../../Helpers/Lang';
import { routeNames } from '../../../constants/uiRouteNames';
import {CategoryContext} from '../../../contexts/CategoryContext';
import { Spots } from '../../../constants';
import { ReactComponent as ChevronRightIcon } from '../../../assets/icons/chevron-right.svg';
import { ReactComponent as GridIcon } from '../../../assets/icons/grid.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/icons/minus.svg';
import { categoryLevelItemConfig } from './categoryLevelItemConfig';

const CategoryLevel = ({
	parent,
	selectedCategoryIdChain,
	closeMenu,
	level,
	selectedCategoryWasInList,
}) =>
{
	const { categoryListById } = useStoreState('catalog');
	const categoryList = parent?.children;

	const selectedCategoryIdFromChain = selectedCategoryIdChain[0];
	const [ selectedCategoryId, setSelectedCategoryId ] = React.useState( selectedCategoryIdFromChain );
	const selectedCategory = selectedCategoryId ? categoryListById[ selectedCategoryId ] : null;
	const selectedCategoryIsInList = Boolean( categoryList?.find( category => category.category_id === selectedCategoryId ) );

	const showAllSelected = selectedCategoryWasInList && !selectedCategoryId;

	React.useEffect(() => {
		setSelectedCategoryId(selectedCategoryIdFromChain);
	},[selectedCategoryIdFromChain]);

	if (!categoryList) {
		return null;
	}

	const buildLinkUrl = (slugChain, spot) => {
		if ( !Array.isArray(slugChain) ) {
			slugChain = [];
		}

		return spot === Spots.CategoryLevel.Catalog
			? slugChain.join('/')
			: [ routeNames.catalog, ...slugChain ].join('/');
	}

	return (
		<CategoryContext.Consumer>
		{({spot}) => (
			<>
				<Box
					sx={{
						display: "flex",
						flexFlow: "column wrap",
						pl: [ Spots.CategoryLevel.Catalog, Spots.CategoryLevel.MobileCatalog ].includes( spot ) ? level * 4 : 0
					}}
				>
					<MenuItem
						onClick={() => { setSelectedCategoryId(null); closeMenu?.(); } }
						component={ Link }
						to={ buildLinkUrl( parent.slug_chain, spot ) }
						sx={theme=>({
							fill: showAllSelected ? theme.palette.black.main : theme.palette.grey.main2,
							ml: spot !== Spots.CategoryLevel.MobileCatalog ? -4 : 0,
							display: spot === Spots.CategoryLevel.MobileCatalog && level === 0 ? "none" : "flex",
						})}
					>
						{ spot !== Spots.CategoryLevel.MobileCatalog && <GridIcon width="16" height="16" viewBox="0 0 24 24"/>}
						<Typography
							variant={categoryLevelItemConfig[spot]?.[level]?.typography.variant}
							sx={theme=>({
								fontWeight: showAllSelected ? 700 : 400,
								color: showAllSelected ? theme.palette.black.main : theme.palette.grey.main2,
								...categoryLevelItemConfig[spot]?.[level]?.typography.sx
							})}
						>
							<L term="nav.show_all"/>
						</Typography>
					</MenuItem>
					{categoryList?.map(category => {

						const selected = category.category_id === selectedCategoryId;

						if ( !category.children ) {
							category.children = categoryListById[ category.category_id ]?.children;
						}
						const categoryHasChildren = Boolean(category?.children)

						return (
							<Box key={category.category_id}>
								<MenuItem
									key={category.category_id}
									component={ !categoryHasChildren ? Link : undefined }
									to={ !categoryHasChildren ? buildLinkUrl( category.slug_chain, spot ) : undefined }
									onClick={() => {
										if ( !selected ) { setSelectedCategoryId(category.category_id) }; 
										if ( [ Spots.CategoryLevel.CatalogTabs, Spots.CategoryLevel.MobileCatalog ].includes( spot ) && !categoryHasChildren ) {
											closeMenu?.()
										}
										
										if ( selected && [ Spots.CategoryLevel.MobileCatalog ].includes( spot ) && categoryHasChildren ) {
											setSelectedCategoryId(null);
										}
									}}
									sx={[{
										// display: selected ? "inline-flex" : "flex",
										ml: ( !categoryHasChildren && selected ) ? -4 : undefined
										},
										categoryLevelItemConfig[spot]?.[level]?.menuItem?.sx
									]}
								>
									{( !categoryHasChildren && selected ) && <ChevronRightIcon width="16" height="16" viewBox="0 0 24 24"/>}
									
										<Typography
											variant={categoryLevelItemConfig[spot]?.[level]?.typography.variant}
											sx={{
												fontWeight: selected ? 700 : 400,
												mr: "auto",
												...categoryLevelItemConfig[spot]?.[level]?.typography.sx,
												'::first-letter': {
													textTransform: 'uppercase'
												}
											}}
										>
											{category.category_name}
										</Typography>
										<Box
											sx={theme=>({
												display: "flex",
												fill: selected ? theme.palette.black.main : theme.palette.grey.main4
											})}
										>
											{ ( spot === Spots.CategoryLevel.MobileCatalog && categoryHasChildren ) && (
												<>
													{ selected ? <MinusIcon width="20" height="20" viewBox="0 0 24 24" /> : <PlusIcon width="20" height="20" viewBox="0 0 24 24"/> }
												</>
											) }
										</Box>
								</MenuItem>
								{([ Spots.CategoryLevel.Catalog, Spots.CategoryLevel.MobileCatalog ].includes( spot ) && selected ) && (
									<CategoryLevel
										level={level+1}
										parent={category}
										closeMenu={closeMenu}
										selectedCategoryIdChain={selectedCategoryIdChain.slice(1)}
										selectedCategoryWasInList={selectedCategoryIsInList}
									/>
								)}
							</Box>
						);
					})}
				</Box>
				{(spot === Spots.CategoryLevel.CatalogTabs && selectedCategoryIsInList) && (
					<CategoryLevel
						level={level+1}
						parent={selectedCategory}
						closeMenu={closeMenu}
						selectedCategoryIdChain={selectedCategoryIdChain.slice(1)}
						selectedCategoryWasInList={selectedCategoryIsInList}
					/>
				)}
			</>
		)}
		</CategoryContext.Consumer>
	);
}

export default CategoryLevel;
import { DASHBOARD } from '../constants';

const initialState = {
	productList: null,
	pagination: null,
	setFavoriteIsPending: false,
	content: undefined
}

const config = (state = initialState, action) => {
	switch(action.type)
	{
		case DASHBOARD.SET_PRODUCT_LIST:
			return {
				...state,
				productList: action.products,
				pagination: action.pagination,
			};
		case DASHBOARD.CLEAR_PRODUCT_LIST:
			return {
				...state,
				productList: initialState.productList
			};
		case DASHBOARD.CLEAR_DASHBOARD_DATA:
			return initialState;

		case DASHBOARD.SET_FAVORITE_IS_PENDING:
			return {
				...state,
				setFavoriteIsPending: action.pending
			}
		case DASHBOARD.SET_FAVORITE_TO_PRODUCT_LIST_ITEM:
			return {
				...state,
				productList: state.productList.map( product => product.product_id === action.product_id ? { ...product, favorite: action.favorite } : product )
			}
		case DASHBOARD.CLEAR_CONTENT:
			return {
				...state,
				content: initialState.content
			}
		case DASHBOARD.SET_CONTENT:
			return {
				...state,
				content: action.content
			}
		default:
			return state;
	}
};

export default config;
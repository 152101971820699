import React from 'react';
import {Grid} from '@mui/material';
import {ProductListContext} from '../../../contexts/ProductListContext';
import { routeNames } from '../../../constants/uiRouteNames';

const ProductGridItem = ({children,sx}) => {

	return (
		<ProductListContext.Consumer>
			{({spot}) => {
				let gridParameters = {};
				switch( spot ) {
					case routeNames.dashboard:
						gridParameters = { xs: 6, sm: 3, md: 2 }
						break;
					default:
						gridParameters = { xs: 6, sm: 4, md: 3 }
						break;
				}

				return (
					<Grid item {...gridParameters} /*xs={6} sm={4} md={3}*/
						sx={[{
							position: "relative",
							mb: 4
						},sx]}
					>
					{children}
				</Grid>)}
			}
		</ProductListContext.Consumer>
	);
}

export default ProductGridItem;
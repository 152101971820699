import { CATALOG } from '../constants';

const initialState = {
	categoryListById: undefined,
	attributeListById: undefined,
	// categoryMap: undefined,
	productList: undefined,
	pagination: undefined,
	setFavoriteIsPending: false
};

const catalog = (state=initialState, action) => {
	switch(action.type)
	{
		case CATALOG.SET_CATEGORIES:
			return {
				...state,
				categoryListById: action.categories
			};
		case CATALOG.SET_ATTRIBUTES:
			return {
					...state,
					attributeListById: action.attributes
			};

		// case CATALOG.SET_CATEGORYMAP:
		// 		return {
		// 				...state,
		// 				categoryMap: action.category_map
		// 		};

		case CATALOG.CLEAR_CATALOG:
			return initialState;
			
		case CATALOG.CLEAR_PRODUCTLIST:
			return {
				...state,
				productList: initialState.productList,
				pagination: initialState.pagination
			};
			
		// case CATALOG.CLEAR_PAGINATION:
		// 	return {
		// 		...state,
		// 		pagination: initialState.pagination
		// 	};

		case CATALOG.SET_PRODUCTLIST:
			return { // action.products, action.category_id
				...state,
				// productList: action.pagination.currentPage === 1 ? action.productList : [ ...state.productList, ...action.productList ],
				productList: [ ...( Array.isArray(state.productList) ? state.productList : [] ), ...action.productList ],
				pagination: action.pagination,
			};

		case CATALOG.SET_FAVORITE_IS_PENDING:
			return {
				...state,
				setFavoriteIsPending: action.pending
			}
		case CATALOG.SET_FAVORITE_TO_PRODUCT_LIST_ITEM:
			return {
				...state,
				productList: state.productList.map( product => product.product_id === action.product_id ? { ...product, favorite: action.favorite } : product )
			}

		default:
			return state;
	}
};

export default catalog;
import { Spots } from "../../../constants";

export const searchSizeAttributeLevelItemConfig = {
	[Spots.SearchSizeLevel.SearchSize]: {
		0: { // level
			typography: {
				variant: "menu1",
				sx: {
					"&:first-letter": {
						textTransform: "capitalize"
					},
					// textTransform: "uppercase",
				}
			}
		},
		1: {
			typography: {
				variant: "body1",
				sx: {
					"&:first-letter": {
						textTransform: "capitalize"
					}
				}
			}
		}
	},
	[Spots.SearchSizeLevel.CatalogTabs]: {
		0: { // level
			typography: {
				variant: "subtitle1",
				sx: {
					"&:first-letter": {
						textTransform: "capitalize"
					},
					// textTransform: "uppercase"
				}
			}
		},
		1: {
			typography: {
				variant: "body1",
				sx: {
					"&:first-letter": {
						textTransform: "capitalize"
					}
				}
			}
		}
	},
	[Spots.SearchSizeLevel.MobileSearchSize]: {
		0: {
			typography: {
				variant: "subtitle1",
				sx: {
					textTransform: "uppercase"
				}
			},
			menuItem: {
				sx: theme => ({
					height: 56,
					borderBottom: `1px solid ${theme.palette.grey.main7}`
				})
			}
		},
		1: {
			typography: {
				variant: "subtitle1",
				sx: {
					"&:first-letter": {
						textTransform: "capitalize"
					}
				}
			}
		}
	}
}
export const categoryPropFromTree = ( categories ) => ( propName ) => ( category ) =>
{
	if ( !categories ) { return undefined; }
	if ( !category ) { return undefined; }

	const parentId = categories[category.category_id]?.parent_category_id;

	if ( parentId )
	{
		return [
			...categoryPropFromTree(categories)(propName)(categories[parentId]),
			...(
				propName
				? Array.isArray(category[ propName ]) ? category[ propName ] : [ category[ propName ] ]
				: [ category ]
			)
		];
	}
	else {
		return propName
			? Array.isArray(category[ propName ]) ? category[ propName ] : [ category[ propName ] ]
			: [category]
	}
};

export const topLevelCategories = (categoryListById) => Object.entries(categoryListById).reduce((acc, [ category_id, category ]) => {
	return !!category.parent_category_id ? acc : [ ...acc, category ];
},[])
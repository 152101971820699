import { SEARCH, RESPONSE } from '../constants';
import API from '../apis/uzuzdApi';
import { APP } from '../constants/urls';
import { paginationFromResponse } from '../utils';

export const toggleFavoriteProduct = (product_id) => async dispatch =>
{
	try
	{
		dispatch({ type: SEARCH.SET_FAVORITE_IS_PENDING, pending: true });
		const response = await new API().post( `${APP.favoriteProduct}`, { product_id } );
		dispatch({ type: SEARCH.SET_FAVORITE_IS_PENDING, pending: false });
		dispatch({ type: SEARCH.SET_FAVORITE_TO_PRODUCT_LIST_ITEM, product_id, favorite: response.data?.favorite });
	}
	catch(error)
	{
		dispatch({ type: RESPONSE.SET_ERROR, error });
		dispatch({ type: SEARCH.SET_FAVORITE_IS_PENDING, pending: false });
	}
}

export const searchProducts = (params) => async dispatch =>
{
	try
	{
		if ( params.filter ) {
			params.filter = JSON.stringify( params.filter );
		}

		const response = await new API().get( `${APP.products}`, { params } );
		dispatch({
			type: SEARCH.SET_PRODUCTLIST,
			productList: response.data?.data,
			pagination: paginationFromResponse(response.data),
			builtOn: response.data?.built_on
		});
	}
	catch(error)
	{
		dispatch({ type: RESPONSE.SET_ERROR, error });
	}
};

export const clearProductListAction = () => ({ type: SEARCH.CLEAR_PRODUCTLIST })

export const searchUsers = (params) => async dispatch =>
{
	try
	{
		const response = await new API().get( `${APP.searchUsers}`, { params } );

		dispatch({
			type: SEARCH.SET_USERLIST,
			userList: response.data
		});
	}
	catch(error)
	{
		dispatch({ type: RESPONSE.SET_ERROR, error });
	}
};

export const clearUserListAction = () => ({ type: SEARCH.CLEAR_USERLIST })
import React from 'react';
import { useLocation, useLinkClickHandler } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import homeLink from '../constants/urls';

const Forbidden = (props) => {
	const {state} = useLocation();
	const from = state?.from?.pathname;
	const handleClick = useLinkClickHandler( homeLink )

	return (
		<Box sx={{pt: 60, textAlign: "center", cursor: "pointer"}} onClick={handleClick}>
			<Typography align="center" variant="h1" color="error" sx={{fontWeight: 700}}>403</Typography>
			{from && <Typography align="center" variant="h4">{from}</Typography>}
		</Box>
	);
}
export default Forbidden;
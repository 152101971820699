import { useState, useEffect } from 'react'

export const useStepper = (steps, initialStep, delay) =>
{
	const [step, setStep] = useState(initialStep);

	useEffect(()=>{
		const timeoutId = setTimeout(()=>{
			
			setStep( (step + 1 ) % steps )
		// }, step === 0 ? 0 : delay);
		}, delay);

		return () => clearTimeout(timeoutId);
	}, [step, steps, delay]);

	return [step, setStep];
}
import React from 'react';
import {Paper, Typography, Box, Button} from '@mui/material';
import Modal from './Modal';
import {alpha} from '@mui/material/styles';
import bp from '../utils/BarionPixel';

const key = 'UZUZD_cc';

const CookieConsent = (props) =>
{
	const [ cookiePreferencesWasSet, setCookiePreferencesWasSet ] = React.useState( localStorage.getItem( key ) );

	if ( cookiePreferencesWasSet ) {
		return null;
	}

	const setDone = (action) => {
		const json = JSON.stringify({action, at: new Date().getTime()});
		localStorage.setItem( key, json );
		setCookiePreferencesWasSet(json);
	};

	const handleApproveAll = () => {
		setDone('approved');
		bp.grantConsent();
	};

	const handleDenyAll = () => {
		setDone('denied');
		bp.rejectConsent();
	};

	return (
		<Modal
			backdropSxProp={theme=>({
				bgcolor: alpha(theme.palette.grey.main6),
				flexDirection: "column",
				justifyContent: {xs: "center", md: "flex-end"},
				zIndex: 2
			})}
			containedMaxWidth="lg"
			forceNoPadding
		>
			<Paper
				sx={theme=>({
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
					m: 2,
					p: 4,
					[theme.breakpoints.down('md')]: {
						// mb: 22,
					}
				})}
			>
				<Box >
					<Typography variant="h4" sx={{mb: {xs:2, sm: 4}}}>Az Ön adatainak védelme fontos a számunkra</Typography>
					<Typography variant="p">
					Mi és a partnereink információkat – például sütiket – tárolunk egy eszközön vagy hozzáférünk az eszközön tárolt információkhoz, és személyes adatokat kezelünk személyre szabott hirdetések és tartalom nyújtásához, hirdetés- és tartalomméréshez, nézettségi adatok gyűjtéséhez, valamint termékek kifejlesztéséhez és a termékek javításához. Az Ön engedélyével mi és a partnereink eszközleolvasásos módszerrel szerzett pontos geolokációs adatokat és azonosítási információkat is felhasználhatunk. A megfelelő helyre kattintva hozzájárulhat ahhoz, hogy mi és a partnereink a fent leírtak szerint adatkezelést végezzünk.
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: { xs: "row", md: "column"},
						ml: "auto",
						pl: {xs: 0, md: 4},
						mt: { xs: 2, sm: 4, md: 0 },
						justifyContent: "center",
						alignItems: "flex-end",
						"@media(max-width: 428px)": {
							flexDirection: "column",
							alignItems: "center"
						}
					}}
				>
					<Button
						sx={{
							minWidth: 184,
							mb: {xs: 0, md: 4},
							order: { xs: 1, md: 0}
						}}
						variant="contained" color="secondary"
						onClick={handleApproveAll}
						>Összes elfogadása</Button>
					<Button
						sx={{
							minWidth: 184,
							mr: {xs: 4, md: 0},
							order: {xs: 0, md: 1},
							"@media(max-width: 428px)": {
								mr: 0,
								mb: 2
							}
						}}
						variant="outlined" color="secondary"
						onClick={handleDenyAll}
					>Összes elutasítása</Button>
				</Box>
			</Paper>
		</Modal>
	);
}

export default CookieConsent;
import { routeNames } from './uiRouteNames';

export * from './errors';
export * from './orderOptions';

export const INIT = {
	INITIALIZING: 'INIT@INITIALIZING',
	INITIALIZED: 'INIT@INITIALIZED',
	UNINITIALIZED: 'INIT@UNINITIALIZED',
};

export const AUTH = {
	SET_CSRF_COOKIE: 'AUTH@SET_CSRF_COOKIE',
	LOGGED_IN: 'AUTH@LOGGED_IN',
	AUTHENTICATED: 'AUTH@AUTHENTICATED',
	UNAUTHENTICATED: 'AUTH@UNAUTHENTICATED',
	SIGNED_UP: 'AUTH@SIGNED_UP',
	LOGGED_OUT: 'AUTH@LOGGED_OUT',
};

export const APP = {
	SHOW_LOGIN_FORM: 'APP@SHOW_LOGIN_FORM',
	HIDE_LOGIN_FORM: 'APP@HIDE_LOGIN_FORM',
	SHOW_SIGNUP_FORM: 'APP@SHOW_SIGNUP_FORM',
	HIDE_SIGNUP_FORM: 'APP@HIDE_SIGNUP_FORM',

	SET_INTENTED_ROUTE: 'APP@SET_INTENTED_ROUTE',
	UNSET_INTENTED_ROUTE: 'APP@UNSET_INTENTED_ROUTE',
	SHOW_RESET_PASSWORD_FORM: 'APP@SHOW_RESET_PASSWORD_FORM',
	HIDE_RESET_PASSWORD_FORM: 'APP@HIDE_RESET_PASSWORD_FORM',
	SHOW_FORGOT_PASSWORD_FORM: 'APP@SHOW_FORGOT_PASSWORD_FORM',
	HIDE_FORGOT_PASSWORD_FORM: 'APP@HIDE_FORGOT_PASSWORD_FORM',
	SHOW_EMAIL_VERIFICATION: 'APP@SHOW_EMAIL_VERIFICATION',
	HIDE_EMAIL_VERIFICATION: 'APP@HIDE_EMAIL_VERIFICATION',

	PASSWORD_RESET_LINK_SENT_SUCCESS: 'APP@PASSWORD_RESET_LINK_SENT_SUCCESS',
	PASSWORD_RESET_LINK_SENT_FAILED: 'APP@PASSWORD_RESET_LINK_SENT_FAILED',
	PASSWORD_RESET_SUCCESS: 'APP@PASSWORD_RESET_SUCCESS',
	PASSWORD_RESET_FAILED: 'APP@PASSWORD_RESET_FAILED',

	REDIRECTED: 'APP@REDIRECTED',

	VERIFYING_EMAIL: 'APP@VERIFYING_EMAIL',
	EMAIL_VERIFICATION_SUCCESS: 'APP@EMAIL_VERIFICATION_SUCCESS',
	EMAIL_VERIFICATION_FAILED: 'APP@EMAIL_VERIFICATION_FAILED',
	OPEN_PROFILE_MENU: 'APP@OPEN_PROFILE_MENU',
	CLOSE_PROFILE_MENU: 'APP@CLOSE_PROFILE_MENU',
	OPEN_FILTER_MENU: 'APP@OPEN_FILTER_MENU',
	CLOSE_FILTER_MENU: 'APP@CLOSE_FILTER_MENU',
	OPEN_CATALOG_MENU: 'APP@OPEN_CATALOG_MENU',
	CLOSE_CATALOG_MENU: 'APP@CLOSE_CATALOG_MENU',
};

export const LANG = {
	SET: 'LANG@SET',
	LOCALE_SET: 'LANG@LOCALE_SET'
};

export const RESPONSE = {
	SET_SUCCESS: 'RESPONSE@SET_SUCCESS',
	SET_ERROR: 'RESPONSE@SET_ERROR',
	CLEAR: 'RESPONSE@CLEAR'
};

export const SETTINGS = {
	SET_DELIVERY_ADDRESSLIST: 'SETTINGS@SET_DELIVERY_ADDRESSLIST',
	CLEAR_DELIVERY_ADDRESSLIST: 'SETTINGS@CLEAR_DELIVERY_ADDRESSLIST',
	SET_DELIVERY_OPTIONS: 'SETTINGS@SET_DELIVERY_OPTIONS',
	CLEAR_DELIVERY_OPTIONS: 'SETTINGS@CLEAR_DELIVERY_OPTIONS',
	CLEANUP: 'SETTINGS@CLEANUP',
	SET_USER_PRODUCTS: 'SETTINGS@SET_USER_PRODUCTS',
	CLEAR_USER_PRODUCTS: 'SETTINGS@CLEAR_USER_PRODUCTS',
	SET_USER_PRODUCTS_IS_PENDING: 'SETTINGS@SET_USER_PRODUCTS_IS_PENDING',
	CLEAR_BANK_ACCOUNT_DETAILS: 'SETTINGS@CLEAR_BANK_ACCOUNT_DETAILS',
	SET_BANK_ACCOUNT_DETAILS: 'SETTINGS@SET_BANK_ACCOUNT_DETAILS',
	SET_DELIVERY_ADDRESS_CANNOT_BE_DELETED: 'SETTINGS@SET_DELIVERY_ADDRESS_CANNOT_BE_DELETED',
	SET_BANK_ACCOUNT_DETAILS_CANNOT_BE_DELETED: 'SETTINGS@SET_BANK_ACCOUNT_DETAILS_CANNOT_BE_DELETED',
	UNSET_DELIVERY_ADDRESS_CANNOT_BE_DELETED: 'SETTINGS@UNSET_DELIVERY_ADDRESS_CANNOT_BE_DELETED',
	UNSET_BANK_ACCOUNT_DETAILS_CANNOT_BE_DELETED: 'SETTINGS@UNSET_BANK_ACOUNT_DETAILS_CANNOT_BE_DELETED',
	SET_PHONE_DETAILS: 'SETTINGS@SET_PHONE',
	CLEAR_PHONE_DETAILS: 'SETTINGS@CLEAR_PHONE',
	SET_PHONE_DETAILS_CANNOT_BE_DELETED: 'SETTINGS@SET_PHONE_DETAILS_CANNOT_BE_DELETED',
	UNSET_PHONE_DETAILS_CANNOT_BE_DELETED: 'SETTINGS@UNSET_PHONE_DETAILS_CANNOT_BE_DELETED',
	REQUEST_IS_PENDING: 'SETTINGS@REQUEST_IS_PENDING',
	UPDATE_PRODUCT_IN_USER_PRODUCTS: 'SETTINGS@UPDATE_PRODUCT_IN_USER_PRODUCTS',
	UPDATE_PRODUCT_AVAILABILITY_IN_USER_PRODUCTS: 'SETTINGS@UPDATE_PRODUCT_AVAILABILITY_IN_USER_PRODUCTS',
	SET_PRODUCT_BARGAIN_PRICES: 'SETTINGS@SET_PRODUCT_BARGAIN_PRICES',
	SET_HOLIDAY: 'SETTINGS@SETTINGS.SET_HOLIDAY',
	SET_OPTOUTS: 'SETTINGS@SETTINGS.SET_OPTOUTS',
};

export const PROFILE = {
	CLEAR_PROFILE_DATA: 'PROFILE@CLEAR_PROFILE_DATA',
	SET_PROFILE_DATA: 'PROFILE@SET_PROFILE_DATA',
	SET_AVATAR: 'PROFILE@SET_AVATAR',
	AVATAR_CHANGED: 'PROFILE@AVATAR_CHANGED',
	SET_FAVORITE: 'PROFILE@SET_FAVORITE',
	SET_FAVORITE_IS_PENDING: 'PROFILE@SET_FAVORITE_IS_PENDING',
	SET_USER_PRODUCTS: 'PROFILE@SET_USER_PRODUCTS',
	SET_FAVORITE_IN_PRODUCTLIST: 'PROFILE@SET_FAVORITE_IN_PRODUCTLIST',
	SET_PENDING: 'PROFILE@SET_PENDING',
	UPDATE_PROFILE_DATA: 'PROFILE@UPDATE_PROFILE_DATA',
};

export const CATALOG = {
	SET_CATEGORIES: 'CATALOG@SET_CATEGORIES',
	SET_ATTRIBUTES: 'CATALOG@SET_ATTRIBUTES',
	SET_SEARCH_SIZES: 'CATALOG@SET_SEARCH_SIZES',
	CLEAR_CATALOG: 'CATALOG@CLEAR_CATALOG',
	// SET_CATEGORYMAP: 'CATALOG@SET_CATEGORYMAP',
	// CLEAR_PAGINATION: 'CATALOG@CLEAR_PAGINATION',
	CLEAR_PRODUCTLIST: 'CATALOG@CLEAR_PRODUCTLIST',
	SET_PRODUCTLIST: 'CATALOG@SET_PRODUCTLIST',
	SET_FAVORITE_IS_PENDING: 'CATALOG@SET_FAVORITE_IS_PENDING',
	SET_FAVORITE_TO_PRODUCT_LIST_ITEM: 'CATALOG@SET_FAVORITE_TO_PRODUCT_LIST_ITEM',
};

export const SEARCH = {
	SET_SEARCH_SIZE_ATTRIBUTES: 'SEARCH@SET_SEARCH_SIZE_ATTRIBUTES',
	CLEAR_CATALOG: 'SEARCH@CLEAR_CATALOG',
	CLEAR_PRODUCTLIST: 'SEARCH@CLEAR_PRODUCTLIST',
	CLEAR_USERLIST: 'SEARCH@CLEAR_USERLIST',
	SET_PRODUCTLIST: 'SEARCH@SET_PRODUCTLIST',
	SET_USERLIST: 'SEARCH@SET_USERLIST',
	SET_FAVORITE_IS_PENDING: 'SEARCH@SET_FAVORITE_IS_PENDING',
	SET_FAVORITE_TO_PRODUCT_LIST_ITEM: 'SEARCH@SET_FAVORITE_TO_PRODUCT_LIST_ITEM',
};

export const CONFIG = {
	SET_CONFIG: 'CONFIG@SET_CONFIG',
};

export const STATIC_TEXTS = {
	SET_CONTENT: 'TOS@SET_CONTENT',
};

export const PRODUCT = {
	CLEAR_PRODUCT_CREATE_DATA: 'PRODUCT@CLEAR_PRODUCT_CREATE_DATA',
	SET_PRODUCT_CREATE_DATA: 'PRODUCT@SET_PRODUCT_CREATE_DATA',
	DELETE_TEMPORARY_IMAGE: 'PRODUCT@DELETE_TEMPORARY_IMAGE',
	ADD_TEMPORARY_IMAGE: 'PRODUCT@ADD_TEMPORARY_IMAGE',
	UPDATE_TEMPORARY_IMAGE: 'PRODUCT@UPDATE_TEMPORARY_IMAGE',
	DELETING_TEMPORARY_IMAGE: 'PRODUCT@DELETING_TEMPORARY_IMAGE',
	IMAGE_UPLOAD_FAILED: 'PRODUCT@IMAGE_UPLOAD_FAILED',
	CLEAR_FAILED_IMAGES: 'PRODUCT@CLEAR_FAILED_IMAGES',
	SET_PRODUCT_ERROR: 'PRODUCT@SET_PRODUCT_ERROR',
	CLEAR_PRODUCT_ERROR: 'PRODUCT@CLEAR_PRODUCT_ERROR',
	UPDATE_IMAGE_PRIMARY: 'PRODUCT@UPDATE_IMAGE_PRIMARY',
	SET_RESPONSE_ERROR: 'PRODUCT@SET_RESPONSE_ERROR',
	CLEAR_RESPONSE_ERROR: 'PRODUCT@CLEAR_RESPONSE_ERROR',
	SAVED: 'PRODUCT@SAVED',
	CLEAR_SAVED: 'PRODUCT@CLEAR_SAVED',
	PRODUCT_IS_FETCHING: 'PRODUCT@PRODUCT_IS_FETCHING',
	SHOW_PRODUCT_SIZE_WARNING: 'PRODUCT@SHOW_PRODUCT_SIZE_WARNING',
	HIDE_PRODUCT_SIZE_WARNING: 'PRODUCT@HIDE_PRODUCT_SIZE_WARNING',

	CLEAR_PRODUCT_DATA: 'PRODUCT@CLEAR_PRODUCT_DATA',
	SET_PRODUCT_DATA: 'PRODUCT@SET_PRODUCT_DATA',
	SET_CATEGORIES: 'PRODUCT@SET_CATEGORIES',
	SET_ATTRIBUTES: 'PRODUCT@SET_ATTRIBUTES',
	SET_PRODUCT_DELETED: 'PRODUCT@SET_PRODUCT_DELETED',
	SET_USER_PRODUCTS: 'PRODUCT@SET_USER_PRODUCTS',
	CLEAR_USER_PRODUCTS: 'PRODUCT@CLEAR_USER_PRODUCTS',
	SET_FAVORITE: 'PRODUCT@SET_FAVORITE',

	SET_FAVORITE_IS_PENDING: 'PRODUCT@SET_FAVORITE_IS_PENDING', // TODO ?
	SET_FAVORITE_IN_PRODUCTLIST: 'PRODUCT@SET_FAVORITE_IN_PRODUCTLIST',
	SET_REQUEST_IS_PENDING: 'PRODUCT@SET_REQUEST_IS_PENDING',
	SET_HIGLIGHT_RESULT: 'PRODUCT@SET_HIGLIGHT_RESULT',
	PURCHASE_HIGHLIGH_DIALOG_VISIBILITY: 'PRODUCT@PURCHASE_HIGHLIGH_DIALOG_VISIBILITY',
	SET_AVAILABILITY_INQUIRED: 'PRODUCT@SET_AVAILABILITY_INQUIRED',
	UPDATE_PRODUCT_AVAILABILITY: 'PRODUCT@UPDATE_PRODUCT_AVAILABILITY'
};

export const DASHBOARD = {
	SET_PRODUCT_LIST: 'DASHBOARD@SET_PRODUCT_LIST',
	CLEAR_PRODUCT_LIST: 'DASHBOARD@CLEAR_PRODUCT_LIST',
	CLEAR_DASHBOARD_DATA: 'DASHBOARD@CLEAR_DASHBOARD_DATA',
	SET_FAVORITE_IS_PENDING: 'DASHBOARD@SET_FAVORITE_IS_PENDING',
	SET_FAVORITE_TO_PRODUCT_LIST_ITEM: 'DASHBOARD@SET_FAVORITE_TO_PRODUCT_LIST_ITEM',
	CLEAR_CONTENT: 'DASHBOARD@CLEAR_CONTENT',
	SET_CONTENT: 'DASHBOARD@SET_CONTENT',
}

export const FAVORITES = {
	CLEAR_FAVORITE_LISTS: 'FAVORITE@CLEAR_FAVORITE_LISTS',
	CLEAR_PRODUCTS: 'FAVORITE@CLEAR_PRODUCTS',
	SET_PRODUCTS: 'FAVORITE@SET_PRODUCTS',
	CLEAR_USERS: 'FAVORITE@CLEAR_USERS',
	SET_USERS: 'FAVORITE@SET_USERS',
	SET_FAVORITE: 'FAVORITE@SET_FAVORITE',
	SET_FAVORITE_IS_PENDING: 'FAVORITE@SET_FAVORITE_IS_PENDING',
};

export const CART = {
	SET_CART: 'CART@SET_CART',
	CLEAR_CART: 'CART@CLEAR_CART',
	CART_IS_PENDING: 'CART@CART_IS_PENDING',
	CLEAR_DETAILED_CART: 'CART@CLEAR_DETAILED_CART',
	SET_DETAILED_CART: 'CART@SET_DETAILED_CART',
	CLEAR_DELIVERY_ADDRESSLIST: 'CART@CLEAR_DELIVERY_ADDRESSLIST',
	SET_DELIVERY_ADDRESSLIST: 'CART@SET_DELIVERY_ADDRESSLIST',
	SET_MPL_DELIVERY_OPTIONS: 'CART@SET_MPL_DELIVERY_OPTIONS',
	CLEAR_MPL_DELIVERY_OPTIONS: 'CART@CLEAR_MPL_DELIVERY_OPTIONS',
	SET_DELIVERY_OPTIONS: 'CART@SET_DELIVERY_OPTIONS',
	SET_CART_DELIVERY_OPTIONS: 'CART@SET_CART_DELIVERY_OPTIONS',
	SET_CART_USER_DELIVERY_OPTIONS: 'CART@SET_CART_USER_DELIVERY_OPTIONS',
	FIRST_PRODUCT_ADDED_TO_CART: 'CART@FIRST_PRODUCT_ADDED_TO_CART',
	SET_CART_OPTIONS: 'CART@SET_CART_OPTIONS',
	SET_CART_CUSTOM_PRODUCT_PRICES: 'CART@SET_CART_CUSTOM_PRODUCT_PRICES',
};

export const ORDER = {
	SET_USER_ORDERS: 'ORDER@SET_USER_ORDERS',
	SET_USER_ORDER: 'ORDER@SET_USER_ORDER',
	CLEAR_USER_ORDERS: 'ORDER@CLEAR_USER_ORDERS',
	CLEAR_USER_ORDER: 'ORDER@CLEAR_USER_ORDER'
};

export const NOTIFICATIONS = {
	SET_NOTIFICATIONS: 'NOTIFICATIONS@SET_NOTIFICATIONS',
	SET_UNREAD_COUNT: 'NOTIFICATIONS@SET_UNREAD_COUNT',
	SET_NOTIFICATION_GROUPS: 'NOTIFICATIONS@SET_NOTIFICATION_GROUPS',
	CLEAR_NOTIFICATION_GROUPS: 'NOTIFICATIONS@CLEAR_NOTIFICATION_GROUPS',
	CLEAR_NOTIFICATIONS: 'NOTIFICATIONS@CLEAR_NOTIFICATIONS',
	SET_READ_FLAG: 'NOTIFICATIONS@SET_READ_FLAG',
};

export const Spots = {
	CategoryLevel: {
		Catalog: routeNames.catalog,
		MobileCatalog: "mobile-catalog",
		CatalogTabs: "catalog-tabs",
	},
	SearchSizeLevel: {
		CatalogTabs: "catalog-tabs",
		SearchSize: "search-size",
		MobileSearchSize: "mobile-search-size"
	}
}

export const PAYMENT = {
	SET_PAYMENT_STATE: 'PAYMENT@SET_PAYMENT_STATE',
	CLEAR_PAYMENT_STATE: 'PAYMENT@CLEAR_PAYMENT_STATE',
};

export const MESSAGES = {
	SET_THREAD_LIST: 'MESSAGES@SET_THREAD_LIST',
	CLEAR_THREAD_LIST: 'MESSAGES@CLEAR_THREAD_LIST',
	SET_MESSAGES: 'MESSAGES@SET_MESSAGES',
	CLEAR_MESSAGES: 'MESSAGES@CLEAR_MESSAGES',
	// SET_THREAD_INTENT: 'MESSAGES@SET_THREAD_INTENT',
	PENDING: 'MESSAGES@PENDING',
	MESSAGE_IS_SENDING: 'MESSAGES@MESSAGE_IS_SENDING',
	SET_SELECTED_THREAD: 'MESSAGES@SET_SELECTED_THREAD',
	CLEAR_SELECTED_THREAD: 'MESSAGES@CLEAR_SELECTED_THREAD',
	APPEND_MESSAGE: 'MESSAGES@APPEND_MESSAGE',
	UPDATE_THREAD: 'MESSAGES@UPDATE_THREAD',
	SET_REPLY_MESSAGE: 'MESSAGES@SET_REPLY_MESSAGE',
	SET_BARGAIN_MESSAGE_LIMIT_EXCEEDED_LAYER: 'MESSAGES@SET_BARGAIN_MESSAGE_LIMIT_EXCEEDED_LAYER',
	SET_UNREAD_THREAD_COUNT: 'MESSAGES@SET_UNREAD_THREAD_COUNT',
	SET_SELECTED_THREAD_BY_ID: 'MESSAGES@SET_SELECTED_THREAD_BY_ID',
	SET_OTHER_USER_IS_DISABLED: 'MESSAGES@SET_OTHER_USER_IS_DISABLED'
};
import React from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {usePost} from '../../hooks/useApi';
import {APP as URLS} from '../../constants/urls';
import { Button } from '@mui/material';

import AuthForm from './AuthForm';
import AuthLayer from './AuthLayer';
import L from '../Helpers/Lang';
import UTypography from '../Helpers/Typography';
import ResponseLayer from '../Helpers/ResponseLayer';
import FullPageLoader from '../Helpers/FullPageLoader';



const ResetPassword = props =>
{
	const {token} = useParams();
	const [searchParams] = useSearchParams();
	const email = searchParams.get('email');

	const [ resetPassword, { isDone, isSuccess, isLoading, response, error } ] = usePost(URLS.resetPassword);
	const [pending,setPending] = React.useState(false);
	
	React.useEffect(()=>{
		setPending(false);
	},[response]);

	if ( isLoading )
	{
		return (
			<FullPageLoader />
		);
	}

	if ( isDone )
	{
		const responseLayerProps = {
			title: isSuccess ? response.message : error.errors?.email,
			[ isSuccess ? "success" : "error" ]: true,
			button: (
				<Button
					variant="contained"
					color={isSuccess?'success':'error'}
					onClick={props.onModalDismiss}
					sx={{mt: 6}}
				>
					{isSuccess?<L term="button.okay"/>:<L term="button.igot_it"/>}
				</Button>
			)
		};


		return (
			<ResponseLayer
				onDismiss={props.onModalDismiss}
				{ ...responseLayerProps }
			/>
		);
	}

	return (
		<AuthLayer onDismiss={props.onModalDismiss}>
			<UTypography
					variant="h4"
					align="center"
					sx={{mb: 7}}
				>
					.title.reset_password
			</UTypography>
			<AuthForm
				submitDisabled={pending}
				fields={['email','password','password_confirmation']}
				defaultValues={{email}}
				submitLabel="button.next"
				onSubmit={({ password, password_confirmation }) => {
					setPending(true);
					resetPassword({token, email, password, password_confirmation});
				}}
				responseStackName="resetPassword"
				validationRules={{
					password: 'required|min.string:8|confirmed',
				}}
			/>
		</AuthLayer>
	);
}

export default ResetPassword;
import { createTheme } from '@mui/material/styles';
import lightPalette from './palette/lightPalette';
// import darkPalette from './palette/darkPalette';
import mixins from './mixins';
import buttonOverrides from './buttons';
import inputOverrides from './inputs';
import { typographyDefinition, typographyOverrides } from './typography';

const theme = createTheme({
	palette: lightPalette,
	shape: {
		borderRadius: 2
	},
	spacing: 4,
	...mixins,
	...typographyDefinition,

	components:
	{
		...typographyOverrides,
		...buttonOverrides,
		...inputOverrides,

		// MuiBackdrop: {
		// 	styleOverrides: {
		// 		root: ({theme}) => ({
		// 			backgroundColor: theme.palette.grey.main2
		// 		})
		// 	}
		// },

		MuiBottomNavigation: {
			styleOverrides: {
				root: ({theme}) => ({
					height: 80,
				}),
			}
		},
		MuiBottomNavigationAction: {
			styleOverrides: {
				root: ({theme}) => ({
					padding: 0,
					minWidth: "auto",
					"&.Mui-selected": {
						backgroundColor: theme.palette.primary.main4,
						paddingTop: 0
					}
				}),
				label: ({theme}) => ({
					fontSize: "0.75rem",
					marginTop: theme.spacing(2),
					"&.Mui-selected": {
						fontSize: "0.75rem",
						color: theme.palette.black.main,
						fontWeight: 700,
					}
				})
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: ({theme}) => ({
					"&.Mui-selected": {
						backgroundColor: theme.palette.grey.main8,
					}
				})
			}
		},
		MuiPaper: {
			defaultProps: {
				elevation: 0
			}
		}
	}
});

export default theme;
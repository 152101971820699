import React from 'react';
import {useDispatch} from 'react-redux';
import useStoreState from '../../../hooks/useStoreState';
import { openFilterMenuAction, closeFilterMenuAction } from '../../../actions/appActions';
import {categoryPropFromTree} from '../../../utils/category';
import MobileNavigationMenu from '../../MobileNavigationMenu';
import {Button, Typography, Box, List, ListItemButton, IconButton, Radio, Checkbox} from '@mui/material'
import L from '../../Helpers/Lang';
import { orderOptions } from '../../../constants';
import PriceTextField from '../Catalog/PriceTextField';
import { ReactComponent as FilterIcon } from '../../../assets/icons/sliders.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/icons/minus.svg';
import PromoFilterDialog from '../Catalog/PromoFilterDialog';

const MobileProductFilter = ({
	category,
	filter,
	order,
	// handleFilterChange,
	// handlePriceRangeChange,
	// handleOrderChange,
	clearFilter,
	setParams
}) => {
	const dispatch = useDispatch();
	const {filterMenuOpen} = useStoreState('app');

	const {categoryListById,attributeListById} = useStoreState('catalog');
	const attributes = categoryPropFromTree(categoryListById)('attributes')(category);
	attributes?.sort((a,b) => a.order - b.order)

	const childrenAttributes = category?.children_attributes?.map( attribute_id => attributeListById[ attribute_id ] );

	const [openAttributes, setOpenAttributes] = React.useState([]);

	const [ orderContentOpen, setOrderContentOpen ] = React.useState(false);
	const toggleOrderContentOpen = () => setOrderContentOpen(!orderContentOpen);
	const [ priceContentOpen, setPriceContentOpen ] = React.useState(false);
	const togglePriceContentOpen = () => setPriceContentOpen(!priceContentOpen);

	const [ promoFilterDialogOpen, setPromoFilterDialogOpen ] = React.useState(false);
	const togglePromoFilterDialog = React.useCallback(() => setPromoFilterDialogOpen(!promoFilterDialogOpen),[promoFilterDialogOpen]);
	
	const openFilterMenu = () => dispatch(openFilterMenuAction());
	const closeFilterMenu = () => dispatch(closeFilterMenuAction());

	const toggleFilterOpen = (event, attribute) => {
		event.stopPropagation();
		setOpenAttributes( attributeIds => attributeIds.includes( attribute.attribute_id )
			? attributeIds.filter( a => a !== attribute.attribute_id )
			: [ ...attributeIds, attribute.attribute_id ]
		);
  };

	const setAll = () => {
		setParams({
			filter: _filter,
			order: _order
		});
		closeFilterMenu();
	}

	// handling filter data in place preventing immediate requests
	const [ _filter, _setFilter ] = React.useState(filter);
	const [ _order, _setOrder ] = React.useState(order);

	const _handleFilterChange = (attribute, options) => {
		if ( !attribute ) {
			return;
		}

		switch( attribute )
		{
			case 'price':
				_setFilter( filter => ({ ...filter, price: options }));
				break;
			default:
				if ( ( filter && Object.keys(filter).includes( attribute.toString() ) ) || !Array.isArray(options) || options.length === 0 )
				{
					if ( filter && Object.keys(filter).includes( attribute.toString() ) && Object.keys(filter).length === 1 )
					{
						_setFilter(undefined)
					}
					else {
						_setFilter( filter => ({ ...filter, [attribute]: undefined }))
					}
				}
				else {
					_setFilter( filter => ({ ...filter, [attribute]: options }))
				}
		}
  }

	const _handleOrderChange = (order) => _setOrder(order)
	const _handlePriceRangeChange = (price) => _handleFilterChange('price', price)

	const hasActiveFilter = _filter && Object.keys(_filter).length > 0;

	const _clearFilter = () => {
		_setFilter(undefined)
		// _setOrder(undefined)
		clearFilter?.()
  }

	return (
		<>
			<Button
				variant="text"
				startIcon={<FilterIcon/>}
				color={hasActiveFilter ? "secondary" : "black"}
				sx={theme=>({
					textTransform: "none",
					fontWeight: 700,
					"& .MuiButton-startIcon": {
						fill: hasActiveFilter ? theme.palette.secondary.main : theme.palette.black.main
					}
				})}
				onClick={openFilterMenu}
			>
				<L term="nav.filters" />
			</Button>
				{hasActiveFilter && ( <Box
					sx={{
						flexBasis: '100%',
						display: "flex",
						justifyContent: "center"
					}}
				>
					<Button
						variant="text"
						color="secondary"
						onClick={_clearFilter}
					><L term="button.clear_filters" /></Button>
				</Box>
			)}
			<MobileNavigationMenu
				open={filterMenuOpen}
				transitionDirection="left"
			>
				<Box
					sx={{
						display: "flex",
						flexShrink: 0,
						height: 56,
						alignItems: "center",
						px: 4
					}}
				>
					<Typography
						variant="h4"
						sx={{mr: "auto"}}
					><L term="nav.filters" /></Typography>
					<IconButton
						onClick={closeFilterMenu}
						sx={{p: 0}}
					>
						<CloseIcon />
					</IconButton>
				</Box>
				<List
					sx={{mb: 22}}
				>
					<MobileProductFilterListItem
						label={<L term="button.order"/>}
						open={orderContentOpen}
						toggle={toggleOrderContentOpen}
						// set={Boolean(_order)}
					/>
					<MobileProductOrderContent
						open={orderContentOpen}
						// selectedOrder={order}
						selectedOrder={_order}
						// onOrderChange={handleOrderChange}
						onOrderChange={_handleOrderChange}
					/>
					{childrenAttributes?.length > 0 && (
						<ChildrenAttributesMenu
							attributes={childrenAttributes}
							filter={filter}
							handleFilterChange={_handleFilterChange}
						/>
					)}
					{attributes?.map(attribute => (
						<Box key={attribute.attribute_id}>
							<MobileProductFilterListItem
								key={attribute.attribute_id}
								label={attribute.attribute_name}
								open={openAttributes.includes(attribute.attribute_id)}
								toggle={ev => toggleFilterOpen(ev,attribute)}
								set={Boolean(_filter?.[ attribute?.attribute_id ])}
							/>
							<MobileProductFilterContent
								open={openAttributes.includes(attribute.attribute_id)}
								attribute={attribute}
								selectedOptions={_filter?.[ attribute.attribute_id ]}
								// onOptionsChange={handleFilterChange}
								onFilterChange={_handleFilterChange}
							/>
						</Box>
					))}
					<MobileProductFilterListItem
						label={<L term="button.price"/>}
						open={priceContentOpen}
						toggle={togglePriceContentOpen}
						set={Boolean(_filter?.price)}
					/>
					<MobileProductPriceContent
						open={priceContentOpen}
						priceRange={_filter?.price}
						onPriceRangeChange={_handlePriceRangeChange}
					/>
					{!category && (
						<PromoFilter { ...{ togglePromoFilterDialog } } />
					)}
				</List>
				<Box
					sx={theme=>({
						display: "flex",
						position: "fixed",
						width: '100%',
						bottom: 0,
						height: 88,
						px: 4,
						py: 4.5,
						bgcolor: theme.palette.white.main,
						boxShadow: "0 -2px 3px rgba(0,0,0,.1)"
					})}
				>
					<Button
						variant="contained"
						color="black"
						fullWidth
						onClick={setAll}
					><L term="button.set_filter" /></Button>
				</Box>
			</MobileNavigationMenu>
			<PromoFilterDialog  { ...{ promoFilterDialogOpen, setPromoFilterDialogOpen } } />
		</>
	);
};

export default MobileProductFilter;

export const MobileProductFilterListItem = ({open,set,label,toggle}) => (
	<ListItemButton
		sx={theme=>({
			height: 56,
			"& .MuiTypography-root": {
				color: set ?  theme.palette.secondary.main: theme.palette.black.main,
			},
			fill: set ?  theme.palette.secondary.main: (open ? theme.palette.black.main : theme.palette.grey.main4),
			border: `1px solid ${theme.palette.grey.main7}`
		})}
		onClick={toggle}
	>
		<Typography
			variant="subtitle"
			sx={theme=>({
				textTransform: "uppercase",
				fontWeight: 500,
				mr: "auto",
			})}
		>
			{label}
		</Typography>
		{open ? <MinusIcon /> : <PlusIcon />}
	</ListItemButton>
);

const MobileProductOrderContent = ({
	open,
	selectedOrder: initiallySelectedOrder,
	onOrderChange
}) => {
	
	const options = orderOptions;
	const [ selectedOrder, setSelectedOrder ] = React.useState( initiallySelectedOrder );

	const setOrder = ( order ) => {
		setSelectedOrder( order )
		onOrderChange( order )
	}

	return open ? (
		<List>
			{options?.map( order => {
				const optionIsSelected = selectedOrder === order
				return (
					<ListItemButton key={order}
						sx={theme=>({
							pl: 4,
							height: 56,
							fill: theme.palette.grey.main4
						})}
						onClick={() => optionIsSelected ? setOrder(undefined) : setOrder(order) }
					>
						<Typography variant="subtitle" sx={{mr: "auto", fontWeight: optionIsSelected ? 700 : 400 }}><L term={`label.${order}`} /></Typography>
						<Radio checked={optionIsSelected} color="secondary" sx={{p: 0}}/>
					</ListItemButton>
				)
			})}
		</List>
	) : null;
}

const MobileProductFilterContent = ({
	open,
	attribute,
	selectedOptions : initiallySelectedOptions,
	onFilterChange
}) => {
	const {attributeListById} = useStoreState('catalog');

	const attributeId = attribute.attribute_id;
	const options = attributeListById?.[ attributeId ]?.options;

	const [ selectedOptions, setSelectedOptions ] = React.useState( Array.isArray(initiallySelectedOptions) ? initiallySelectedOptions : []);

	const setOptions = ( options ) => {
		setSelectedOptions( options )
		onFilterChange( attributeId, options.length > 0 ? options : undefined )
	}

	return open ? (
		<List>
			{options?.map( option => {
				const optionIsSelected = Boolean(selectedOptions.includes(option.option_id))
				const meta = option.meta ? JSON.parse(option.meta) : undefined

				return (
					<ListItemButton key={option.option_id}
						sx={theme=>({ pl: 4, height: 56, fill: theme.palette.grey.main4 })}
						onClick={() => setOptions( optionIsSelected ? selectedOptions.filter( o => o !== option.option_id ) : [ ...selectedOptions, option.option_id ] )}
					>
						<Typography variant="subtitle" sx={{mr: "auto", fontWeight: optionIsSelected ? 700 : 400 }}>{option.option_name}</Typography>
						
						{!meta?.color_code && <Checkbox color="secondary" checked={optionIsSelected} sx={{p: 0}}/>}
						{meta?.color_code && (
							<Box
								sx={theme =>({
									mx: 4,
									my: 2,
									width: 24,
									height: 24,
									borderRadius: 2,
									border: `2px solid ${ optionIsSelected ? theme.palette.secondary.main : meta.color_code }`,
									bgcolor: Array.isArray(meta.color_code) ? undefined : `${meta.color_code}`,
									backgroundImage: Array.isArray(meta.color_code) ? `linear-gradient(to right, ${meta.color_code.join(', ')})` : "none",
								})}
							></Box>
						)}
					</ListItemButton>
				)
			})}
		</List>
	) : null;
}

const MobileProductPriceContent = ({
	open,
	priceRange: initialPriceRange,
	onPriceRangeChange
}) => {
	const [ priceRange, setPriceRange ] = React.useState( initialPriceRange || [] );

	const setRange = (priceRange) => {
		setPriceRange( priceRange )
		onPriceRangeChange( priceRange )
	}

	return open ? (
		<Box sx={{ p: 4 }} >
			<PriceTextField
				name="from"
				value={priceRange[0] || ''}
				onChange={ ev => setRange( [ parseInt(ev.target.value,10), priceRange[1] ] ) }
				/>
			<PriceTextField
				name="to"
				value={priceRange[1] || ''}
				onChange={ ev => setRange( [ priceRange[0], parseInt(ev.target.value,10) ] ) }
			/>
		</Box>
	) : null;
}

const PromoFilter = ({togglePromoFilterDialog}) =>
{
	const { attributeListById } = useStoreState('catalog');
	const promoAttributeNames = [ '3', '1', '9', '2' ].map( attributeId => attributeListById[ attributeId ].attribute_name );

	return (
		<>
			{promoAttributeNames?.map((attributeName,index) => (
				<MobileProductFilterListItem
						key={index}
						label={attributeName}
						open={false}
						set={false}
						toggle={togglePromoFilterDialog}
					/>
			))}
		</>
	);
}

const ChildrenAttributesMenu = ({attributes, filter, handleFilterChange}) =>
{
	const [childrenAttributesContentOpen,setChildrenAttributesContentOpen] = React.useState(false);

	const attribute_ids_string = attributes.map( a => a.attribute_id.toString() );
	const childrenAttributeSet = Object.keys(filter||{}).some( key => attribute_ids_string.includes( key ) );

	return (
		<>
		<MobileProductFilterListItem
			label={<L term="label.sizes" />}
			open={childrenAttributesContentOpen}
			toggle={ev => setChildrenAttributesContentOpen( open => !open )}
			set={Boolean(childrenAttributeSet)}
		/>
			{childrenAttributesContentOpen && attributes?.map( attribute => (
				<Box key={attribute.attribute_id} sx={{mt: 4}}>
					<Typography variant="subtitle" sx={theme=>({mr: "auto", fontWeight: 400, px: 4, color: theme.palette.grey.main2})}>{attribute.attribute_description}</Typography>
					<MobileProductFilterContent
						open={true}
						attribute={attribute}
						selectedOptions={filter?.[ attribute.attribute_id ]}
						onFilterChange={handleFilterChange}
					/>
				</Box>
			))}
		</>
	);
}
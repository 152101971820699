const _green = '#4C982E';
const _red = '#D83B3B';
const _black = '#202020';
const _white = '#fff';
const _yellow = '#F6CC45';
const _blue = '#006992';

export const borderLeft = (w,c) => `border-left: ${w}px solid ${c};`;
export const bl = (w,c) => borderLeft(w,c);
export const greenBl = (w) => bl(w,_green);
export const redBl = (w) => bl(w,_red);
export const greenBl8 = (w) => greenBl(8,_green);
export const redBl8 = (w) => redBl(8,_green);

export const padding = p => `padding: ${p};`;
export const p4 = () => padding('4px');

export const bold = () => 'font-weight: 700';
export const background = color => `background: ${color};`
export const color = color => `color: ${color};`

export const greenBg = () => background(_green);
export const green = () => color(_green);
export const blackBg = () => background(_black);
export const black = () => color(_black);
export const whiteBg = () => background(_white);
export const white = () => color(_white);
export const redBg = () => background(_red);
export const red = () => color(_red);
export const yellowBg = () => background(_yellow);
export const yellow = () => color(_yellow);
export const blueBg = () => background(_blue);
export const blue = () => color(_blue);

export const combine = (...args) => [ p4, ...args ].reduce( ( style, arg ) => `${style} ${arg()}`, '');
import React from 'react';
import { useDispatch } from 'react-redux';
import useStoreState from '../hooks/useStoreState';
import { getContent } from '../actions/staticTextActions';
import {Box, Typography, List, ListItem, ListItemText, Collapse} from '@mui/material';
import L from './Helpers/Lang';
import { ReactComponent as PlusIcon } from '../assets/icons/plus.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import { Link } from 'react-router-dom';

const getRowContent = (row) => {
	
	if ( row.content.indexOf(':mailto') !== -1 ) {
		const parts = row.content.split(':mailto');
		parts.splice(1,0,<Box key={1} component="a" href={`mailto:${row.mailto}`} target="_blank" rel="noreferrer">{row.mailto}</Box>)
		return parts;
	}

	if ( row.content.indexOf(':a') !== -1 ) {
		const parts = row.content.split(':a');
		parts.splice(1,0,<Box key={1} component={Link} to={row.a.href}>{row.a.label}</Box>)
		return parts;
	}
	
	if ( row.content.indexOf(':b') !== -1 ) {
		const parts = row.content.split(':b');
		parts.splice(1,0,<b key={1}>{row.b.label}</b>)
		return parts;
	}

	return row.content;
}

const Faq = () => {

	const dispatch = useDispatch();
	const { faq: faqList } = useStoreState('statictexts');
	
	React.useEffect(()=>{
		if ( !faqList ) {
			dispatch(getContent({kind: 'faq'}));
		}
	},[dispatch, faqList])

	const [ openedTitle, setOpenedTitle ] = React.useState("");

	return (
		<Box>
			<Typography
				variant="h3"
				sx={{
					mb: 4
				}}
			><L term="label.faq" /></Typography>
			<List
				sx={theme=>({
					p:0,
					border: `1px solid ${theme.palette.grey.main6}`,
					boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
					"& a": {
						textDecoration: "none",
						color: theme.palette.info.main
					}
				})}
			>
				{faqList?.map(row => {
					const opened = openedTitle === row.title;
					const content = getRowContent(row);

					return (
						<ListItem
							key={row.title}
							sx={theme=>({
								borderBottom: `1px solid ${theme.palette.grey.main6}`,
								"&:last-child": {
									border: "none"
								},
								flexWrap: "wrap"
							})}
							onClick={ev => opened ? setOpenedTitle("") : setOpenedTitle(row.title)}
						>
							<ListItemText disableTypography
								sx={{
									mr: "auto",
									display: "flex",
									cursor: "pointer"
								}}
							>
								<Typography variant="h4" sx={{mr: "auto"}}>{row.title}</Typography>
								<Box
									sx={{flexShrink: 0}}
								>
									{!opened && <PlusIcon />}
									{opened && <CloseIcon />}
								</Box>
							</ListItemText>
							<Collapse
								sx={{
									flexBasis: '100%',
								}}
								in={opened}
							>
								<Typography variant="body1" sx={{ py: 3 }} >
									{content}
								</Typography>
							</Collapse>
						</ListItem>
					)})}
			</List>
		</Box>
	);
}

export default Faq;
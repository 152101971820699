import React from 'react';
import ReactDOM from 'react-dom';
import Backdrop from '@mui/material/Backdrop';
import Container from '@mui/material/Container';
import { alpha } from '@mui/material/styles';
// import history from '../history';

const Modal = props => {
		return ReactDOM.createPortal(
				<Backdrop
					open={true}
					sx={theme=>({
						zIndex: 1,
						bgcolor: alpha(theme.palette.grey.main2, .9),
						...(props.backdropSxProp?.(theme)),
						overflow: "scroll"
					})}
					onClick={props.onDismiss}
				>
					<Container maxWidth={props.containedMaxWidth || "md"}
							onClick={ev=>ev.stopPropagation()}
							sx={theme=>({
								px: 4,
								...( props.noPadding ? {p: 0} : {"@media (min-width: 600px) and (max-width: 699px)": { px: '20%' } } ),
								...( props.noPadding ? {p: 0} : {"@media (min-width: 700px) and (max-width: 899px)": { px: '25%' } } ),
								...( props.forceNoPadding ? {p: '0 !important'} : undefined ),
							})}
					>
						{props.children}
					</Container>
				</Backdrop>,
				document.querySelector('#modal')
		);
};

export default Modal;

import { STATIC_TEXTS } from '../constants';

const initialState = {
	tos: undefined,
	gdrp: undefined,
	faq: undefined,
};

const statictexts = (state=initialState, action) =>
{
	switch(action.type)
	{
		case STATIC_TEXTS.SET_CONTENT:
			return {
				...state,
				[action.kind]: action.content
			}
		default:
			return state;
	}
};

export default statictexts;
import React from 'react';
import { useTheme } from '@mui/material/styles';
import {categoryPropFromTree} from '../../../utils/category';
import useStoreState from '../../../hooks/useStoreState';
import { Box, Button, Paper, List, Popover, ListItemButton, ClickAwayListener, Typography, Checkbox, Radio } from '@mui/material';
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import L from '../../Helpers/Lang';
import { orderOptions } from '../../../constants';
import PriceTextField from '../Catalog/PriceTextField';
import PromoFilterDialog from '../Catalog/PromoFilterDialog';

const ProductFilter = ({
	category,
	filter,
	order,
	handleFilterChange,
	handlePriceRangeChange,
	handleOrderChange,
	clearFilter
}) => {
	const theme = useTheme();
	const {categoryListById, attributeListById} = useStoreState('catalog');
	const attributes = categoryPropFromTree(categoryListById)('attributes')(category);
	attributes?.sort((a,b) => a.order - b.order);

	const childrenAttributes = category?.children_attributes?.map( attribute_id => attributeListById[ attribute_id ] );

	const [filterMenuAnchorEl, setFilterMenuAnchorEl] = React.useState(null);
	const [orderMenuAnchorEl, setOrderMenuAnchorEl] = React.useState(null);
	const [priceMenuAnchorEl, setPriceMenuAnchorEl] = React.useState(null);
	const [openAttribute, setOpenAttribute] = React.useState(null);
  const filterMenuOpen = Boolean(filterMenuAnchorEl);
  const orderMenuOpen = Boolean(orderMenuAnchorEl);
  const priceMenuOpen = Boolean(priceMenuAnchorEl);

	const [ promoFilterDialogOpen, setPromoFilterDialogOpen ] = React.useState(false);
	const togglePromoFilterDialog = React.useCallback(() => setPromoFilterDialogOpen(!promoFilterDialogOpen),[promoFilterDialogOpen]);

	const handleOpenFilterMenu = (event, attribute) => {
		event.stopPropagation();
    setFilterMenuAnchorEl(event.currentTarget)
		setOpenAttribute(attribute)
  };
	
	const handleOpenOrderMenu = (event) => {
		event.stopPropagation();
    setOrderMenuAnchorEl(event.currentTarget)
  };
	
	const handleOpenPriceMenu = (event) => {
		event.stopPropagation();
    setPriceMenuAnchorEl(event.currentTarget)
  };

  const handleCloseFilterMenu = () => {
    setFilterMenuAnchorEl(null)
		setOpenAttribute(null)
  };
	
	const handleCloseOrderMenu = () => {
    setOrderMenuAnchorEl(null)
  };
	
	const handleClosePriceMenu = () => {
    setPriceMenuAnchorEl(null)
  };

	return (
		<Paper
			elevation={1}
			sx={{
				borderRadius: 21,
				p: 0,
				px: 4,
			}}
		>
			<List
				sx={{
					display: "flex",
					flexWrap: "wrap",
					p: 0,
				}}
			>
				<FilterMenuButton
					label={<L term="button.order"/>}
					onClick={handleOpenOrderMenu}
					open={orderMenuOpen}
					// set={Boolean(order)}
				/>
				{childrenAttributes?.length > 0 && (
					<ChildrenAttributesMenuButton
						attributes={childrenAttributes}
						filter={filter}
						handleFilterChange={handleFilterChange}
					/>
				)}
				{attributes?.map(attribute => (
					<FilterMenuButton
						key={attribute.attribute_id}
						label={attribute.attribute_name}
						open={openAttribute?.attribute_id===attribute.attribute_id}
						set={Boolean(filter?.[ attribute?.attribute_id ])}
						onClick={ev => handleOpenFilterMenu(ev,attribute)}
					/>
				))}
				<FilterMenuButton
					label={<L term="button.price"/>}
					onClick={handleOpenPriceMenu}
					open={priceMenuOpen}
					set={Boolean(filter?.price)}
				/>
				{(filter && Object.keys(filter)?.length > 0 ) && <FilterMenuButton
					label={<L term="button.clear_filters"/>}
					endIcon={<CloseIcon width="16" height="16" viewBox="0 0 24 24" style={{fill: theme.palette.secondary.main}} />}
					set
					onClick={clearFilter}
				/>}
				{!category && (
					<PromoFilter { ...{ togglePromoFilterDialog } } />
				)}
			</List>
			{filterMenuOpen && <FilterMenu
				onClose={handleCloseFilterMenu}
				anchorEl={filterMenuAnchorEl}
				open={filterMenuOpen}
				openAttribute={openAttribute}
				selectedOptions={filter?.[ openAttribute?.attribute_id ]}
				onFilterOptionsChange={handleFilterChange}
			/>}
			{orderMenuOpen && <OrderMenu
				onClose={handleCloseOrderMenu}
				anchorEl={orderMenuAnchorEl}
				open={orderMenuOpen}
				selectedOrder={order}
				onOrderChange={handleOrderChange}
			/>}
			{priceMenuOpen && <PriceMenu
				onClose={handleClosePriceMenu}
				anchorEl={priceMenuAnchorEl}
				open={priceMenuOpen}
				priceRange={filter?.price}
				onPriceRangeChange={handlePriceRangeChange}
			/>}
			<PromoFilterDialog  { ...{ promoFilterDialogOpen, setPromoFilterDialogOpen } } />
		</Paper>
	);
};

export default ProductFilter;

export const FilterMenuButton = ({label, open, set, ...rest}) => (
	<Button
		disableRipple
		endIcon={<ChevronDownIcon width="16" height="16" viewBox="0 0 24 24" style={{ transform: open ? "scaleY(-1)" : "none" }} />}
		sx={theme => ({
			height: 42,
			color: set ? theme.palette.secondary.main : ( open ? theme.palette.black.main : theme.palette.grey.main2 ),
			fill: set ? theme.palette.secondary.main : ( open ? theme.palette.black.main : theme.palette.grey.main2 ),
			mx: 4,
			justifyContent: "flex-start",
			whiteSpace: "nowrap",
			"& .MuiButton-endIcon": {
				ml: 1
			},
		})}
		{...rest}
	>
		<Typography
			color="inherit"
			sx={{
				fontWeight: set ? 700 : 400,
				textTransform: "lowercase",
				"&::first-letter": {
					textTransform: "capitalize",
				}
			}}
		>
			{label}
		</Typography>
	</Button>
);

const ChildrenAttributesMenuButton = ({attributes, filter, handleFilterChange}) => {
	const [childrenAttributesMenuAnchorEl, setchildrenAttributesMenuAnchorEl] = React.useState(null);
	const childrenAttributesMenuOpen = Boolean(childrenAttributesMenuAnchorEl);

	const handleOpenChildrenAttributesMenu = (event) => {
		event.stopPropagation();
    setchildrenAttributesMenuAnchorEl(event.currentTarget)
  };

	const handleCloseChildrenAttributesMenu = () => {
    setchildrenAttributesMenuAnchorEl(null)
  };
	const attribute_ids_string = attributes.map( a => a.attribute_id.toString() );
	const childrenAttributeSet = Object.keys(filter||{}).some( key => attribute_ids_string.includes( key ) );

	return (
		<>
			<FilterMenuButton
				label={<L term="label.sizes" />}
				open={childrenAttributesMenuOpen}
				set={childrenAttributeSet}
				onClick={ev => handleOpenChildrenAttributesMenu(ev,{todo: true})}
			/>
			{childrenAttributesMenuOpen && <ChildrenAttributesMenu
				onClose={handleCloseChildrenAttributesMenu}
				anchorEl={childrenAttributesMenuAnchorEl}
				open={childrenAttributesMenuOpen}
				onFilterChange={handleFilterChange}
				filter={filter}
				attributes={attributes}
			/>}
		</>
	);
};

const FilterMenu = ({
	open,
	onClose,
	anchorEl,
	openAttribute,
	selectedOptions : initiallySelectedOptions,
	onFilterOptionsChange
}) => {
	const {attributeListById} = useStoreState('catalog');

	const attributeId = openAttribute?.attribute_id;
	const options = attributeListById?.[ attributeId ]?.options;

	const [ selectedOptions, setSelectedOptions ] = React.useState( Array.isArray(initiallySelectedOptions) ? initiallySelectedOptions : []);

	const setFilter = () => {
		onFilterOptionsChange(attributeId, selectedOptions)
	};

	return (
		<CustomMenu
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			buttonLabel="button.set_filter"
			onButtonClick={() => { setFilter(); onClose(); }}
			height={306}
		>
			{options?.map( option => {
				const optionIsSelected = Boolean(selectedOptions.includes(option.option_id))
				const meta = option.meta ? JSON.parse(option.meta) : undefined

				return (
					<ListItemButton key={option.option_id}
						sx={{ py: 0, px: 0, pl: 4 }}
						onClick={() => setSelectedOptions( selectedOptions => optionIsSelected ? selectedOptions.filter( o => o !== option.option_id ) : [ ...selectedOptions, option.option_id ] )}
					>
						<Typography variant="subtitle" sx={{mr: "auto", fontWeight: optionIsSelected ? 700 : 400 }}>{option.option_name}</Typography>
						
						{!meta?.color_code && <Checkbox checked={optionIsSelected} sx={{p: 2}}/>}
						{meta?.color_code && (
							<Box
								sx={theme =>({
									mx: 4,
									my: 2,
									width: 24,
									height: 24,
									borderRadius: 2,
									border: `2px solid ${ optionIsSelected ? theme.palette.secondary.main : meta.color_code }`,
									bgcolor: Array.isArray(meta.color_code) ? undefined : `${meta.color_code}`,
									backgroundImage: Array.isArray(meta.color_code) ? `linear-gradient(to right, ${meta.color_code.join(', ')})` : "none",
								})}
							></Box>
						)}
					</ListItemButton>
				)
			})}
		</CustomMenu>
	);
};

const ChildrenAttributesMenu = ({
	open,
	onClose,
	anchorEl,
	// selectedAttributes : initiallySelectedAttributes,
	// selectedOptions : initiallySelectedOptions,
	filter: initialFilter,
	attributes,
	onFilterChange
}) => {
	// const {attributeListById} = useStoreState('catalog');

	// const attributeId = openAttribute?.attribute_id;
	// const options = attributeListById?.[ attributeId ]?.options;

	const [ filter, setFilter ] = React.useState( initialFilter || {} );

	const handleFilterChange = React.useCallback(() => {
		onFilterChange.apply(undefined, ['children-attributes',filter])
	}, [onFilterChange, filter])

	const handleSetFilter = ( attribute_id, option, selected ) =>
	{
		if ( Array.isArray( filter[ attribute_id ]) )
		{
			if ( !selected ) // will be selected
			{
				setFilter( filter => ({ ...filter, [ attribute_id ]: [ ...filter[ attribute_id ], option.option_id ] }) )
			}
			else
			{ // unselect
				setFilter( filter => {
					if ( filter[ attribute_id ].length > 1 )
					{
						return {
							...filter,
							[attribute_id]: filter[ attribute_id ].filter( option_id => option_id !== option.option_id )
						};
					}
					else {
						return Object.entries(filter).reduce( (acc, [key,value]) => {
							if ( key !== attribute_id.toString() ) {
								if ( !acc ) { acc = {}; }
								acc[ key ] = value;
							}

							return acc;
						} , {});
					}
				});
			}
		}
		else
		{
			if ( !selected ) // will be selected
			{
				setFilter( filter => ({ ...filter, [ attribute_id ]: [ option.option_id ] }) )
			}
		}
	};

	return (
		<CustomMenu
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			buttonLabel="button.set_filter"
			onButtonClick={() => { handleFilterChange(); onClose(); }}
			height={506}
		>
			{attributes?.map(({attribute_id, attribute_description, options}) => (
				<Box key={attribute_id}>
					<ListItemButton disabled>
						<Typography variant="subtitle" sx={{mr: "auto", fontWeight: 400 }}>{attribute_description}</Typography>
					</ListItemButton>
					{options?.map( option => {
						const optionIsSelected = Boolean( filter && Array.isArray(filter?.[ attribute_id ]) && filter[ attribute_id ].includes(option.option_id) );
						const meta = option.meta ? JSON.parse(option.meta) : undefined

						return (
							<ListItemButton key={option.option_id}
								sx={{ py: 0, px: 0, pl: 4 }}
								onClick={() => handleSetFilter(attribute_id,option,optionIsSelected)}
							>
								<Typography variant="subtitle" sx={{mr: "auto", fontWeight: optionIsSelected ? 700 : 400 }}>{option.option_name}</Typography>
								{!meta?.color_code && <Checkbox checked={optionIsSelected} sx={{p: 2}}/>}
								{meta?.color_code && (
									<Box
										sx={theme =>({
											mx: 4,
											my: 2,
											width: 24,
											height: 24,
											borderRadius: 2,
											border: `2px solid ${ optionIsSelected ? theme.palette.secondary.main : meta.color_code }`,
											bgcolor: Array.isArray(meta.color_code) ? undefined : `${meta.color_code}`,
											backgroundImage: Array.isArray(meta.color_code) ? `linear-gradient(to right, ${meta.color_code.join(', ')})` : "none",
										})}
									></Box>
								)}
							</ListItemButton>
						)
					})}
				</Box>
			))}
		</CustomMenu>
	);
};

const OrderMenu = ({
	open,
	onClose,
	anchorEl,
	selectedOrder : initiallySelectedOrder,
	onOrderChange,
}) => {

	const options = orderOptions;
	const [ selectedOrder, setSelectedOrder ] = React.useState( initiallySelectedOrder );

	const setOrder = () => onOrderChange( selectedOrder )

	return (
		<CustomMenu
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			buttonLabel="button.set_order"
			onButtonClick={() => { setOrder(); onClose(); }}
			height={306}
		>
			{options?.map( order => {
				const optionIsSelected = selectedOrder === order
				return (
					<ListItemButton key={order}
						sx={{ py: 0, px: 0, pl: 4 }}
						onClick={() => optionIsSelected ? setSelectedOrder(undefined) : setSelectedOrder(order)}
					>
						<Typography variant="subtitle" sx={{mr: "auto", fontWeight: optionIsSelected ? 700 : 400 }}><L term={`label.${order}`} /></Typography>
						<Radio checked={optionIsSelected} color="secondary" sx={{p: 2}}/>
					</ListItemButton>
				)
			})}
		</CustomMenu>
	);
};

const PriceMenu = ({
	open,
	onClose,
	anchorEl,
	priceRange : initialPriceRange,
	onPriceRangeChange,
}) => {

	const [ priceRange, setPriceRange ] = React.useState( initialPriceRange || [] );
	const setPriceFilter = () => onPriceRangeChange( priceRange )

	return (
		<CustomMenu
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			buttonLabel="button.set_filter"
			onButtonClick={() => { setPriceFilter(); onClose(); }}
		>
			<Box sx={{ p: 4 }} >
				<PriceTextField
					name="from"
					value={priceRange[0] || ''}
					onChange={ ev => setPriceRange( [ parseInt(ev.target.value,10), priceRange[1] ] ) }
					/>
				<PriceTextField
					name="to"
					value={priceRange[1] || ''}
					onChange={ ev => setPriceRange( [ priceRange[0], parseInt(ev.target.value,10) ] ) }
				/>
			</Box>
		</CustomMenu>
	);
};

const CustomMenu = ({
	children,
	open,
	onClose,
	anchorEl,
	buttonLabel,
	onButtonClick,
	height
}) => {

	const buttonContainerHeight = 76;
	const boxHeight = height ? height - buttonContainerHeight : "auto";
	const listHeight = boxHeight;

	return (
		<ClickAwayListener onClickAway={onClose}>
			<Popover
				anchorEl={anchorEl}
				open={open}
				onClose={onClose}
				transformOrigin={{ horizontal: 'center', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
				PaperProps={{ sx: { width: 220, height: height, position: "relative" } }}
			>
				<Box sx={{ height: boxHeight, p: 0, overflow: "hidden" }} >
					<List sx={{ overflow: "scroll", height: listHeight }} >
						{children}
					</List>
				</Box>
				<Box
					sx={{
						display: "flex",
						height: buttonContainerHeight,
						px: 4,
						py: 4.5,
						boxShadow: "0 -2px 3px rgba(0,0,0,.1)"
					}}
				>
					<Button
						variant="contained"
						color="black"
						fullWidth
						onClick={onButtonClick}
					><L term={buttonLabel} /></Button>
				</Box>
			</Popover>
		</ClickAwayListener>
	);
};

const PromoFilter = ({togglePromoFilterDialog}) =>
{
	const { attributeListById } = useStoreState('catalog');
	const promoAttributeNames = [ '3', '1', '9', '2' ].map( attributeId => attributeListById[ attributeId ].attribute_name );

	return (
		<>
			{promoAttributeNames?.map((attributeName,index) => (
				<FilterMenuButton
						key={index}
						label={attributeName}
						open={false}
						set={false}
						onClick={togglePromoFilterDialog}
					/>
			))}
		</>
	);
}

